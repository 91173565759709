const COLORS = {
    green: '#28A745',
    brand_primary: '#EDCD1E',
    brand_secondary: '#EDCD1E'
}

export const params = function () {
    const ENV = "TEST"

    switch (ENV) {
        case "LOCAL":
            return ({
                apiUri: "http://localhost:8080/api",
                homePage: "https://tasky.digital/inicio",
                dataUri: "https://tasky.digital/data"
            });
        case "PROD":
            return ({
                apiUri: "https://tasky.digital:8080/api",
                homePage: "https://tasky.digital/inicio",
                dataUri: "https://tasky.digital/data"
            });
        case "TEST":
            return ({
                apiUri: "https://testing.tasky.digital:15001/api",
                homePage: "https://testing.tasky.digital/inicio",
                dataUri: "https://testing.tasky.digital/data"
            });
    }
}

export const colors = function () {
    return COLORS;
}

export const getMaintenance = async () => {
    try {
        const response = await fetch(`${process.env.PUBLIC_URL}/maintenance.json`);
        const data = await response.json();

        let maintenance = data.maintenance;

        const localStorageValue = localStorage.getItem('maintenance');
        if (localStorageValue !== null) {
            maintenance = localStorageValue == 'true';
        }

        return maintenance;
    } catch (error) {
        console.error("Error fetching the maintenance status:", error);
        return false; 
    }
}