import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getTextColorForBackground } from '../../tools/tools';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function CalendarList({ events = [], type}) {

    const [eventsParsed, setEventsParsed] = useState([]);

    useEffect(() => {
        moment.locale('es');
        console.log(events)
        switch (type) {
            case "REG-INTERVIEWS":
                processRegistrationInterviewsEvents({onlyPending: true});
                break;
        }
    }, []);

    const handleInterviewsFilter = (e) => {
        processRegistrationInterviewsEvents({onlyPending: e.target.checked})
    };

    const processRegistrationInterviewsEvents = (filter) => {
        let result = [];
        let lastDate = null;
        let content;
        let canPush = false;

        for (let e of events) {
            if (filter.onlyPending) {
                if (e.isCanceled == false && e.isSuccessful == false) {
                    //Is pending
                    canPush = true;
                } else {
                    canPush = false;
                }
            } else {
                canPush = true;
            }

            if (canPush) {
                if (lastDate == null) {
                    lastDate = e.scheduledDate
                    result.push({ calendarDateTitle: moment(e.scheduledDate).format("dddd DD MMMM YYYY") })
                }

                if (moment(lastDate).format("DD/MM/YYYY") != moment(e.scheduledDate).format("DD/MM/YYYY")) {
                    lastDate = e.scheduledDate
                    result.push({ calendarDateTitle: moment(e.scheduledDate).format("dddd DD MMMM YYYY") })
                }

                content = (<>
                    <Badge bg='' style={{ paddingTop: 1, paddingBottom: 3, fontWeight: 400, background: e.MeetingType.color, color: getTextColorForBackground(e.MeetingType.color) }} className={`statusBadge fs-14`}>{e.MeetingType.name}</Badge>
                    {
                        (e.isCanceled != null || e.isSuccessful != null) ?
                            <>
                                {(e.isCanceled == false && e.isSuccessful == false) ? <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} className={`ms-2 statusBadge fs-14`}>Pendiente</Badge> : <></>}
                                {(e.isCanceled == true) ? <Badge bg='danger' style={{ fontWeight: 400 }} className={`ms-2 statusBadge fs-14`}>Cancelada</Badge> : <></>}
                                {(e.isSuccessful == true) ? <Badge bg='success' style={{ fontWeight: 400 }} className={`ms-2 statusBadge fs-14`}>Realizada</Badge> : <></>}
                            </>
                            :
                            <></>
                    }
                </>
                )

                result.push({
                    date: e.scheduledDate,
                    users: e.Registration.PotentialParents.map(p=>`${p.surname ? p.surname.toUpperCase() : ""}, ${p.name}`),
                    content: content,
                    action: (<Link to={`/inscripciones/detalle/?id=${e.Registration.id}`}>
                        <Button variant="secondary">
                            <span><FontAwesomeIcon icon={faEye} /></span>
                        </Button>
                    </Link>)
                })
            }
        }

        setEventsParsed(result);
    }

    return (
        <div>
            <Row className='mb-4'>
                <Form.Check type="switch" className="" defaultChecked={true} onChange={(event) => handleInterviewsFilter(event)} label="Solo pendientes" />
            </Row>
            {eventsParsed.map((e, i) => (
                <>
                    {e.calendarDateTitle != null ?
                        <Row className={i == 0 ? '' : 'mt-3'}>
                            <span className='fs-11'>{e.calendarDateTitle}</span>
                        </Row>
                        :
                        <Row className={`mb-1 ps-2 pe-2 pt-1 pb-1`} style={{ background: '#F0F0F0', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2 ps-0 pe-0'>
                                <div style={{ textAlign: 'center' }}>
                                    {moment(e.date).format("HH:mm")}
                                </div>
                            </Col>
                            <Col md={4} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    {e.users.map((r, j) => (
                                       <div>{r}</div>
                                    ))}
                                </div>
                            </Col>
                            <Col className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'left' }} className='pt-1 pb-1'>
                                    {e.content}
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'right' }}>
                                    {e.action}
                                </div>
                            </Col>

                        </Row>
                    }
                </>
            ))}
        </div>
    )
}

export default CalendarList