import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faFile, faPalette, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST } from '../../../controllers/endpoints.controller';

function PsychopedagogicTags() {
    const PAGE_TITLE = "Informes psicopedagógicos";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion";

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getLevels()
        getTags();
    }, []);

    const [tags, setTags] = useState([]);

    //Modal

    const [tagColor, setTagColor] = useState("#0480FD");
    const handleColorChange = (event) => {
        setTagColor(event.target.value);
    };

    const [showModalNewTag, setShowModalNewTag] = useState(false);

    const handleShowModalNewTag = () => {
        setTagColor("#0480FD");
        setShowModalNewTag(true);
    };

    const handleCloseModalNewTag = () => {
        setShowModalNewTag(false);
    }

    const getTags = async function () {
        const req = await GET(`/students/psychopedagogic/tags/byLevel/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setTags(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postTag = async function () {
        const body = {
            name: document.getElementById('textNewTagName').value,
            color: tagColor.replace('#', ''),
            leveld: newGradeLevelId
        }

        const req = await POST(`/students/psychopedagogic/tag`, getUserToken(), body);
        const res = await req.json();
        console.log(body)
        if (req.status === 201) {
            handleCloseModalNewTag();
            getTags();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [levels, setLevels] = useState([]);
    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [newGradeLevelId, setNewGradeLevelId] = useState(null);
    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey.name);
        setNewGradeLevelId(eventKey.id);
    };

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
            setSelectedLevelOption(res[0].name)
            setNewGradeLevelId(res[0].id)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nueva categoría'} func={handleShowModalNewTag} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={8} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Color
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {tags.map((r, i) => (
                        <div>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={3} className='align-center-vertically-v2 justify-content-center'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.name}
                                        </div>
                                    </Col>
                                    <Col md={8} className='align-center-vertically-v2 justify-content-center' style={{ textAlign: 'right' }}>
                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }} className='mt-1 mb-1'>
                                            <Badge bg='' style={{ background: `${r.color ? `#${r.color}` : '#FFFFFF'}` }} className='statusBadge wi-50 fs-14'> </Badge>
                                        </div>
                                    </Col>
                                    
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='' show={showModalNewTag} onHide={handleCloseModalNewTag} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Nueva categoría</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Row>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" id="textNewTagName" />
                            </div>
                        </Col>

                        <Col md={6} className='mb-2  align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col className='' md={6}>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon style={{ height: 16 }} icon={faPalette} className='dropDownIcon' />
                                <Form.Control
                                    className="textInputGray fs-14 wi-300"
                                    type="color"
                                    defaultValue="#0480FD"
                                    title="Color"
                                    size="lg"
                                    value={tagColor}
                                    onChange={handleColorChange}
                                />
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewTag}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postTag} ><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default PsychopedagogicTags