import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Container, Dropdown, DropdownMenu, Form, Modal, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faFileArchive, faFilter, faPen, faPlus, faSearch, faTimes, faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { DEL, GET, POST } from '../../../controllers/endpoints.controller'

function CoursesConfig() {
    const PAGE_TITLE = "Cursos";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getSchoolYears();
    }, []);

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteClassroomId, setDeleteClassroomId] = useState(null);
    const [deleteClassroomName, setDeleteClassroomName] = useState("");

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, name) => {
        setDeleteClassroomId(id);
        setDeleteClassroomName(name);
        setShowConfirmDeleteModal(true);
    };

    //Modal nuevo curso
    const [showNewCourse, setShowNewCourse] = useState(false);
    const handleShowNewCourse = () => {
        setSelectedSchoolYearOption('Ciclo lectivo');
        setSelectedCurriculumOption('Plan de estudios');
        setSelectedDegreeOption('Grado / Año');
        getCurriculums();
        setShowNewCourse(true);
    };

    const handleCloseModalNewCourse = () => {
        setShowNewCourse(false);
    }

   
    const [selectedCurriculumOption, setSelectedCurriculumOption] = useState('Plan de estudios');
    const [selectedCurriculumId, setSelectedCurriculumId] = useState(null);

    const handleSelectCurriculum = (eventKey) => {
        setSelectedCurriculumOption(curriculums[eventKey].name);
        setSelectedCurriculumId(curriculums[eventKey].id);

        getClassroomsByCurriculumId(curriculums[eventKey].id)
    };

    const [selectedDegreeOption, setSelectedDegreeOption] = useState('Grado / Año');
    const [selectedDegreeId, setSelectedDegreeId] = useState(null);

    const handleSelectDegree = (eventKey) => {
        setSelectedDegreeOption(degrees[eventKey].name);
        setSelectedDegreeId(degrees[eventKey].id);

        // getStudentsBySearchParameters(degrees[eventKey].id, "a", selectedSchoolYearOption, selectedCurriculumId);
    };

    const [selectedSchoolYearOption, setSelectedSchoolYearOption] = useState('Ciclo lectivo');
    const [selectedSchoolYearId, setSelectedSchoolYearId] = useState(null);

    const handleSelectSchoolYear = (eventKey) => {
        setSelectedSchoolYearOption(schoolYears[eventKey].year);
        setSelectedSchoolYearId(schoolYears[eventKey].id);
    };

    const [selectedSchoolYearGeneralOption, setSelectedSchoolYearGeneralOption] = useState('Ciclo lectivo');

    const handleSelectSchoolYearGeneral = (eventKey) => {
        setSelectedSchoolYearGeneralOption(schoolYears[eventKey].year);
        getClassrooms(schoolYears[eventKey].year)
    };

    const [selectedDivision, setSelectedDivision] = useState('');

    const handleSelectDivision = (e) => {
        setSelectedDivision(e.target.value);

        if (e.target.value != "") {
            getStudentsBySearchParameters(selectedDegreeOption, e.target.value, selectedSchoolYearOption, selectedCurriculumId, filterDivision);
        }
    };

    const [filterDivision, setFilterDivision] = useState(true);

    const handleCheckFilterDivision = (e) => {
        setFilterDivision(e.target.checked);

        if (e.target.value != "") {
            getStudentsBySearchParameters(selectedDegreeOption, selectedDivision, selectedSchoolYearOption, selectedCurriculumId, e.target.checked);
        }
    };

    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    const [showAssignTeacherModal, setShowAssignTeacherModal] = useState(false);
    const handleShowAssignTeacherModal = (cid) => {
        getTeachers();
        setSelectedClassroomId(cid);
        setShowAssignTeacherModal(true);
    };

    const handleCloseAssignTeacherModal = () => {
        setShowAssignTeacherModal(false);
    }

    const [schoolYears, setSchoolYears] = useState([]);
    const [classrooms, setClassrooms] = useState([]);
    const [curriculums, setCurriculums] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [students, setStudents] = useState([]);
    const [teachers, setTeachers] = useState([]);

    const getSchoolYears = async function () {
        const req = await GET(`/classes/schoolYears`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchoolYears(res);
            setSelectedSchoolYearGeneralOption(res[0].year)
            getClassrooms(res[0].year)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassrooms = async function (sy) {
        if(sy != null){
            const req = await GET(`/classes/classrooms/bySchoolYear/${sy}`, getUserToken());
            const res = await req.json();
    
            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getCurriculums = async function () {
        const req = await GET(`/curriculum/curriculums`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let result = [];
            console.log(res)
            setCurriculums(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassroomsByCurriculumId = async function (curriculumId) {
        const req = await GET(`/curriculum/grades/byCurriculumId/${curriculumId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setDegrees(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getStudentsBySearchParameters = async function (degree, division, schoolYear, curriculum, filterDivision) {
        const chain = "";
        //const req = await GET(`/classes/classroomStudents/byDegreeDivisionYear&CurriculumId/${degree}/${division}/${schoolYear}/${curriculum}/${!filterDivision}`, getUserToken());
        const req = await GET(`/students/search/withoutClassroom/byClassroomId/${0}/${chain == "" ? 'null' : chain}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postCourse = async function () {
        let sIds = [];

        for(let i = 0; i < students.length ; i++){
            if(document.getElementById(`checkAdd-${i}`).checked == true){
                sIds.push(students[i].id);
            }
        }

        const body = {
            degree: selectedDegreeOption,
            division: selectedDivision,
            gradeId: selectedDegreeId,
            year: selectedSchoolYearOption,
            students: sIds
        }

        const req = await POST(`/classes/classroom`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewCourse();
            getClassrooms(selectedSchoolYearGeneralOption);
            setStudents([])
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTeachers = async function () {
        //Modificar
        const req = await GET(`/sso/users/search/ByRole/Docente Primaria/null`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setTeachers(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postAssignTeacher = async function (uid) {
        const body = {
            mainTeacherId: uid,
            classroomId: selectedClassroomId
        }

        const req = await POST(`/classes/classroom/teacher/main/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getClassrooms(selectedSchoolYearGeneralOption);
            handleCloseAssignTeacherModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteClassroom = async function () {
        const req = await DEL(`/classes/classrooms/${deleteClassroomId}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getClassrooms(selectedSchoolYearGeneralOption);
            handleCloseConfirmDeleteModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedSchoolYearGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedSchoolYearGeneralOption}>
                                    {schoolYears.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectSchoolYearGeneral(i)}>{opt.year}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo curso'} func={handleShowNewCourse} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={4} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'left' }}>
                                    Plan de estudios
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Nivel
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Alum.
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Doc. titulares
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {classrooms.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.degree}º {r.division}
                                        </div>
                                    </Col>
                                    <Col md={4} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            {r.Curriculum ? r.Curriculum.name : ""}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.Level ? `${r.Level.name.substring(0,5)}.` : ""}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.ClassroomStudents ? r.ClassroomStudents.length : "0"}
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.ClassroomMainTeachers.map((cmt, i) => (
                                                <p className='m-0'>{cmt.User.name} {cmt.User.surname}</p>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Link to={`/configuracion/cursos/alumnos/?schoolYear=${selectedSchoolYearGeneralOption}&courseId=${r.id}&courseName=${`${r.degree}º ${r.division}`}`} >
                                                <Button variant="outline-secondary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faUsers} />Alumnos</Button>
                                            </Link>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as={Link} to={`/configuracion/cursos/materias/?cicloLectivo=${r.year}&curso=${r.id}`}><FontAwesomeIcon icon={faEye} style={{ marginRight: 7, marginLeft: 2 }} />Ver materias</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowAssignTeacherModal(r.id)} ><FontAwesomeIcon icon={faChalkboardTeacher} style={{ marginRight: 10, marginLeft: 2 }} />Asignar docente titular</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r.id, `${r.degree}º ${r.division}`)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='lg' show={showNewCourse} onHide={handleCloseModalNewCourse} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo curso</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedSchoolYearOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedSchoolYearOption}>
                                        {schoolYears.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectSchoolYear(i)}>{opt.year}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                        <Col md={6} className='mb-2' >
                            <div className='align-center-vertically w-100'>
                                <FontAwesomeIcon icon={faFileArchive} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={selectedSchoolYearId == null}>
                                        {selectedCurriculumOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedCurriculumOption}>
                                        {curriculums.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectCurriculum(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={selectedCurriculumId == null}>
                                        {selectedDegreeOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedDegreeOption}>
                                        {degrees.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectDegree(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon height={30} icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' id="" placeholder="División" onChange={(val) => handleSelectDivision(val)} disabled={selectedDegreeId == null} />
                            </div>
                        </Col>
                        <Col md={6} className=''>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon height={30} icon={faFilter} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Check type="switch" label="Filtrar alumnos por división" onChange={(val) => handleCheckFilterDivision(val)} defaultChecked={true} />
                            </div>
                        </Col>


                    </Row>
                    <div className='mt-4'>
                        <div>
                            <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        #
                                    </div>
                                </Col>
                                <Col md={6} className='align-center-vertically-v2' >
                                    <div>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={3} className='align-center-vertically-v2' >
                                    <div style={{ textAlign: 'center' }}>
                                        Estado
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2' >
                                    <div style={{ textAlign: 'center' }}>
                                        Añadir
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {students && students.map((r, i) => (
                            <div key={i}>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {1 + i}
                                            </div>
                                        </Col>
                                        <Col md={6} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white' }}>
                                                        <img src={r.avatarFile} alt={r.name} />
                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p>{/*<p className='m-0' style={{ fontSize: 11, color: 'gray' }}>DNI 12.345.678 - 15 años</p>*/}</p>

                                            </div>
                                        </Col>
                                        <Col md={3} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {/*<Badge bg='' style={{ background: 'green' }} className='statusBadge fs-14'>Regular</Badge>*/}---
                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Form.Check type="switch" label="" id={`checkAdd-${i}`} defaultChecked={r.isChecked} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalNewCourse}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postCourse}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showAssignTeacherModal} onHide={handleCloseAssignTeacherModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Asignar docente titular</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <div className=''>
                        <div>
                            <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={10} className='align-center-vertically-v2' >
                                    <div>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2' >
                                    <div style={{ textAlign: 'center' }}>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {teachers.map((r, i) => (
                            <div key={i}>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={10} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white' }}>

                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p>{/*<p className='m-0' style={{ fontSize: 11, color: 'gray' }}>DNI 12.345.678 - 15 años</p>*/}</p>

                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <ActionButton title='Asignar' iconFa={faCheck} func={() => postAssignTeacher(r.id)}></ActionButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseAssignTeacherModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar materia</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar el curso "{deleteClassroomName}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={deleteClassroom}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CoursesConfig