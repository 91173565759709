import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBookOpen, faCalendar, faChalkboardTeacher, faCheck, faChevronRight, faClipboardCheck, faClipboardUser, faCog, faEllipsisV, faExclamationCircle, faEye, faFileCircleExclamation, faPen, faPenAlt, faPlus, faPlusCircle, faPlusMinus, faSearch, faTimes, faToggleOn, faTrash, faUser, faUserCircle, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserId, getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import moment from 'moment';
import { pluralize } from '../../../tools/tools';
import ReactDatePicker from 'react-datepicker';
import InfoField from '../../general-components/InfoField';
import Loading from '../../general-components/Loading';
import NotFound from '../../general-components/NotFound';

function ChatGroups() {
    const PAGE_TITLE = "Grupos";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración ";
    const PREVIOUS_URL = "/configuracion/cursos/materias"

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        getChatGroups();
        validateToken();
    }, []);

    const [showNewGroupModal, setShowNewGroupModal] = useState(false);
    const handleShowNewGroupModal = () => {
        setShowNewGroupModal(true);
        setSelectedPeriodOption('Período');
    };
    const handleCloseNewGroupModal = () => {
        setShowNewGroupModal(false);
    }

    const [selectedPeriodOption, setSelectedPeriodOption] = useState('Período');
    const [selectedPeriodId, setSelectedPeriodId] = useState(null);

    const handleSelectPeriod = (eventKey) => {
        setSelectedPeriodOption(periods[eventKey].name);
        setSelectedPeriodId(periods[eventKey].id);
    };

    const [periods, setPeriods] = useState([]);
    const [chatGroups, setChatGroups] = useState([
        {
            name: 'Preceptores',
            id: 123,
            members: [
                { name: 'Guadalupe', surname: 'Baccaro' },
                { name: 'Esteban', surname: 'Filipe' },
                { name: 'Santiago', surname: 'Salmeron' }
            ]
        }
    ]);

    const getChatGroups = async function () {
        const req = await GET(`/communication/groupChat/byLevelId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setChatGroups(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postChatGroup = async function () {
        const body = {
            name: document.getElementById("txtNewGroupName").value,
            userIds: selectedContacts.map((r) => r.id)
        }

        const req = await POST(`/communication/groupChat`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewGroupModal();
            getChatGroups();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const searchUsers = async function (roles) {
        const chain = document.getElementById('textSearch').value;

        if (chain.length >= 3) {
            const req = await GET(`/sso/users/search/ByRoles/${roles.join(",")}/${chain}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setSearchResults(res)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            setSearchResults([]);
        }
    }

    const [selectedContacts, setSelectedContacts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const handleSearchChange = (event) => {
        setSearchResults([]);
        searchUsers(["Preceptor Primaria", "Preceptor Secundaria", "Dirección Primaria", "Rectorado Secundaria", "Secretaría Secundaria", "Director Primaria", "Dirección Jardín", "Docente Secundaria", "Docente Primaria"]);
    };

    const handleContactClick = (contact) => {
        if (!selectedContacts.find(selected => selected.id === contact.id)) {
            setSelectedContacts([...selectedContacts, contact]);
            document.getElementById('textSearch').value = '';
            setSearchResults([]);
        }
    };

    const removeContact = (contactToRemove) => {
        const updatedContacts = selectedContacts.filter(contact => contact.id !== contactToRemove.id);
        setSelectedContacts(updatedContacts);
    };

    const handleOutsideClick = (event) => {
        if (document.getElementById('textSearch').value != '') { }
        const clickedOutside = !event.target.closest('#textSearch');
        if (clickedOutside) {
            /*             document.getElementById('textSearch').value = ''; */
            setSearchResults([])
        }
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteElement, setDeleteElement] = useState(null);
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (element) => {
        setDeleteElement(element);
        setShowConfirmDeleteModal(true);
    };


    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} > ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo grupo'} func={handleShowNewGroupModal} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={4} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Grupo
                                </div>
                            </Col>
                            <Col md={5} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Integrantes
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {chatGroups.length > 0 && chatGroups.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={4} className='align-center-vertically-v2 '>
                                        <div style={{ textAlign: 'left' }} className='align-center-vertically'>
                                            <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                 {r.avatarFile ? <img className='' src={r.avatarFile.file} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                            </div>
                                            {r.name}
                                        </div>
                                    </Col>
                                    <Col md={5} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.GroupMembersNames && r.GroupMembersNames.map((s, j) => (
                                                `${s.surname ? s.surname.toUpperCase() : ''} ${s.name}${j < r.GroupMembersNames.length - 1 ? ', ' : ''}`
                                            ))}
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal show={showNewGroupModal} onHide={handleCloseNewGroupModal} onClick={handleOutsideClick} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faUserGroup} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo grupo</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={6} className=''>
                            <div className='align-center-vertically mb-2'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' id="txtNewGroupName" placeholder="Nombre" />
                            </div>
                        </Col>
                        <Col className='' md={6}>
                            <div className='align-center-vertically '>
                                <FontAwesomeIcon icon={faUserGroup} className='dropDownIcon' style={{ height: 16 }} />
                                <div>
                                    <div className='align-center-vertically'>
                                        <Form.Control className='textInputGray fs-14 wi-300' autoComplete="off" id="textSearch" placeholder="Agregar participantes..." onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearchChange()
                                            }
                                        }} /><ActionButton iconFa={faSearch} title='' classExtra='ms-2' func={searchUsers} /></div>
                                    <div className='search-results' style={{ display: searchResults.length > 0 ? 'block' : 'none' }}>
                                        {searchResults && searchResults.map((contact, index) => (
                                            <div key={index} onClick={() => handleContactClick(contact)} style={{ cursor: 'pointer' }}>
                                                {contact.name} {contact.surname}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='ms-1 align-center-vertically mb-3' md={12}>
                            <div className='dropDownIcon' ></div>
                            <div className='align-center-vertically  wrapCont'>
                                {selectedContacts.map((contact, index) => (
                                    <div key={index} className='me-2 userSelectedModal mt-2' style={{ display: 'inline-block' }}>
                                        <span>{contact.name} {contact.surname}</span>
                                        <FontAwesomeIcon onClick={() => removeContact(contact)} icon={faTimes} className='remiderCross ms-2' />
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewGroupModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postChatGroup}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar el grupo "{deleteElement && deleteElement.name}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' ><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ChatGroups