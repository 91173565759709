import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faCheck, faCheckSquare, faClipboardUser, faEllipsisV, faEnvelope, faEye, faFileExport, faGraduationCap, faHeading, faIdCard, faImage, faPen, faPlus, faPrint, faSearch, faSortNumericUp, faTimes, faTrash, faUser, faUserFriends, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { faLetterboxd } from '@fortawesome/free-brands-svg-icons'
import { GET } from '../../../controllers/endpoints.controller'
import Loading from '../../general-components/Loading'

function Students() {
    const PAGE_TITLE = "Estudiantes";
    const PAGE_ICON = faUsers;

    const [myRole, setMyRole] = useState("")

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        setMyRole(getUserRole());
        //getUsers();
    }, []);

    const [users, setUsers] = useState([]);

    const searchUsers = async function () {
        const chain = document.getElementById('textSearch').value;

        if (chain.length >= 3) {
            const req = await GET(`/sso/users/search/ByRole/Alumno/${chain}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setUsers(res);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            setUsers([]);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className=''>
                    <Col>
                        <div className='align-center-vertically '>
                            <Form.Control className='textInputGray fs-14 wi-300' placeholder="Buscar..." id="textSearch" onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    searchUsers();
                                }
                            }} />
                            <ActionButton iconFa={faSearch} title='Buscar' classExtra='ms-2 d-none d-lg-block' func={searchUsers} />
                            <ActionButton iconFa={faSearch} title='' classExtra='ms-2 d-block d-lg-none' func={searchUsers} />
                        </div>
                    </Col>

                </Row>
                <Card className='mobile-cards cards mt-3'>
                    <div className='d-none d-lg-block'>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={5} className='align-center-vertically-v2'>
                                <div>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={4} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Correo electrónico
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>

                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='d-block d-lg-none'>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={4} xs={12} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Estudiantes
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {users.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={5} className='p-1 align-center-vertically-v2'>
                                        {myRole != "" && myRole != "Docente Secundaria" && myRole != "Docente Primaria" ? <Link to={`/estudiante/?id=${r.id}`} className='studentLink link-no-deco'><div style={{ textAlign: 'center' }} className='align-center-vertically-v2'>
                                            <div className='align-center-vertically'>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                                        {r.avatarFile ? <img src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                    </div>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                    ({r.id}) {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                                </div> </div>
                                        </div></Link> :
                                            <div style={{ textAlign: 'center' }} className='align-center-vertically-v2'>
                                                <div className='align-center-vertically'>
                                                    <div className='fitContentWidth'>
                                                        <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                                            {r.avatarFile ? <img src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        ({r.id}) {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                                    </div><Row>

                                                    </Row>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={4} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.email}
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }} className='pt-1 pb-1'>

                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                        {myRole != "" && myRole != "Docente Secundaria" && myRole != "Docente Primaria" ? <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <Link to={`/usuarios/detalle/?id=${r.id}`} >
                                                <Button variant="secondary">
                                                    <span><FontAwesomeIcon icon={faEye} /></span>
                                                </Button>
                                            </Link>
                                        </div> : ''}
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-block d-lg-none'>
                                <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={4} xs={12} className='p-1 align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }} className='align-center-vertically-v2'>
                                            {myRole != "" && myRole != "Docente Secundaria" && myRole != "Docente Primaria" ? <Link to={`/estudiante/?id=${r.id}`} className='studentLink link-no-deco'><div className='align-center-vertically'>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                                        {r.avatarFile ? <img src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        ({r.id}) {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                                    </div>
                                                    <div style={{ textAlign: 'left' }} className='smallFontSize'>
                                                        {r.email}
                                                    </div>
                                                </div>
                                            </div></Link>
                                                :
                                                <div className='align-center-vertically'>
                                                    <div className='fitContentWidth'>
                                                        <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                                            {r.avatarFile ? <img src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={{ textAlign: 'left' }}>
                                                            ({r.id}) {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                                        </div>
                                                        <div style={{ textAlign: 'left' }} className='smallFontSize'>
                                                            {r.email}
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>


        </div>
    )
}

export default Students