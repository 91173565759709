import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faCross, faEllipsisV, faFolderOpen, faGraduationCap, faMinus, faPen, faPenClip, faPenFancy, faPlus, faSignature, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST } from '../../../controllers/endpoints.controller'
import Moment from 'react-moment'
import { getCroppedImg } from '../../../tools/tools'
import Cropper from 'react-easy-crop'

function Signatures() {
    const PAGE_TITLE = "Docentes";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getTeachers();
    }, []);

    const [employees, setEmployees] = useState([])

    //DropDown Employee
    const [selectedEmployeeOption, setselectedEmployeeOption] = useState('Docente');
    const [selectedEmployeeOptionId, setselectedEmployeeOptionId] = useState(null);
    const handleSelectEmployee = (eventKey) => {
        setselectedEmployeeOption(`${employees[eventKey].surname ? employees[eventKey].surname.toUpperCase() : ''}, ${employees[eventKey].name}`);
        setselectedEmployeeOptionId(employees[eventKey].id);
    };

    const [showChangeSignatureModal, setShowChangeSignatureModal] = React.useState(false);
    const [actualChangeSignature, setActualChangeSignature] = React.useState(null);
    const handleCloseChangeSignatureModal = () => {
        setShowChangeSignatureModal(false);
        setActualChangeSignature(null);
    };

    const handleShowChangeSignatureModal = (user=null) => {
        if(user.name){
            setselectedEmployeeOption(`${user.surname ? user.surname.toUpperCase() : ''}, ${user.name}`);
            setselectedEmployeeOptionId(user.id);
        }else{
            setselectedEmployeeOption('Docente');
            setselectedEmployeeOptionId(null);
        }
        setAvatarFileSrc(null)
        setShowChangeSignatureModal(true);
    };

    const [avatarCrop, setAvatarCrop] = useState({ x: 0, y: 0 });
    const [avatarZoom, setAvatarZoom] = useState(1);
    const [avatarFileSrc, setAvatarFileSrc] = useState(null);
    const [avatarFileExtension, setAvatarFileExtension] = useState(null);

    const [avatarCropped, setAvatarCropped] = useState({});

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setAvatarCropped(croppedAreaPixels)
    }

    const onAvatarFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            console.log(file)
            const reader = new FileReader();
            reader.onload = () => {
                setAvatarFileSrc(reader.result);
                setAvatarFileExtension(file.name.split(".")[file.name.split(".").length - 1]);
            };
            reader.readAsDataURL(file);
        }
    };

    const postNewSignature = async function () {
        const croppedImage = await getCroppedImg(avatarFileSrc, {
            x: avatarCropped.x,
            y: avatarCropped.y,
            width: avatarCropped.width,
            height: avatarCropped.height
        });

        const body = {
            employeeId: selectedEmployeeOptionId,
            file: {
                b64: croppedImage,
                name: `signature-${selectedEmployeeOptionId}`,
                extension: avatarFileExtension.toLowerCase()
            }
        }

        const req = await POST(`/employees/signature`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseChangeSignatureModal();
            getTeachers();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTeachers = async function () {
        const req = await GET(`/employees`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setEmployees(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nueva firma'} func={handleShowChangeSignatureModal} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={4} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Docente
                                </div>
                            </Col>
                            <Col md={7} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Firma
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {employees.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={4} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            {r.surname ? r.surname.toUpperCase() : ''}, {r.name}
                                        </div>
                                    </Col>
                                    <Col md={7} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            {r.signature != null ? 
                                                <img src={r.signatureFile} width={50} height={50}/>
                                                : "Sin firma"
                                            }
                                        </div>
                                    </Col>

                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowChangeSignatureModal(r)}><FontAwesomeIcon icon={faPlus} style={{ marginRight: 10, marginLeft: 2 }}/>Nueva firma</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal show={showChangeSignatureModal} centered onHide={handleCloseChangeSignatureModal}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faSignature} style={{ minWidht: '20px' }} />
                    <Modal.Title>Nueva firma</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className='mb-3 align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0 ms-2'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedEmployeeOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedEmployeeOption}>
                                    {employees.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectEmployee(i)}>{opt.surname ? opt.surname.toUpperCase() : ''}, {opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <div>
                        <Row className=''>
                            <Form.Group controlId="formFile">
                                <Form.Control type="file" accept="image/*" onChange={onAvatarFileChange} multiple={false} />
                            </Form.Group>
                        </Row>
                        <Row>

                            <Col>
                                {avatarFileSrc && (
                                    <div className='mt-3'>
                                        <div className="cropper-container">
                                            <Cropper
                                                image={avatarFileSrc}
                                                crop={avatarCrop}
                                                zoom={avatarZoom}
                                                onCropChange={setAvatarCrop}
                                                onZoomChange={setAvatarZoom}
                                                aspect={1}
                                                showGrid={false}
                                                onCropComplete={onCropComplete}
                                            />
                                        </div>
                                        <div className="button-container">
                                            <ActionButton func={() => setAvatarZoom(avatarZoom + 0.1)} iconFa={faPlus} title='Agrandar' />
                                            <ActionButton func={() => setAvatarZoom(avatarZoom - 0.1)} iconFa={faMinus} title='Achicar' />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseChangeSignatureModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postNewSignature}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Signatures