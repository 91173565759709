import React, { useEffect, useState } from 'react'
import { Card, Button, Container, Row, Col, Modal, Badge, Alert, Carousel, Spinner, Form } from "react-bootstrap";
import { POST, GET } from '../../../controllers/endpoints.controller';
import { faCalendar, faCheck, faChevronLeft, faChevronRight, faClock, faCog, faExclamation, faExclamationCircle, faFile, faFileAlt, faFileCircleExclamation, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../navbar/Navbar'
import EventModal from '../calendar/EventModal';
import NotFound from '../../general-components/NotFound';
import Calendar from '../calendar/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Courses from '../courses/CoursesCard';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken, validateUser } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';

export default function Home() {
    useEffect(() => {
        document.title = `Tasky`;

    }, []);


    return (
        <div className='p-0'>
            <Row>
                <div className="login-container">
                    <div className="login-card">
                        <div className="logo">
                            <img src="/logo_color.svg" width={110} alt="Tasky Logo" />
                        </div>
                        <Row>
                            <Col className='pt-5'>
                                <NotFound iconFa={faExclamationCircle} text={"No estás habilitado para acceder"}/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Row>
        </div>
    );
}