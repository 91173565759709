import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Button, Card, Col, Dropdown, Form, Modal, Row, } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBook, faCalculator, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faPlus, faTimes, faTrash, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { useNavigate } from 'react-router-dom';
import { GET, POST } from '../../../controllers/endpoints.controller'

function CommGroups() {
    const PAGE_TITLE = "Comunicados";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
    }, []);

    const [groups, setGroups] = useState([{
        name: "Classroom 185",
        id: 123,
        groupClassroom: [{ classRoomId: 185, name: '1ºA' }]
    }])

    const [showNewGruop, setShowNewGruop] = useState(false);
    const handleCloseNewGruop = () => {
        setShowNewGruop(false)
    };
    const handleShowNewGruop = () => {
        setShowNewGruop(true);
    };

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} > ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo grupo'} func={handleShowNewGruop} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Grupo
                                </div>
                            </Col>
                            <Col md={6} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Miembros
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {groups.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={3} className='align-center-vertically-v2 '>
                                        <div style={{ textAlign: 'left' }}>

                                        </div>
                                    </Col>
                                    <Col md={6} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>

                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size={"lg"} show={showNewGruop} onHide={handleCloseNewGruop} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faUserGroup} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo grupo</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={6} className=''>
                            <div className='align-center-vertically mb-2'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' id="nameGroupInput" placeholder="Nombre" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewGruop}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CommGroups