import React, { useEffect, useRef, useState } from 'react'
import { Card, Button, Container, Row, Col, Modal, Badge, Alert, Carousel, Spinner, Form, Dropdown, ButtonGroup, DropdownButton, OverlayTrigger, Popover, } from "react-bootstrap";
import { POST, GET, DEL, PUT } from '../../../controllers/endpoints.controller';
import { faArrowLeft, faCalendar, faCheck, faCheckCircle, faChevronLeft, faChevronRight, faClock, faCog, faComment, faCommentAlt, faCommentDots, faDownload, faEllipsisV, faEnvelope, faExclamation, faExclamationCircle, faEye, faFile, faFileAlt, faFileArchive, faFileArrowUp, faFileCircleExclamation, faHeart, faLaugh, faListDots, faPaperclip, faPaperPlane, faPen, faPlaceOfWorship, faPlane, faPlus, faReply, faSearch, faSurprise, faThumbsUp, faTimes, faTrash, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../navbar/Navbar'
import EventModal from '../calendar/EventModal';
import NotFound from '../../general-components/NotFound';
import Calendar from '../calendar/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Courses from '../courses/CoursesCard';
import ActionButton from '../../general-components/ActionButton';
import { getUserId, getUserRole, getUserToken, logout, validateToken, validateUser } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import moment from 'moment';
import * as Emoji from "quill-emoji";
import DatePicker from 'react-datepicker';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get, onValue } from "firebase/database";
import { convert2Base64, downloadFile } from '../../../tools/tools';
import Chat from './Chat';

function Communications() {
    const PAGE_TITLE = "Comunicaciones";
    const PAGE_ICON = faCommentAlt;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getUserIdNumber();
        Quill.register("modules/emoji", Emoji);
        getCommunications();
        moment.locale('es');
        getGroups();
    }, []);

    const quillRef = useRef(null);

    const [userId, setUserId] = useState(-1)
    const getUserIdNumber = () => {
        setUserId(getUserId());
    }

    const [communicationList, setCommunicationList] = useState([
        /* {
            title: 'Fiesta de Fin de Año',
            content: `<p><strong>Queridos padres y alumnos,</strong></p>
                      <p>Nos complace anunciar que la <span style="color:blue;">Fiesta de Fin de Año</span> se llevará a cabo el próximo viernes. Les pedimos que vengan con atuendos <em>formales</em> y preparados para disfrutar de una noche <strong>inolvidable</strong>.</p>
                      <p><span style="color:green;">¡Esperamos verlos a todos!</span></p>`,
            createdAt: "2024-04-08T16:33:05.000Z",
            name: 'Ana',
            surname: 'Gómez',
            files: [
                {
                    id: 1234,
                    name: 'foto',
                    extension: 'png'
                },
                {
                    id: 1234,
                    name: 'foto',
                    extension: 'png'
                }
            ],
            isDraft: true
        },
        {
            title: 'Taller de Arte',
            content: `<p>Nos complace informarles que se abrirán inscripciones para el <span style="color:purple;"><strong>Taller de Arte</strong></span>.</p>
                      <p>El taller está dirigido a todos los estudiantes que deseen explorar su <em>creatividad</em> y mejorar sus habilidades artísticas. Las clases comenzarán el 15 de marzo y se realizarán los <strong>miércoles</strong> y <strong>viernes</strong>.</p>
                      <p><span style="color:orange;">¡No te lo pierdas!</span></p>`,
            createdAt: "2024-04-08T16:33:05.000Z",
            name: 'María',
            surname: 'López'
        },
        {
            title: 'Campeonato de Atletismo',
            content: `<p>Nos complace anunciar el <span style="color:red;"><strong>Campeonato de Atletismo</strong></span> que se llevará a cabo el próximo mes.</p>
                      <p>Invitamos a todos los estudiantes a participar y demostrar sus <em>habilidades deportivas</em>. Habrá premios para los ganadores en cada categoría. ¡Prepárense para un evento lleno de <strong>emoción</strong>!</p>
                      <p><span style="color:blue;">¡Nos vemos en la pista!</span></p>`,
            createdAt: "2024-04-08T16:33:05.000Z",
            name: 'Carlos',
            surname: 'Ramírez'
        },
        {
            title: 'Visita al Museo de Ciencias',
            content: `<p>Estamos emocionados de anunciar una <span style="color:green;"><strong>visita educativa</strong></span> al Museo de Ciencias para los estudiantes de 3er año.</p>
                      <p>La visita se llevará a cabo el próximo lunes. Durante la visita, los estudiantes tendrán la oportunidad de aprender sobre diversas áreas de la ciencia y participar en actividades <em>interactivas</em>.</p>
                      <p><span style="color:purple;">¡Será una experiencia <strong>increíble</strong>!</span></p>`,
            createdAt: "2024-04-08T16:33:05.000Z",
            name: 'Luis',
            surname: 'Martínez'
        },
        {
            title: 'Concurso de Matemáticas',
            content: `<p>Invitamos a todos los alumnos a participar en el <span style="color:orange;"><strong>Concurso de Matemáticas</strong></span> que se celebrará en nuestro colegio.</p>
                      <p>Este concurso es una excelente oportunidad para que los estudiantes <em>demuestren sus conocimientos</em> y habilidades matemáticas. Habrá premios para los tres primeros lugares.</p>
                      <p><span style="color:red;">¡Anímate y participa!</span></p>`,
            createdAt: "2024-04-08T16:33:05.000Z",
            name: 'Elena',
            surname: 'Pérez'
        },
        {
            title: "Celebración del Día del Estudiante",
            content: "<p>¡Queridos estudiantes!</p><p>Les informamos que este viernes celebraremos el <strong>Día del Estudiante </strong>con actividades divertidas y emocionantes. ¡Ven con tu mejor actitud y participa en todas las actividades!</p><p>No olvides vestirte de manera <em>festiva </em>y traer tu entusiasmo. <span style=\"background-color: rgb(235, 214, 255);\">¡Será un día inolvidable!</span></p><ul><li>En el colegio</li><li>15hs</li></ul><p>Link de inscripción <a href=\"https://www.google.com.ar/?hl=es\" rel=\"noopener noreferrer\" target=\"_blank\">acá</a></p>",
            createdAt: "2024-04-08T16:33:05.000Z",
            userId: 21,
            name: "Valentina",
            surname: "Francesch"
        },
        {
            title: "Bienvenidos!",
            content: "<p>Y si!<span class=\"ql-emojiblot\" data-name=\"heart\">﻿<span contenteditable=\"false\"><span class=\"ap ap-heart\">❤</span></span>﻿</span> Nuevo año!</p>",
            createdAt: "2024-04-08T16:33:05.000Z",
            userId: 21,
            name: "Valentina",
            surname: "Francesch"
        } */
    ]);

    /*     useEffect(() => {
            setIsAnyDraft(false);
            checkIfDraftExist()
        }, [communicationList]) */

    const [showNewCommunicationModal, setShowNewCommunicationModal] = useState(false);
    const handleCloseNewCommunicationModal = () => setShowNewCommunicationModal(false);
    const handleShowNewCommunicationModal = () => {
        setIsEditCommunication(false);
        setValueContentModal('');
        setSelectedContacts([]);
        setValueTitleModal('');
        setSelectedScheduledDate(null);
        setIsPostReady(-1);
        setFile([]);
        setFilesIdsToPost([])
        setShowNewCommunicationModal(true);
        setSelectedGroups([]);
    };

    const [valueContentModal, setValueContentModal] = useState('');
    const [valueTitleModal, setValueTitleModal] = useState('');
    const handleChangeTitle = (e) => {
        setValueTitleModal(e.target.value)
    }

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], ['emoji'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
        ],
        "emoji-toolbar": true,
        "emoji-shortname": true,
    };

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteCommunicationElement, setDeleteCommunicationElement] = useState(null);
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (element) => {
        setDeleteCommunicationElement(element);
        setShowConfirmDeleteModal(true);
    };

    const [selectedScheduledDate, setSelectedScheduledDate] = useState(null);

    const [selectedScheduledDateModal, setSelectedScheduledDateModal] = useState(null);
    const handleScheduledDateModal = date => {
        setSelectedScheduledDateModal(date);
    };
    const [showDateModal, setShowDateModal] = useState(false);
    const handleCloseDateModal = (wantToSaveDate = true) => {
        if (wantToSaveDate) {
            setSelectedScheduledDate(selectedScheduledDateModal);
        }
        setShowDateModal(false)
    };
    const handleShowDateModal = () => {
        //handleCloseNewCommunicationModal();
        setSelectedScheduledDateModal(selectedScheduledDate);
        setShowDateModal(true);
    };

    const [contactsList, setContactList] = useState([
        {
            name: 'Preceptor Secundaria',
            surname: '',
            id: 123
        },
        {
            name: 'Secretaría Secundaria',
            surname: '',
            id: 124
        },
        {
            name: 'Dirección Primaria',
            surname: '',
            id: 125
        }
    ]);

    const [selectedContacts, setSelectedContacts] = useState([]);

    const handleContactClick = (contact) => {
        if (!selectedContacts.find(selected => selected.id === contact.id)) {
            setSelectedContacts([...selectedContacts, contact]);
        }
    };

    const removeContact = (contactToRemove) => {
        const updatedContacts = selectedGroups.filter(contact => contact.id !== contactToRemove.id);
        setSelectedGroups(updatedContacts);
    };

    const [editCommunicationElement, setEditCommunicationElement] = useState(false);
    const [isEditingCommunication, setIsEditCommunication] = useState(false);
    const handleEditCommunication = (r) => {
        setEditCommunicationElement(r);
        setIsEditCommunication(true);
        setValueContentModal(r.content);
        if (r.to) {
            setSelectedContacts(r.to);
        }
        setValueTitleModal(r.title);
        setIsPostReady(-1);
        setShowNewCommunicationModal(true);
    }

    /*     const [draftList, setDraftList] = useState([]);
        const [isAnyDraft, setIsAnyDraft] = useState(false);
        const checkIfDraftExist = () => {
            let a = [];
            for (let i = 0; i < communicationList.length; i++) {
                if (communicationList[i].fromId == userId && communicationList[i].isDraft) {
    
                    a.push(communicationList[i]);
                }
            }
            if (a.length > 0) {
                setDraftList(a);
                setIsAnyDraft(true);
            } else {
    
            }
        }
     */

    const [isPostReady, setIsPostReady] = useState(-1);
    const [filesIdsToPost, setFilesIdsToPost] = useState([])
    /* 
    {
    "title": "Comunicado de prueba",
    "content": "Este es un comunicado de prueba",
    "groupId": 3,
    "canReply": true,
    "isDraft": false,
    "show": true,
    "scheduledDate": "2024-07-23T14:35:25.446Z"
}
    */
    const postCommunication = async function (isDraft = false) {
        setIsPostReady(false)
        let a = [];
        for (let g of selectedGroups) {
            a.push(g.id);
        }

        const body =
        {
            title: valueTitleModal,
            content: valueContentModal,
            groupIds: a,
            canReply: canReply,
            isDraft: isDraft,
            show: true,
            scheduledDate: isDraft ? null : selectedScheduledDate ? selectedScheduledDate : null,
            fileIds: filesIdsToPost.length == 0 ? null : filesIdsToPost,
        }
        //communication
        const req = await POST(`/communications/communication`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            getCommunications();
            if (showDateModal) {
                handleCloseDateModal();
            }
            setIsPostReady(true);
            handleCloseNewCommunicationModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putCommunication = async function (isDraft) {
        setIsPostReady(false);
        let body = {
            title: valueTitleModal,
            content: valueContentModal,
            canReply: canReply,
            isDraft: isDraft,
            show: true,
            scheduledDate: isDraft ? null : selectedScheduledDate ? selectedScheduledDate : null,
        };
        const req = await PUT(`/communication/${editCommunicationElement.id}`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            setIsPostReady(true)
            console.log(res)
            getCommunications();
            handleCloseNewCommunicationModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteMeeting = async function (id) {
        const req = await DEL(`/communication/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getCommunications();
            handleCloseConfirmDeleteModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getCommunications = async function () {
        const req = await GET(`/communications/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            //res.reverse(); //para mostrar lo último más arriba
            console.log(res)
            setCommunicationList(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const interCardsRef = useRef(null);
    useEffect(() => {
        if (interCardsRef.current) {
            const container = interCardsRef.current;
            if (container.scrollHeight > container.clientHeight) {
                container.style.padding = '0px 8px 0px 0px'
            } else {
                container.style.padding = '0px 10px 0px 0px'
            }
        }
    }, [interCardsRef])

    const deleteDateSelected = () => {
        setSelectedScheduledDate(null);
    }

    const emojiData = [
        { shortname: ':smile:', unicode: '😊' },
        { shortname: ':heart:', unicode: '❤️' },
        { shortname: ':thumbsup:', unicode: '👍' },
        { shortname: ':clap:', unicode: '👏' }
    ];

    const [showPicker, setShowPicker] = useState(-1);

    const handleEmojiClick = (id, emoji) => {
        setReactions(prevState => ({
            ...prevState,
            [id]: [emoji],
        }));
        postReaction(id, emoji)
    };

    //Provisorio
    const [reactions, setReactions] = useState({});

    const handleMouseEnter = (id) => {
        setShowPicker(id);
    };

    const handleMouseLeave = () => {
        setShowPicker(-1);
    };

    const deleteMyReaction = (id) => {
        setReactions(prevState => ({
            ...prevState,
            [id]: [],
        }));
    }

    const postReaction = async function (commId, emoji) {

        let body = {
            communicationId: commId,
            emoji: emoji,
        };
        const req = await POST(`/communications/reaction`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            getCommunications();
            if (showDateModal) {
                handleCloseDateModal();
            }
            setIsPostReady(true);
            handleCloseNewCommunicationModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const handleCloseUploadFileModal = () => {
        setShowUploadFileModal(false)
    };
    const handleShowUploadFileModal = () => {
        setShowUploadFileModal(true);
    };

    const handleFile = async (event) => {
        setFile((prevState) => ([...prevState, event.target.files[0]]));
        handleCloseUploadFileModal();
    }
    const [file, setFile] = useState([]);

    const deleteFile = (index) => {
        setFile(prevFile => prevFile.filter((_, i) => i !== index));
    }


    const postFile = async function (i) {
        setIsPostReady(false)
        const f = await convert2Base64(file[i]);
        const aux = file[i].name.split('.')
        const fileExtension = `${aux[aux.length - 1]}`
        const fileName = file[i].name;

        const body = {
            b64: f,
            name: fileName.replace(`.${fileExtension}`, ""),
            extension: fileExtension
        }

        const req = await POST(`/files/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            setFilesIdsToPost((prevState) => ([...prevState, res.id]));
            if (file.length - 1 > i) {
                postFile(i + 1)
            } else {
                postCommunication(false)
            }
            console.log(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    // /files/byId/:fileId
    const getFileToDownload = async function (id) {
        downloadFile(id, "archivo-chat");
    }

    const [showChooseGroupModal, setShowChooseGroupModal] = useState(false);
    const handleCloseChooseGroupModal = () => {
        setSelectedGroupList("")
        setSelectedGroups([])
        setShowChooseGroupModal(false)
    };
    const handleShowChooseGroupModal = () => {
        setShowGroupList(false)
        setShowChooseGroupModal(true);
    };

    const [showGroupsList, setShowGroupList] = useState(false)
    const [selectedGroupList, setSelectedGroupList] = useState("")
    const handleShowGroupList = (selection) => { //selection = "role" / "level" / "classroom"
        setSelectedGroupList(selection);
        setShowGroupList(true);
    }
    const handleNotShowGroupList = () => {
        setShowGroupList(false);
        setSelectedGroups([])
    }
    const handleSelectGroups = (element) => {
        setSelectedGroups((prevState) => ([...prevState, element]));
    }
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [groups, setGroups] = useState([{
        name: "Nivel secundaria",
        groupLevels: [{ levelId: 2 }],
        id: 1
    },
    {
        name: "Profesores 1ero",
        groupRoles: [{ classroomId: 1, roleId: 1 }],
        id: 2
    },
    {
        name: "1ero",
        groupClassrooms: [{ classroomId: 230 }, { classroomId: 212 },],
        id: 23
    },
    ])
    const [canReply, setCanReply] = useState(true);
    const handleCheckCanReply = (e) => {
        setCanReply(e.target.checked);
    };

    const getGroups = async function () {
        const req = await GET(`/communication/groups`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            res.reverse(); //para mostrar lo último más arriba
            console.log(res)
            setGroups(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    function LikeButton({ r }) {
        let isUserReacted = false;
        for (let a of r.Reactions) {
            isUserReacted = a.Users.find(user => user.id == userId) != undefined;
            if (isUserReacted) {
                break;
            }
        }
        return (<>
            <FontAwesomeIcon onClick={() => setShowPicker(showPicker != -1 ? -1 : r.id)} className='blueHover pointer ps-1 pe-1' icon={faThumbsUp} style={{
                cursor: 'pointer',
                marginRight: '10px',
                color: isUserReacted ? '#0480FD' : 'inherit',
                fontSize: '14px'
            }} />
            {showPicker == r.id && (
                <div className="emoji-picker">
                    {emojiData.map((emoji, index) => (
                        <button key={index} className="emoji-button" onClick={() => handleEmojiClick(r.id, emoji.unicode)}>
                            {emoji.unicode}
                        </button>
                    ))}
                </div>
            )}</>
        )
    }

    return (
        <div className="app">
            <Navbar />
            <div className="d-none d-lg-block content">
                <Row className='toolbar m-0'>
                    <Col clas>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={8} xs={12} className='ps-0 pe-3'>
                        <Card className='cards h-100-1' style={{ padding: '13px 2px 13px 10px' }}>
                            <Row className=' mb-3'>
                                <Col className='ps-0 align-center-vertically'>
                                    <span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Comunicados</span>
                                </Col>
                                <Col className='endRow me-2 align-center-vertically'>
                                    {/* {isAnyDraft ? <ActionButton variant='secondary' iconFa={faEye} title={'Ver borradores'} func={handleShowDraftsModal} classExtra='fs-14 me-2' /> : ''}
                                     */}<ActionButton iconFa={faPlus} title={'Nuevo comunicado'} func={handleShowNewCommunicationModal} classExtra='fs-14' />
                                </Col>
                            </Row>
                            <Row className='slideContent' ref={interCardsRef}>
                                {communicationList.map((r, i) => (<>
                                    <Row key={i} className='communication-card mb-3 ps-1 pe-1 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8, border: r.isDraft ? "1px solid #ff000078" : "1px solid #F0F0F0", position: 'relative' }} >
                                        <div className='pt-1 pb-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '34px' }}>
                                            <p className='mb-0 fs-14 fw-600'>{r.title}</p>
                                        </div>
                                        <div style={{ backgroundColor: 'white' }} className='mt-1 mb-1 pb-4 pt-4'>
                                            <div dangerouslySetInnerHTML={{ __html: r.content }} />
                                            {r.CommunicationFiles && r.CommunicationFiles.length > 0 &&
                                                <div className='mt-3 align-center-vertically' style={{ flexWrap: 'wrap', rowGap: '4px' }}>
                                                    {r.CommunicationFiles.map((f, j) => (
                                                        <div key={j}>
                                                            <div onClick={() => getFileToDownload(f.id)} style={{ border: '1px solid transparent', backgroundColor: '#00000021', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap' }} className=' ps-2 pe-2 pt-1 pb-1 fs-12 me-2 blueHoverDownload'>
                                                                <div className='fitContentWidth align-center-vertically-v2'><FontAwesomeIcon icon={faFile} className='me-1' style={{ height: '11px' }} /> {f.name != null ? f.name : "Archivo"}</div>
                                                                <FontAwesomeIcon icon={faDownload} style={{ cursor: 'pointer', height: '11px', justifySelf: 'flex-end' }} className='ms-2' />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        <div className='pt-1 pb-1' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Row style={{ width: '100%' }}>
                                                <Col md={8} className='ps-0'>
                                                    <div className='align-center-vertically fitContentWidth' >
                                                        {r.User && <div className='align-center-vertically me-2 fs-14'>
                                                            <div className='fitContentWidth'>
                                                                <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                                    <img className='' src={r.User.avatarFile} alt={r.User.name} />
                                                                </div>
                                                            </div>
                                                            {r.User.surname ? r.User.surname.toUpperCase() : ""}, {r.User.name}
                                                        </div>}
                                                        <p className='smallFontSize mb-0'>{r.sendDate && `${moment(r.sendDate).format("DD/MM/YY HH:mm")} hs`} </p>
                                                    </div>
                                                </Col>
                                                <Col md={4} className='ps-0 pe-0 align-center-vertically endRow'>

                                                    <div className='align-center-vertically'>
                                                        <div className='align-center-vertically'>
                                                            {r.fromId == userId ? <><div onClick={() => handleEditCommunication(r)} className='blueHover ps-1 pe-2 pointer'><FontAwesomeIcon className='  ' icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} /></div>
                                                                <div onClick={() => handleShowConfirmDeleteModal(r)} className='blueHover ps-1 pe-2 pointer'><FontAwesomeIcon className=' ' icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} /></div>
                                                            </> :
                                                                <div className='blueHover ps-1 pe-2 pointer'> <FontAwesomeIcon className='  ' icon={faReply} style={{ marginRight: 10, marginLeft: 2 }} /></div>
                                                            }
                                                        </div>
                                                        {r.canReply && !r.isDraft && r.sendDate &&
                                                            <div className="emoji-reaction-container" onMouseEnter={() => handleMouseEnter(r.id)} onMouseLeave={handleMouseLeave} style={{ width: '100%' }}>
                                                                <LikeButton r={r} />
                                                            </div>}
                                                    </div>
                                                    {r.isDraft || r.scheduledDate ? <div className='align-center-vertically endRow' style={{ height: '100%' }}><p className='smallFontSize' style={{ color: 'red', textAlign: 'end', fontStyle: 'italic' }}>{r.isDraft ? 'Borrador' : r.scheduledDate != null && !r.sendDate ? `Envío programado para el ${moment(r.scheduledDate).format("DD/MM/YY HH:mm")}hs` : ''}</p></div> : ''}
                                                </Col>
                                            </Row>
                                        </div>
                                        {r.Reactions && r.Reactions.length > 0 &&
                                            <div className="reactions pe-1 ps-1" style={{ cursor: 'pointer', flexWrap: 'nowrap' }}>
                                                <OverlayTrigger
                                                    trigger="click"
                                                    placement="top"
                                                    overlay={
                                                        <Popover style={{ borderRadius: '10px' }}>
                                                            {/* <Popover.Header style={{borderTopLeftRadius:'10px', borderTopRightRadius:'10px'}}>Reacciones</Popover.Header>
                                                             */}<Popover.Body>
                                                                {r.Reactions.map((k, z) => (
                                                                    k.Users.map((user, idx) => (
                                                                        <div key={idx} onClick={() => user.id == userId && postReaction(r.id, k.emoji)} style={{ cursor: user.id == userId ? 'pointer' : 'normal' }}>
                                                                            <p className='mb-1 fs-12' key={idx}>{user.surname ? user.surname.toUpperCase() : ""}, {user.name} {k.emoji}</p>
                                                                            <p className='smallFontSize mb-1' style={{ marginTop: '-7px' }}>{user.id == userId ? 'Haz click para eliminar' : ''}</p>
                                                                        </div>
                                                                    ))
                                                                ))}
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        {r.Reactions.map((reaction, index) => (
                                                            <div key={index} className="reaction fs-14">
                                                                {reaction.emoji}{reaction.qty}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        }
                                    </Row>
                                    {r.Reactions && r.Reactions.length > 0 &&
                                        <div style={{ marginBottom: '10px' }}></div>
                                    }</>
                                ))}
                            </Row>
                        </Card>
                    </Col>
                    <Col md={4} className='p-0'>
                        <Row>
                            <Chat />
                        </Row>
                    </Col>
                </Row>
            </div>

            {showNewCommunicationModal &&
                <Modal size={'xl'} show={showNewCommunicationModal} onHide={handleCloseNewCommunicationModal} centered backdrop="static" style={{ zIndex: 1049 }} >
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropDownIcon' icon={faCommentAlt} style={{ minWidht: '20px' }} /><Modal.Title>{isEditingCommunication ? 'Editar comunicado' : 'Nuevo comunicado'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className=''>
                            <Col className='' md={6}>
                                <div className='align-center-vertically'>
                                    <div className='dropDownIcon'></div>
                                    <Button variant='outline-secondary' onClick={handleShowChooseGroupModal}>
                                        <FontAwesomeIcon icon={faUserGroup} className='me-2' />
                                        Añadir destinatarios</Button>
                                </div>
                            </Col>
                            <Col className='ms-1 align-center-vertically mb-2' md={12}>
                                <div className='align-center-vertically  wrapCont'>
                                    <div className='dropDownIcon'></div>
                                    {selectedGroups.map((r, index) => (
                                        <div key={index} className='me-2 userSelectedModal mt-2' style={{ display: 'inline-block' }}>
                                            <span>{r.name}</span>
                                            <FontAwesomeIcon onClick={() => removeContact(r)} icon={faTimes} className='remiderCross ms-2' />
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-2'>

                            <Col md={12}>
                                <div className='align-center-vertically'>
                                    <div className='dropDownIcon'></div>
                                    <Form.Control className='textInputGray fs-14' id="newCommunicationTitle" placeholder="Título" value={valueTitleModal} onChange={handleChangeTitle} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className='align-center-vertically pb-5'>
                                    <div className='dropDownIcon'></div>
                                    <ReactQuill theme="snow" value={valueContentModal} ref={quillRef} onChange={setValueContentModal} modules={modules} style={{ height: '350px', width: '100%' }} />
                                </div>
                            </Col>


                        </Row>
                        <Row>
                            <Col className='fitContentWidth'>
                                <div className='align-center-vertically'>
                                    <div className='dropDownIcon'></div>
                                    <Button variant='outline-secondary mt-2' onClick={handleShowUploadFileModal}>
                                        <FontAwesomeIcon icon={faPaperclip} className='me-2' />
                                        Adjuntar archivo</Button>
                                </div>

                            </Col>
                            <Col md={12} className='pt-2'>
                                <div className='align-center-vertically'>
                                    <div className='dropDownIcon'></div>
                                    {file != null &&
                                        file.map((f, i) => (
                                            <div key={i} style={{ border: '1px solid grey', borderRadius: '8px' }} className='fitContentWidth ps-3 pe-2 pb-1 pt-1 me-2'>
                                                <FontAwesomeIcon icon={faFile} className='me-2' /> {f.name}
                                                <FontAwesomeIcon onClick={() => deleteFile(i)} icon={faTimes} style={{ cursor: 'pointer' }} className='remiderCross ms-2' />
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon height={30} icon={faThumbsUp} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Check type="switch" label="Permitir reacciones" onChange={(val) => handleCheckCanReply(val)} defaultChecked={true} />
                            </div>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <div className='align-center-vertically scheduleDateModal'>
                                {selectedScheduledDate != null && <div className='align-center-vertically scheduleDateModal'><p className='mb-0 fs-12 fitContentWidth' style={{ textAlign: 'left' }}>Envío programado para el {moment(selectedScheduledDate).format("DD/MM/YY")} a las {moment(selectedScheduledDate).format("HH:mm")}hs</p>
                                    <FontAwesomeIcon icon={faTimes} className='remiderCross crossScheduleDate ms-2' onClick={deleteDateSelected} style={{ cursor: 'pointer' }} />
                                </div>}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="outline-secondary" className='fs-14 ms-2' onClick={handleCloseNewCommunicationModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                                <Button variant="secondary" className='fs-14 ms-2' disabled={valueTitleModal.trim() == '' || isPostReady != -1} onClick={isEditingCommunication ? () => putCommunication(true) : file.length > 0 ? () => postFile(0) : () => postCommunication(true)}><FontAwesomeIcon className='mr-10' icon={faFile} />Guardar borrador</Button>
                                <Button onClick={handleShowDateModal} variant="primary" className='fs-14 ms-2' disabled={isPostReady != -1}><FontAwesomeIcon className='mr-10' icon={faPaperPlane} />Programar envío</Button>
                                <Button variant="primary" className='fs-14 ms-2' onClick={isEditingCommunication ? () => putCommunication(false) : file.length > 0 ? () => postFile(0) : () => postCommunication(false)} disabled={valueContentModal == '' || selectedGroups.length == 0 || valueTitleModal.trim() == '' || isPostReady != -1}>
                                    {isPostReady != -1 ?
                                        <div className="justify-content-center" style={{ width: '85px' }}>
                                            <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                                        </div>
                                        : <><FontAwesomeIcon className='mr-10' icon={faCheck} />Publicar</>}
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar el comunicado "{deleteCommunicationElement && deleteCommunicationElement.title}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={() => deleteMeeting(deleteCommunicationElement.id)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            {showDateModal && <div>
                <div style={{ opacity: 0.5, zIndex: 1050 }}></div>
                <Modal show={showDateModal} onHide={() => handleCloseDateModal(false)} className="custom-backdrop" centered style={{ zIndex: 1051 }}>
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faPaperPlane} style={{ minWidht: '20px' }} /><Modal.Title>Programar envío</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-4 pb-4'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <DatePicker
                                selected={selectedScheduledDateModal}
                                onChange={handleScheduledDateModal}
                                showTimeSelect
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                placeholderText="Fecha de publicación"
                                className='dropdown-toggle wi-300'
                                locale='es'
                                minDate={moment().toDate()} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={() => handleCloseDateModal(false)}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="primary" className='fs-14 me-2' disabled={selectedScheduledDateModal == null} onClick={handleCloseDateModal}><FontAwesomeIcon className='mr-10' icon={faCheck} />Programar envío</Button>
                    </Modal.Footer>
                </Modal></div>
            }

            <Modal show={showUploadFileModal} onHide={handleCloseUploadFileModal} className="custom-backdrop" centered style={{ zIndex: 1051 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFileArrowUp} style={{ minWidht: '20px' }} /><Modal.Title>Subir archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group controlId="formFile">
                            <Form.Control type="file" multiple={false} onChange={(event) => handleFile(event)} />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseUploadFileModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                </Modal.Footer>
            </Modal>

            {showChooseGroupModal &&
                <Modal show={showChooseGroupModal} onHide={handleCloseChooseGroupModal} className="custom-backdrop" centered style={{ zIndex: 1051 }}>
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faUserGroup} style={{ minWidht: '20px' }} /><Modal.Title>Añadir destinatarios</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!showGroupsList ? <>
                            <Row onClick={() => handleShowGroupList("classroom")} className='mb-2 ps-2 pe-2 pt-2 pb-2 blueHover pointer' style={{ background: '#F0F0F0', borderRadius: 8, flexWrap: 'nowrap' }}>
                                <Col className=' align-center-vertically-v2' md={8}>
                                    <div style={{ textAlign: 'left' }} className=' '>
                                        Por curso
                                    </div>
                                </Col>
                                <Col className='align-center-vertically-v2 pe-2' md={4}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'end' }}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Col>
                            </Row>
                            <Row onClick={() => handleShowGroupList("level")} className='mb-2 ps-2 pe-2 pt-2 pb-2 blueHover pointer' style={{ background: '#F0F0F0', borderRadius: 8, flexWrap: 'nowrap' }}>
                                <Col className=' align-center-vertically-v2' md={8}>
                                    <div style={{ textAlign: 'left' }} className=' '>
                                        Por Nivel
                                    </div>
                                </Col>
                                <Col className='align-center-vertically-v2 pe-2' md={4}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'end' }}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Col>
                            </Row>
                            <Row onClick={() => handleShowGroupList("role")} className='mb-2 ps-2 pe-2 pt-2 pb-2 blueHover pointer' style={{ background: '#F0F0F0', borderRadius: 8, flexWrap: 'nowrap' }}>
                                <Col className=' align-center-vertically-v2' md={8}>
                                    <div style={{ textAlign: 'left' }} className=' '>
                                        Por rol
                                    </div>
                                </Col>
                                <Col className='align-center-vertically-v2 pe-2' md={4}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'end' }}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                </Col>
                            </Row>
                        </>
                            :
                            selectedGroupList == "level" ?
                                groups.map((r, i) => (
                                    r.GroupLevels && r.GroupLevels.length > 0 &&
                                    <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' key={i} style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={8} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <p className='classesTime m-0'><p className='m-0'>{r.name}:</p>{/*<p className='m-0' style={{ fontSize: 11, color: 'gray' }}>DNI 12.345.678 - 15 años</p>*/}</p>
                                                <div className='ps-1'>
                                                    {r.GroupLevels.map((item, j) => (
                                                        <>{item.role ? item.role.name : item.level.name} {j < r.GroupLevels.length - 1 && ', '}</>
                                                    ))
                                                    }</div>
                                            </div>
                                        </Col>
                                        <Col md={4} className='align-center-vertically-v2 endRow' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                {selectedGroups.some(item => item.id == r.id) ? <ActionButton title='Eliminar' iconFa={faTimes} variant='danger' func={() => removeContact(r)}></ActionButton> : <ActionButton title='Añadir' iconFa={faCheck} func={() => handleSelectGroups(r)}></ActionButton>}
                                            </div>
                                        </Col>
                                    </Row>))
                                : selectedGroupList == "role" ?
                                    groups.map((r, i) => (
                                        r.GroupRoles && r.GroupRoles.length > 0 &&
                                        <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' key={i} style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={8} className='align-center-vertically-v2'>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <p className='classesTime m-0'><p className='m-0'>{r.name}:</p>{/*<p className='m-0' style={{ fontSize: 11, color: 'gray' }}>DNI 12.345.678 - 15 años</p>*/}</p>
                                                    <div className='ps-1'>
                                                        {r.GroupRoles.map((item, j) => (
                                                            <>{` ${item.role && item.role.name}`} {j < r.GroupLevels.length - 1 && ', '}</>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md={4} className='align-center-vertically-v2 endRow' style={{ textAlign: 'right' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {selectedGroups.some(item => item.id == r.id) ? <ActionButton title='Eliminar' iconFa={faTimes} variant='danger' func={() => removeContact(r)}></ActionButton> : <ActionButton title='Añadir' iconFa={faCheck} func={() => handleSelectGroups(r)}></ActionButton>}
                                                </div>
                                            </Col>
                                        </Row>))
                                    :
                                    groups.map((r, i) => (
                                        r.GroupClassrooms && r.GroupClassrooms.length > 0 &&
                                        <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' key={i} style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={8} className='align-center-vertically-v2'>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <p className='classesTime m-0'><p className='m-0'>{r.name}:</p>{/*<p className='m-0' style={{ fontSize: 11, color: 'gray' }}>DNI 12.345.678 - 15 años</p>*/}</p>
                                                    <div className='ps-1'>
                                                        {r.GroupClassrooms.map((item, j) => (
                                                            <>{item.classroom && `${item.classroom.degree}º${item.classroom.division}`} {j < r.GroupLevels.length - 1 && ', '}</>
                                                        ))
                                                        }</div>
                                                </div>
                                            </Col>
                                            <Col md={4} className='align-center-vertically-v2 endRow' style={{ textAlign: 'right' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {selectedGroups.some(item => item.id == r.id) ? <ActionButton title='Eliminar' iconFa={faTimes} variant='danger' func={() => removeContact(r)} ></ActionButton> : <ActionButton title='Añadir' iconFa={faCheck} func={() => handleSelectGroups(r)}></ActionButton>}
                                                </div>
                                            </Col>
                                        </Row>))
                        }
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        {!showGroupsList ?
                            <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseChooseGroupModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                            : <>
                                <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleNotShowGroupList}><FontAwesomeIcon className='mr-10' icon={faArrowLeft} />Volver</Button>
                                <Button variant="primary" className='fs-14 me-2' onClick={() => setShowChooseGroupModal(false)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                            </>}
                    </Modal.Footer>
                </Modal>}
        </div >
    );
}

export default Communications