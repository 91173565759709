import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Container, Dropdown, DropdownMenu, Form, Modal, OverlayTrigger, Row, Table, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClock, faCog, faEllipsisV, faEye, faFileArchive, faFilter, faListNumeric, faPen, faPlus, faSearch, faTimes, faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import DatePicker from 'react-datepicker';
import { GET, POST } from '../../../controllers/endpoints.controller'
import moment from 'moment'

function CoursesConfig() {
    const PAGE_TITLE = "Horarios";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getSchoolYears();
    }, []);

    const [showNewSchoolHourModal, setShowNewSchoolHourModal] = useState(false);
    const handleShowNewSchoolHour = () => {
        setSelectedLevelOption("Nivel");
        setSelectedLevelId(null);
        setSelectedSchoolYearOption("Ciclo lectivo")
        setSelectedSchoolYearId(null);
        setSelectedTimeStart(null);
        setSelectedTimeEnd(null);
        setShowNewSchoolHourModal(true);
        getLevels();
    };

    const handleCloseNewSchoolHour = () => {
        setShowNewSchoolHourModal(false);
    }

    const [showNewClassTimeModal, setShowNewClassTimeModal] = useState(false);
    const [newClassHourId, setNewClassHourId] = useState(null);
    const [newClassDay, setNewClassDay] = useState(null);
    const handleShowNewClassTimeModal = (hourId, day) => {
        setShowNewClassTimeModal(true);
        getClassesByClassroomId();
        setNewClassHourId(hourId);
        setNewClassDay(day);
    };

    const handleCloseNewClassTimeModal = () => {
        setShowNewClassTimeModal(false);
    }

    const [levels, setLevels] = useState([]);

    const [selectedSchoolYearOption, setSelectedSchoolYearOption] = useState('Ciclo lectivo');
    const [selectedSchoolYearId, setSelectedSchoolYearId] = useState(null);

    const handleSelectSchoolYear = (eventKey) => {
        setSelectedSchoolYearOption(schoolYears[eventKey].year);
        setSelectedSchoolYearId(schoolYears[eventKey].id);
    };

    const [selectedSchoolYearGeneralOption, setSelectedSchoolYearGeneralOption] = useState('Ciclo lectivo');

    const handleSelectSchoolYearGeneral = (eventKey) => {
        setSelectedSchoolYearGeneralOption(schoolYears[eventKey].year);
        getClassrooms(schoolYears[eventKey].year)
    };

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(-1);

    const handleSelect = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getScheduleByClassroomId(classrooms[eventKey].id)
    };

    const [selectedDivision, setSelectedDivision] = useState('');


    const [schoolYears, setSchoolYears] = useState([]);
    const [classrooms, setClassrooms] = useState([]);

    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [selectedLevelId, setSelectedLevelId] = useState(null);

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey.name);
        setSelectedLevelId(eventKey.id);
    };

    const [selectedTimeStart, setSelectedTimeStart] = useState(null);
    const handleChangeTimeStart = date => {
        setSelectedTimeStart(date);
    };

    const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);

    const handleChangeTimeEnd = date => {
        setSelectedTimeEnd(date);
    };

    const [selectedClassOption, setSelectedClassOption] = useState('Materia');
    const [selectedClassId, setSelectedClassId] = useState(null);

    const handleSelectClass = (eventKey) => {
        setSelectedClassOption(classes[eventKey].name);
        setSelectedClassId(classes[eventKey].id);
    };

    const [schedule, setSchedule] = useState([]);
    const [classes, setClasses] = useState([]);

    const getSchoolYears = async function () {
        const req = await GET(`/classes/schoolYears`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchoolYears(res);
            setSelectedSchoolYearGeneralOption(res[0].year)
            getClassrooms(res[0].year)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassrooms = async function (sy) {
        if (sy != null) {
            const req = await GET(`/classes/classrooms/bySchoolYear/${sy}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                getScheduleByClassroomId(res[0].id)
                setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                setSelectedClassroomId(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getScheduleByClassroomId = async function (classroomId) {
        const req = await GET(`/class/schedule/byClassroomId/${classroomId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchedule(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postSchoolHour = async function () {
        const body = {
            start: moment(selectedTimeStart).format('HHmm'),
            end: moment(selectedTimeEnd).format('HHmm'),
            year: selectedSchoolYearOption,
            isBreak: document.getElementById('textNewSchoolHourBreak').checked,
            isLunch: document.getElementById('textNewSchoolHourLunch').checked,
            number: document.getElementById('textNewSchoolHourNumber').value,
            levelId: selectedLevelId
        }
        console.log(body)

        const req = await POST(`/class/schedule/schoolHour/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseNewSchoolHour();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassesByClassroomId = async function () {
        const req = await GET(`/classes/classes/byClassroomId/${selectedClassroomId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClasses(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postClassTime = async function () {
        const body = {
            hourId: newClassHourId,
            day: newClassDay,
            classId: selectedClassId
        }

        const req = await POST(`/class/schedule/classTime`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getScheduleByClassroomId(selectedClassroomId);
            handleCloseNewClassTimeModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const ClassComponent = ({ startHour, endHour, name, color, teachers, i }) => {
        const [teacher, setTeacher] = useState({});
        const [show, setShow] = useState(false);

        const getUserById = async function (userId) {
            const req = await GET(`/sso/users/byId/${userId}`, getUserToken());
            const res = await req.json();
    
            if (req.status === 200) {
                console.log(res)
                setTeacher(res);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }

        const handleToggle = (nextShow) => {
            setShow(nextShow);
            if (nextShow) {
                setTeacher({});
                getUserById(teachers[0].userId);
            }
        };

        if(teachers && teachers.length > 0){
            return (
                <OverlayTrigger key={i} trigger="click" placement="left" overlay={<Popover id={i} style={{ borderRadius: '15px', minWidth: '200px' }} rootCloseEvent="mousedown">
                    <div style={{ borderRadius: '15px', overflow: 'hidden' }}>
                        <Row>
                            <div style={{ textAlign: 'center', backgroundColor: color ? color : '#0580FD', color: 'white' }} className='pt-1 pb-1' >
                                {name}
                            </div>
                            <FontAwesomeIcon icon={faTimes} style={{ color: 'white', position: 'absolute', right: '0px', marginTop: '6px', marginRight: '3px', cursor: 'pointer'}} className='fitContentWidth' onClick={() => handleToggle(!show)} />
                        </Row>
                        <Row className='mt-2'>
                            <Col className='align-center-vertically' style={{ flexDirection: 'column' }}>
                                <div className='studentImg circle-container' style={{ width: '50px', height: '50px', border: 'none' }}>
                                    { <img src={teacher.avatarFile} /> }
                                </div>
                                <div className='fitContentWidth roundedText-v2 borderDiv fs-11' style={{ position: 'absolute', top: '75px' }}>
                                    {teacher.surname && teacher.surname.toUpperCase()}, {teacher.name}
                                </div>
                                <p className='fs-11' style={{ marginTop: '18px' }}>{`${(startHour + "").slice(0, -2).padStart(2, '0')}:${(startHour + "").slice(-2)}`} a {`${(endHour + "").slice(0, -2).padStart(2, '0')}:${(endHour + "").slice(-2)}`}</p>
                            </Col>
                        </Row>
                    </div>
                </Popover>} rootClose show={show} onToggle={() => handleToggle(!show)}>
                    <div style={{ backgroundColor: color ? color : '#0580FD', color: 'white', borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", cursor: 'pointer' }} className='align-center-vertically'>{name}</div>
                </OverlayTrigger>
            )
        }else{
            return(<div style={{ backgroundColor: color ? color : '#0580FD', color: 'white', borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", cursor: 'pointer' }} className='align-center-vertically'>{name}</div>)
        }
    }

    const BreakComponent = () => {
        return (
            <div style={{ backgroundColor: '#6C757D', borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", color: 'white' }} className='align-center-vertically'>RECREO</div>
        )
    }

    const FreeClassComponent = () => {
        return (
            <div style={{ backgroundColor: '#F0F0F0', borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", color: 'black' }} className='align-center-vertically'>---</div>
        )
    }

    const AddClassComponent = ({hourId, day}) => {
        return (
            <div onClick={() => handleShowNewClassTimeModal(hourId, day)} style={{ border: '2px solid #0480FD',background: 'white', borderRadius: '5px', height: '25px', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", color: '#0480FD' }} className='mt-1 align-center-vertically'><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></div>
        )
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedSchoolYearGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedSchoolYearGeneralOption}>
                                    {schoolYears.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectSchoolYearGeneral(i)}>{opt.year}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nueva hora cátedra'} func={handleShowNewSchoolHour} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <table className='table m-0 br-15' style={{ textAlign: 'center' }}>
                        <thead className='table br-15'>
                            <tr style={{ borderBottomColor: 'rgb(222, 226, 230) !important', borderBottomWidth: '0px' }} className='tableHtml blueTasky-back whiteText'>
                                <td className='w-div6' style={{ width: '150px', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}></td>
                                <td className='w-div6'>Lunes</td>
                                <td className='w-div6'>Martes</td>
                                <td className='w-div6'>Miércoles</td>
                                <td className='w-div6'>Jueves</td>
                                <td className='w-div6' style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>Viernes</td>
                            </tr>
                        </thead>
                        <tbody style={{ borderWidth: '0px !important' }}>
                            {schedule.map((r, i) =>
                                <tr key={i} style={{ borderRadius: '5px' }}>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '90px'}`, borderRadius: '5px', justifyContent: 'center' }} className='blueTasky-back whiteText align-center-vertically'>
                                            {`${(r.start + "").slice(0, -2).padStart(2, '0')}:${(r.start + "").slice(-2)}`} a {`${(r.end + "").slice(0, -2).padStart(2, '0')}:${(r.end + "").slice(-2)}`}
                                        </div>
                                    </td>

                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '90px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.monday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} color={dd.color} teachers={dd.Teachers} i={i} key={i}/>
                                            ))}
                                            {r.Days.monday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak ? <BreakComponent /> : <AddClassComponent hourId={r.id} day={1} />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '90px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.tuesday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i}/>
                                            ))}
                                            {r.Days.tuesday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak ? <BreakComponent /> : <AddClassComponent hourId={r.id} day={2} />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '90px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.wednesday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i}/>
                                            ))}
                                            {r.Days.wednesday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak ? <BreakComponent /> : <AddClassComponent hourId={r.id} day={3} />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '90px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.thursday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i}/>
                                            ))}
                                            {r.Days.thursday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak ? <BreakComponent /> : <AddClassComponent hourId={r.id} day={4} />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '90px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.friday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i}/>
                                            ))}
                                            {r.Days.friday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak ? <BreakComponent /> : <AddClassComponent hourId={r.id} day={5} />}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Card>
            </div>

            <Modal show={showNewSchoolHourModal} onHide={handleCloseNewSchoolHour} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClock} style={{ minWidht: '20px' }} /><Modal.Title>Nueva hora cátedra</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={12} className='mt-2  align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={12} className='mt-2  align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedSchoolYearOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedSchoolYearOption}>
                                    {schoolYears.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectSchoolYear(i)}>{opt.year}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={12}>
                            <div className='mt-2 align-center-vertically'>
                                <FontAwesomeIcon icon={faListNumeric} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-150' placeholder="Número" id="textNewSchoolHourNumber" />
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faClock} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    className='input-text fs-14 wi-150'
                                    showTimeSelect
                                    showTimeSelectOnly
                                    onChange={handleChangeTimeStart}
                                    selected={selectedTimeStart}
                                    dateFormat={'HH:mm'}
                                    placeholderText='Comienzo'
                                    locale='es'
                                    timeIntervals={15} />
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <DatePicker
                                    className='input-text fs-14 wi-150'
                                    showTimeSelect
                                    showTimeSelectOnly
                                    onChange={handleChangeTimeEnd}
                                    selected={selectedTimeEnd}
                                    dateFormat={'HH:mm'}
                                    placeholderText='Fin'
                                    locale='es'
                                    timeIntervals={15} />
                            </div>
                        </Col>

                        <Col md={12} className='mt-3'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="Recreo" id="textNewSchoolHourBreak" />
                            </div>
                        </Col>
                        <Col md={12} className='mt-2 align-center-vertically'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="Almuerzo" id="textNewSchoolHourLunch" />
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewSchoolHour}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postSchoolHour}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewClassTimeModal} onHide={handleCloseNewClassTimeModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClock} style={{ minWidht: '20px' }} /><Modal.Title>Asignar materia</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={12} className='mb-3  align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedClassOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassOption}>
                                    {classes.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClass(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> 
                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewClassTimeModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postClassTime}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CoursesConfig