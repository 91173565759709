import React, { useEffect } from 'react'
import Navbar from '../../navbar/Navbar'
import { Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faClipboardUser, faClock, faCog, faFileArchive, faPen, faFile, faBook, faSignature, faMessage, faCommentDots, faList, faExclamationCircle, faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { getUserRole, validateToken } from '../../../controllers/user.controller'

function Settings() {
    const PAGE_TITLE = "Configuración";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = ""

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
    }, []);


    let menus = [{ text: "Planes de estudio", link: "/configuracion/planes-estudio", icon: faFileArchive },
    { text: "Cursos", link: "/configuracion/cursos", icon: faChalkboardTeacher },
    { text: "Ciclos Lectivos", link: "/configuracion/ciclos-lectivos", icon: faCalendar },
    { text: "Calificaciones", link: "/configuracion/calificaciones", icon: faPen },
    { text: "Evaluaciones", link: "/configuracion/evaluaciones", icon: faFile },
    { text: "Asistencia", link: "/configuracion/asistencia", icon: faClipboardUser },
    { text: "Períodos", link: "/configuracion/periodos", icon: faCalendar },
    { text: "Nodales predeterminados", link: "/configuracion/nodales-predeterminados", icon: faFile },
    { text: "Horarios", link: "/configuracion/horarios", icon: faClock },
    { text: "Feriados", link: "/configuracion/feriados", icon: faCalendar },
    { text: "Docentes", link: "/configuracion/firmas-docentes", icon: faChalkboardTeacher },
    { text: "Grupos", link: "/configuracion/gruposChat", icon: faCommentDots },
    {  text: "Tipos de datos de alumnos", link: "/configuracion/tiposDatos", icon: faList },
    {  text: "Sanciones", link: "/configuracion/sanciones", icon: faExclamationCircle },
    //{  text: "Informe psicopedagógico", link: "/configuracion/informe-psicopedagogico", icon: faBookOpen }
    ];

    const rolesCanEditNavbar = ["Personal de secdevs - Secundaria", "Personal de secdevs - Primaria", "Personal de secdevs - Jardín"]
    if(rolesCanEditNavbar.find(r=>getUserRole().includes(r))){
        menus.push(
            {  text: "NavBar", link: "/configuracion/navBar", icon: faList },
        )
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    {menus.map((m, i) =>
                        <Col md={4} key={i} className='mt-3 ps-0'>
                            <Link to={m.link} className='configLink'>
                                <Card className='cards' style={{ padding: '20px 15px' }}>
                                    <div className='align-center-vertically'>
                                        <FontAwesomeIcon className='fitContentWidth me-2' icon={m.icon} />
                                        <p className='mb-0 ps-0 fitContentWidth '>{m.text}</p>
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}

export default Settings