import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClipboardList, faClipboardUser, faEllipsisV, faFile, faFileCircleExclamation, faFolderOpen, faPen, faPenFancy, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row, Table, Spinner, ToggleButton } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { useLocation } from "react-router-dom";
import Loading from '../../general-components/Loading';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import NotFound from '../../general-components/NotFound';
import InfoField from '../../general-components/InfoField';

function Grades() {
    const PAGE_TITLE = "Calificaciones - Registro de valoración académica";
    const PAGE_ICON = faPen;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    const location = useLocation();
    const courseId = location.state ? location.state.course : 'Curso';

    const [showLargeButtons, setShowLargeButtons] = useState(window.innerWidth >= 1386);
    const handleResize = () => {
        console.log(window.innerWidth)
        setShowLargeButtons(window.innerWidth >= 1386);
    };

    let keys = 0;
    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getClassrooms();
        getPeriodsNoGrouped();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //Modal funciones
    const [showNewGradesModal, setShowNewGradesModal] = useState(false);

    const handleShowNewGradesModal = () => {
        initstudentsGradesNew();
        getTestTypes();
        getNodals(selectedClassId);
        getEvaluativeGrades();
        setIsPostReady(-1)
        resetGrades();
        setSelectedPeriodId(selectedPeriodGeneralId);
        setSelectedPeriodOption(selectedPeriodGeneralOption);
        setShowNewGradesModal(true);
        setSelectedDateDay(moment().toDate());
        autoSelectNodalAndPeriodByDate(moment().toDate());
    };

    const handleCloseNewGradesModal = () => {
        setShowNewGradesModal(false);
    };

    const [studentsGradesNew, setStudentsGradesNew] = useState([]);
    const initstudentsGradesNew = () => {
        let listNew = [];
        students.map((r) => {
            listNew.push({
                id: r.userId,
                grade: '',
            })
        })
        setStudentsGradesNew(listNew);
    }

    const handleInputGradeNew = (event, i) => {
        const prev = [...studentsGradesNew];
        prev[i].grade = event.target.value;
        setStudentsGradesNew(prev);
    }

    //Pick date
    const [selectedDateDay, setSelectedDateDay] = useState(null);
    const handleChangeDate = date => {
        setSelectedDateDay(date);
        autoSelectNodalAndPeriodByDate(date);
    };

    // Tabla
    const [amountTest, setAmountTest] = useState(null); //guarda cant de tests por peiriodo
    const [verifierLength, setVerifierLength] = useState(null) //guarda bool por id del periodo, si es true = necesita +cols, si es false no
    const [loadedTable, setLoadedTable] = useState(false);

    const completer = (fr, header) => {
        const COLS_TO_COMPLETE = gradesPeriods.length > 1 ? 5 : 7;

        if (verifierLength[fr] == true) {
            let arrayTd = []
            for (let i = amountTest[fr]; i < COLS_TO_COMPLETE; i++) {
                arrayTd.push(
                    <td key={++keys} className='pb-0 pt-1 ps-0 pe-0' style={{ width: 70 }} >
                        <Row className={`ps-2 pe-2 ${header == true ? 'mh-40' : 'tableRows'}`}>
                            <Col className='align-center-vertically-v2'>
                                <div className='m-0' style={{ textAlign: 'center' }}>
                                </div>
                            </Col>
                        </Row>
                    </td>
                )
            }
            return arrayTd;
        } else {
            return;
        }
    }

    const [hoverContent, setHoverContent] = useState({});

    const handleMouseEnter = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: true,
        }))
    };
    const handleMouseLeave = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    const [hoverContentV2, setHoverContentV2] = useState(null);

    const handleMouseEnterV2 = (id) => {
        setHoverContentV2(id)
    };
    const handleMouseLeaveV2 = (id) => {
        if (hoverContentV2 == id) {
            setHoverContentV2(null);
        }
    };

    const functEdit = (testChild, j, i) => {
        let indexInGradesStudents = j;
        for (let z = 0; z < i; z++) {
            indexInGradesStudents += gradesPeriods[z].Tests.length;
        }
        getTestTypes()
        setSelectedEvaluativeGrades([]);
        setSelectedNumericalGrades([]);
        setEditTestData(testChild)
        setEditTestId(testChild.id)
        setEditTestIndex(indexInGradesStudents);
        handleChangeDate(testChild.date)
        getEvaluativeGrades();
        setSelectedTestTypeOption(testChild.TestType.name);
        setSelectedTestTypeId(testChild.TestType.id);
        setSelectedTestTypeIsNumerical(testChild.TestType.isNumerical)
        setModalStudentGrades(testChild.Grades);

        showOldGrades(indexInGradesStudents);
        setShowEdit(true);
    }

    const colSpanAmount = (fr) => {
        if (gradesPeriods.length > 1) {
            const today = new Date()
            if (!verifierLength[fr.id] || amountTest[fr.id] >= 5) {
                return amountTest[fr.id] > 0 ? amountTest[fr.id] : 1;
            } else {
                return 5;
            }
        } else {
            if (!verifierLength[fr.id] || amountTest[fr.id] >= 7) {
                return amountTest[fr.id] > 0 ? amountTest[fr.id] : 1;
            } else {
                return 7;
            }
        }
    }

    //Modal editar evaluación
    const [showEdit, setShowEdit] = useState(false);
    const [editTestData, setEditTestData] = useState({})
    const [modalStudentGrades, setModalStudentGrades] = useState([]);
    const [editTestId, setEditTestId] = useState(null);
    const [editTestIndex, setEditTestIndex] = useState(null);
    const handleCloseModalEdit = () => {
        setShowEdit(false);
    }

    const passingGrade = 6;
    const isApprovedBadge = (i) => {
        const aBadge = (<Badge bg='' style={{ background: 'green' }} className='statusBadge fs-14'>A</Badge>);
        const dBagde = (<Badge bg='' style={{ background: 'red' }} className='statusBadge fs-14'>D</Badge>);

        if (selectedTestTypeIsNumerical == true) {
            if (selectedNumericalGrades[i] != null) {
                if (selectedNumericalGrades[i] >= passingGrade) {
                    return aBadge;
                } else if (selectedNumericalGrades[i] == -1) {
                    return '';
                } else {
                    return dBagde;
                }
            } else {
                return '';
            }
        } else if (selectedTestTypeIsNumerical == false) {
            if (selectedEvaluativeGrades[i] != null) {
                if (selectedEvaluativeGrades[i].value != '---') {
                    if (selectedEvaluativeGrades[i].approved == true) {
                        return aBadge;
                    } else {
                        return dBagde;
                    }
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }
    }

    const autoSelectNodalAndPeriodByDate = (date) => {
        const dateMoment = moment(date);

        const periodFound = periodsNoGrouped.find(o => {
            const fromMoment = moment(o.from);
            const toMoment = moment(o.to);
            return dateMoment.isBetween(fromMoment, toMoment, null, '[]');
        });

        /*const nodalFound = nodals.find(o => {
            const fromMoment = moment(o.Period.from);
            const toMoment = moment(o.Period.to);
            return dateMoment.isBetween(fromMoment, toMoment, null, '[]');
        });*/

        if (periodFound) {
            setSelectedPeriodOption(periodFound.name);
            setSelectedPeriodId(periodFound.id);
        }

        /*if (nodalFound) {
            setSelectedNodalOption(nodalFound.name);
            setSelectedNodalId(nodalFound.id);
        }*/
    }

    const [classrooms, setClassrooms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [periodGroups, setPeriodGroups] = useState([]);
    const [testTypes, setTestTypes] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [nodals, setNodals] = useState([]);
    const [students, setStudents] = useState([]);
    const [evaluativeGrades, setEvaluativeGrades] = useState([]);
    const [gradesPeriods, setGradesPeriods] = useState([]);
    const [gradesStudents, setGradesStudents] = useState(null);

    useEffect(() => {
        setupTable(gradesPeriods);
    }, [gradesPeriods])

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    const handleSelectClassroom = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getClassesByClassroomId(classrooms[eventKey].id)
        setSelectedClassId(null);
        setSelectedClassOption('Materia');
        setLoadedTable(false)
    };

    const [selectedClassOption, setSelectedClassOption] = useState('Materia');
    const [selectedClassId, setSelectedClassId] = useState(null);

    const handleSelectClass = (eventKey) => {
        setSelectedClassOption(classes[eventKey].name);
        setSelectedClassId(classes[eventKey].id);
        if (selectedPeriodGeneralId != null) {
            setLoadedTable(false)
            getAcademicAssessmentRecord(selectedPeriodGeneralId, classes[eventKey].id, false)
            getBreaches(selectedPeriodGeneralId, classes[eventKey].id)
        }
    };

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        setLoadedTable(false)
        getAcademicAssessmentRecord(periods[eventKey].id, selectedClassId, false)
        getBreaches(periods[eventKey].id, selectedClassId)
    };

    const [selectedTestTypeOption, setSelectedTestTypeOption] = useState('Tipo de evaluación');
    const [selectedTestTypeId, setSelectedTestTypeId] = useState(null);
    const [selectedTestTypeIsNumerical, setSelectedTestTypeIsNumerical] = useState(null);

    const handleSelectTestType = (eventKey) => {
        setSelectedTestTypeOption(testTypes[eventKey].name);
        setSelectedTestTypeId(testTypes[eventKey].id);
        if (testTypes[eventKey].isNumerical != selectedTestTypeIsNumerical) {
            console.log(`${testTypes[eventKey].isNumerical} - ${selectedTestTypeIsNumerical}`)
            resetGrades()
        }
        setSelectedTestTypeIsNumerical(testTypes[eventKey].isNumerical);

    };

    const resetGrades = () => {
        let egs = [];
        let ngs = [];
        for (let i = 0; i < students.length; i++) {
            egs[i] = {
                id: 0,
                name: '---',
                value: '---',
                approved: null,
                equivalent: -1
            };
            ngs[i] = -1;
        }
        setSelectedEvaluativeGrades(egs);
        setSelectedNumericalGrades(ngs)
    }

    const showOldGrades = (testIndex) => {
        let egs = [];
        let ngs = [];

        for (let i = 0; i < students.length; i++) {
            if (gradesStudents[students[i].id].Grades[testIndex].EvaluativeGrade != null) {
                egs[i] = {
                    id: gradesStudents[students[i].id].Grades[testIndex].EvaluativeGrade.id,
                    name: gradesStudents[students[i].id].Grades[testIndex].EvaluativeGrade.name,
                    value: gradesStudents[students[i].id].Grades[testIndex].EvaluativeGrade.value,
                    approved: gradesStudents[students[i].id].Grades[testIndex].EvaluativeGrade.approved,
                    equivalent: gradesStudents[students[i].id].Grades[testIndex].EvaluativeGrade.equivalent,
                };
            } else {
                egs[i] = {
                    id: 0,
                    name: '---',
                    value: '---',
                    approved: null,
                    equivalent: -1
                };
            }
            if (gradesStudents[students[i].id].Grades[testIndex].numerical != "ND") {
                ngs[i] = gradesStudents[students[i].id].Grades[testIndex].numerical;
            } else {
                ngs[i] = -1;
            }

        }
        setSelectedEvaluativeGrades(egs);
        setSelectedNumericalGrades(ngs);
    }

    const [selectedPeriodOption, setSelectedPeriodOption] = useState('Período');
    const [selectedPeriodId, setSelectedPeriodId] = useState(null);

    const handleSelectPeriod = (eventKey) => {
        setSelectedPeriodOption(periodsNoGrouped[eventKey].name);
        setSelectedPeriodId(periodsNoGrouped[eventKey].id);
    };

    const [selectedNodalOption, setSelectedNodalOption] = useState('Nodal');
    const [selectedNodalId, setSelectedNodalId] = useState(null);

    const handleSelectNodal = (eventKey) => {
        setSelectedNodalOption(nodals[eventKey].name);
        setSelectedNodalId(nodals[eventKey].id);
    };

    const [selectedEvaluativeGrades, setSelectedEvaluativeGrades] = useState([]);
    const [selectedNumericalGrades, setSelectedNumericalGrades] = useState([]);

    const handleSelectEvaluativeGrades = (i, grade) => {
        let auxEG = [...selectedEvaluativeGrades];
        auxEG[i] = grade;
        setSelectedEvaluativeGrades(auxEG);
    };

    const handleSelectNumericalGrades = (i, grade, inputId) => {
        const values = grade.target.value.split(" ");
        let value;
        let auxNG = [...selectedNumericalGrades];

        if (values.length > 5) {
            for (let j = 0; j < students.length; j++) {
                if (values[j]) {
                    value = values[j].replace(",", ".");
                    let numericalValue = parseFloat(value);
                    if (numericalValue > 10) {
                        value = numericalValue % 100 == 0 ? 10 : value.charAt(0);
                        numericalValue = parseFloat(value);
                    }
                    document.getElementById(`${inputId}-${j}`).value = value
                    auxNG[j] = value != "" ? (!isNaN(value) ? parseFloat(value) : -1) : -1;
                }
            }
        } else {
            value = grade.target.value.replace(",", ".");
            let numericalValue = parseFloat(value);
            if (numericalValue > 10) {
                value = numericalValue % 100 == 0 ? 10 : value.charAt(0);
                numericalValue = parseFloat(grade.target.value);
            }
            grade.target.value = value;
            auxNG[i] = value != "" ? (!isNaN(value) ? parseFloat(value) : -1) : -1;
        }
        setSelectedNumericalGrades(auxNG);
    };

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    getClassesByClassroomId(res[0].id)
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }

            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${2024}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    getClassesByClassroomId(res[0].id)
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }

            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getClassesByClassroomId = async function (cid) {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classes/byTeacherId&ClassroomId/${cid}`, getUserToken());
            const res = await req.json();
            if (req.status === 200) {
                console.log(res)
                setClasses(res);
                getPeriods();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classes/byClassroomId/${cid}`, getUserToken());
            const res = await req.json();
            if (req.status === 200) {
                console.log(res)
                setClasses(res);
                getPeriods();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getAcademicAssessmentRecord = async function (pgid, cid, avatar) {
        const req = await GET(`/grades/academicAssessmentRecord/byPeriodGroupId&ClassId/${pgid}/${cid}/${avatar}`, getUserToken());
        //const req = await GET(`/grades/academicAssessmentRecord/byPeriodId&ClassId/${pgid}/${cid}/${avatar}`, getUserToken());
        const res = await req.json();
        setAmountTest(null);
        setVerifierLength(null);
        if (req.status === 200) {
            console.log(res)
            if (res.Students) { setStudents(res.Students) }
            if (res.Periods) { setGradesPeriods(res.Periods) }
            if (res.StudentsGrades) {
                setGradesStudents(res.StudentsGrades)
            }
            if (avatar == false) {
                getAcademicAssessmentRecord(pgid, cid, true);
            }
            setLoadedTable(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const setupTable = (pe) => {
        if (pe.length > 1) {
            let endPeriodDate;
            let amTest = {};
            let leTest = {};
            for (let p of pe) {

                amTest[p.id] = p.Tests.length;

                if (p.Tests.length < 5) {
                    leTest[p.id] = true;
                } else {
                    leTest[p.id] = false;
                }
            }
            setAmountTest(amTest);
            setVerifierLength(leTest);
        } else {
            let amTest = {};
            let leTest = {};
            for (let p of pe) {
                amTest[p.id] = p.Tests.length;

                if (p.Tests.length < 7) {
                    leTest[p.id] = true;
                } else {
                    leTest[p.id] = false;
                }
            }
            setAmountTest(amTest);
            setVerifierLength(leTest);
        }
    }

    const getTestTypes = async function () {
        const req = await GET(`/grades/testTypes`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setTestTypes(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPeriods = async function () {
        //const req = await GET(`/grades/periods/noGrouped`, getUserToken());
        const req = await GET(`/grades/periods/groups/bySchoolYear/${2024}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
            const currentDate = moment();
            let currentPeriodIndex = res.findIndex(period => {
                const fromDate = moment(period.from);
                const toDate = moment(period.to);
                return currentDate.isBetween(fromDate, toDate, undefined, '[]'); // '[]' incluye los límites
            });
            currentPeriodIndex = currentPeriodIndex<0 ? 0 : currentPeriodIndex

            setSelectedPeriodGeneralOption(res[currentPeriodIndex].name);
            setSelectedPeriodGeneralId(res[currentPeriodIndex].id);
            
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [periodsNoGrouped, setPeriodsNoGrouped] = useState([]);
    const getPeriodsNoGrouped = async function () {
        const req = await GET(`/grades/periods/noGrouped`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriodsNoGrouped(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getNodals = async function (cid) {
        const req = await GET(`/grades/nodals/byClassId/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setNodals(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEvaluativeGrades = async function () {
        const req = await GET(`/grades/evaluativeGrades/showGrades`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let a = [{
                approved: true,
                equivalent: -1,
                id: null,
                name: "---",
                value: "---"
            }];
            let aa = a.concat(res)
            setEvaluativeGrades(aa);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isPostReady, setIsPostReady] = useState(-1);
    const postGrades = async function () {
        setIsPostReady(false);
        let g = [];

        if (selectedTestTypeIsNumerical == true) {
            for (let i = 0; i < students.length; i++) {
                if (selectedNumericalGrades[i] != -1) {
                    g.push({
                        studentId: students[i].id,
                        numerical: selectedNumericalGrades[i]
                    })
                }
            }
        } else if (selectedTestTypeIsNumerical == false) {
            for (let i = 0; i < students.length; i++) {
                if (selectedEvaluativeGrades[i].value != '---') {
                    g.push({
                        studentId: students[i].id,
                        evaluativeId: selectedEvaluativeGrades[i].id
                    })
                }
            }
        }

        const body = {
            name: document.getElementById('textNewGradesTitle').value,
            typeId: selectedTestTypeId,
            isNumerical: selectedTestTypeIsNumerical,
            nodalId: selectedNodalId,
            date: selectedDateDay,
            classId: selectedClassId,
            periodId: selectedNodalId == null ? selectedPeriodId : null,
            grades: g
        }

        console.log(g)

        const req = await POST(`/grades/test`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            setIsPostReady(true);
            handleCloseNewGradesModal();
            setLoadedTable(false);
            getAcademicAssessmentRecord(selectedPeriodGeneralId, selectedClassId, true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const GradeValue = ({ g }) => {
        if (g.numerical) {
            //Numerical grade
            if (g.numerical == "ND") {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'white' }}>--</Badge>)
            } else if (g.numerical >= passingGrade) {
                //Approved
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'green' }}>{g.numerical}</Badge>)
            } else {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'red' }}>{g.numerical}</Badge>)
            }
        } else if (g.EvaluativeGrade) {
            //Evaluative grade
            if (g.EvaluativeGrade.approved) {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'green' }}>{g.EvaluativeGrade.value}</Badge>)
            } else {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 50, backgroundColor: 'red' }}>{g.EvaluativeGrade.value}</Badge>)
            }
        } else {
            return '';
        }
    }

    const [selectedDateDayBreaches, setSelectedDateDayBreaches] = useState(null);
    const handleChangeDateBreaches = date => {
        setSelectedDateDayBreaches(date);
    };

    const [showNewBreachesModal, setShowNewBreachesModal] = useState(false);
    const handleShowNewBreachesModal = () => {
        setDefaultBreachesValues();
        setSelectedDateDayBreaches(moment().toDate())
        setShowNewBreachesModal(true);
    };
    const handleCloseNewBreachesModal = () => {
        setShowNewBreachesModal(false);
    };

    const [checkedButtons, setCheckedButtons] = useState({});
    const [valueCheckedButtons, setvalueCheckedButtons] = useState({});

    //Falta set value si ya hay valores cargados.
    const setDefaultBreachesValues = () => {
        const initialStates = {};
        const initialValues = {};

        for (let s of students) {
            initialStates[s.id] = {

                [`check-done-${s.id}`]: true
            };

            initialValues[s.id] = 0;
        }

        setCheckedButtons(initialStates);
        setvalueCheckedButtons(initialValues);
    };

    const handleToggleChange = (buttonId, ind, e) => {
        setCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: {
                [buttonId]: true
            }
        }));
        setvalueCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: e.target.checked,
        }));
    };

    const postBreaches = async function () {
        let body = [];
        for (let s of students) {
            if (valueCheckedButtons[s.id] == 1) {
                body.push({
                    userId: s.id,
                    date: selectedDateDayBreaches,
                    classId: selectedClassId,
                    description: document.getElementById(`${s.id}-description`).value
                })
            }
        }

        console.log("Post")
        console.log(body)
        const req = await POST(`/grades/missingAssignments`, getUserToken(), body);
        const res = await req.json();
        console.log("res")
        if (req.status === 201) {
            console.log("201")
            console.log(res)
            getAcademicAssessmentRecord(selectedPeriodGeneralId, selectedClassId, true);
            handleCloseNewBreachesModal();
            getBreaches(selectedPeriodGeneralId, selectedClassId)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [studentsBreaches, setStudentsBreaches] = useState(null);
    const getBreaches = async function (gpid, cid) {
        //grades/missingAssignments/byPeriodId&ClassId
        //const req = await GET(`/grades/missingAssignments/byPeriodId&ClassId/${pid}/${cid}`, getUserToken());
        const req = await GET(`/grades/missingAssignments/byPeriodId&ClassId/${gpid}/${cid}`, getUserToken());
        const res = await req.json();
        if (req.status === 200) {
            console.log(res);
            setStudentsBreaches(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    useEffect(() => {
        if (showStudentBreachesModal) {
            let arr = getbreachesByStudentId(students[selectedStudentIndex].id);
            setBreachesByStudent(arr);
            if (arr.length <= 0) {
                handleCloseStudentBreachesModal();
            }
        }
    }, [studentsBreaches])

    function getbreachesByStudentId(userId) {
        return studentsBreaches.filter(obj => obj.userId === userId);
    }

    const [showStudentBreachesModal, setShowStudentBreachesModal] = useState(false);
    const [selectedStudentIndex, setSelectedStudentIndex] = useState(null);
    const [breachesByStudent, setBreachesByStudent] = useState(null)
    const handleShowStudentBreachesModal = (i) => {
        setBreachesByStudent(getbreachesByStudentId(students[i].id))
        setSelectedStudentIndex(i);
        setShowStudentBreachesModal(true);
    };
    const handleCloseStudentBreachesModal = () => {
        setShowStudentBreachesModal(false);
    };

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({ state: false, modalTitle: "Eliminar", modalContent: "", funct: "" });
    const [deleteElement, setDeleteElement] = useState(null);
    const [deleteElementId, setDeleteElementId] = useState(null);
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, title, content, funct) => {
        setShowConfirmDeleteModal({
            state: true,
            modalTitle: title,
            modalContent: content,
            funct: () => funct(id),
        });
    };

    const deleteBreach = async function (id) {
        const req = await DEL(`/grades/missingAssignments/deleteById/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getBreaches(selectedPeriodGeneralId, selectedClassId, true);
            handleCloseConfirmDeleteModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putUpdatedGrades = async function () {
        setIsPostReady(false);
        let g = [];

        if (selectedTestTypeIsNumerical == true) {
            for (let i = 0; i < students.length; i++) {
                g.push({
                    studentId: students[i].id,
                    numerical: selectedNumericalGrades[i] != -1 ? selectedNumericalGrades[i] : null
                })
            }
        } else if (selectedTestTypeIsNumerical == false) {
            for (let i = 0; i < students.length; i++) {
                if (selectedEvaluativeGrades[i].value != '---') {
                    g.push({
                        studentId: students[i].id,
                        evaluativeId: selectedEvaluativeGrades[i].id
                    })
                }
            }
        }

        const body = {
            name: document.getElementById('textNewGradesTitle').value,
            typeId: selectedTestTypeId,
            nodalId: selectedNodalId,
            date: selectedDateDay,
            classId: selectedClassId,
            periodId: selectedNodalId == null ? selectedPeriodId : null,
            grades: g
        }

        const req = await PUT(`/grades/test/${editTestId}`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            setIsPostReady(true);
            handleCloseModalEdit();
            setLoadedTable(false)
            getAcademicAssessmentRecord(selectedPeriodGeneralId, selectedClassId, true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteGrade = async function (id) {
        const body = {
            id: id
        }
        console.log(body)
        const req = await DEL(`/grades/test`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseConfirmDeleteModal();
            handleCloseModalEdit();
            setLoadedTable(false)
            getAcademicAssessmentRecord(selectedPeriodGeneralId, selectedClassId, true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col style={{ display: 'flex', flexDirection: 'row !important', alignItems: 'center' }}>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>

                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className='' style={{ rowGap: '8px' }}>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClassroom(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faPen} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250">
                                    {selectedClassOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassOption}>
                                    {classes.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClass(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col className=''>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250" disabled={selectedClassId == null}>
                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    {showLargeButtons ? <Col className='endRow ps-0 d-none d-lg-flex'>
                        <ActionButton variant='secondary' disabled={selectedClassId == null} iconFa={faPlus} func={handleShowNewBreachesModal} title={'Incumplimientos'} classExtra='fs-14 me-2' />
                        <ActionButton iconFa={faPlus} disabled={selectedClassId == null} title={'Nueva evaluación'} func={handleShowNewGradesModal} classExtra='fs-14' />
                    </Col>
                        : <Col className='d-none d-lg-block'>
                            <div style={{ display: 'flex', justifyContent: 'end' }} className=' mb-1'>
                                <Dropdown className='ms-2'  >
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic" >
                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon className='me-2' icon={faEllipsisV} />Opciones</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={handleShowNewGradesModal} disabled={selectedClassId == null}><FontAwesomeIcon icon={faPlus} style={{ marginRight: 10, marginLeft: 2 }} />Nueva evaluación</Dropdown.Item>
                                        <Dropdown.Item onClick={handleShowNewBreachesModal} disabled={selectedClassId == null} ><FontAwesomeIcon icon={faPlus} style={{ marginRight: 10, marginLeft: 2 }} />Incumplimientos</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>}
                    <Col className='d-flex d-lg-none' md={12}>
                        <div style={{ width: '100%' }}>
                            <Button style={{ width: '100%' }} variant="secondary" disabled={selectedClassId == null} className='fs-14' onClick={handleShowNewBreachesModal}><FontAwesomeIcon className={'mr-10'} icon={faPlus} />Incumplimientos</Button>
                            <Button style={{ width: '100%' }} variant="primary" disabled={selectedClassId == null} className='fs-14 mt-2' onClick={handleShowNewGradesModal}><FontAwesomeIcon className={'mr-10'} icon={faPlus} />Nueva evaluación</Button>
                        </div>
                    </Col>
                </Row>

                <Card className='cards mt-3 fs-14 h-100-2 mobile-cards'>
                    {!loadedTable ?
                        <div className='justify-content-center'>
                            {selectedPeriodGeneralId != null && selectedClassId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un curso, una materia y un período' />
                            }
                        </div> :

                        <div className='slideTable' style={{ borderRadius: '10px' }}>

                            <table className='br-15 table m-0' style={{ borderColor: 'white', borderSpacing: '0', borderCollapse: 'separate' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className='headerContainer'>
                                        <td className='' rowSpan="4">#</td>
                                        <td className='align-itmes-center sticky-column' rowSpan="4" >Estudiantes</td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        <td colSpan={"100%"}>{selectedPeriodGeneralOption}</td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        <td className='align-itmes-center' rowSpan="3" colSpan={1}>Incum.</td>
                                        {amountTest != null && verifierLength != null && gradesPeriods.map((gp, i) =>
                                            <td key={++keys} colSpan={colSpanAmount(gp)}>{gp.name}</td>
                                        )}
                                    </tr>

                                    <tr className='headerContainer'>
                                        {gradesPeriods && gradesPeriods.map((fr, i) => (<>
                                            {fr.Tests.map((testChild, j) => (
                                                <OverlayTrigger key={++keys} placement="top" delay={{ show: 20, hide: 20 }} overlay={<Tooltip id="button-tooltip" className="custom-tooltip" >
                                                    Modificar evaluación
                                                </Tooltip>}>
                                                    <td className='p-0' onMouseEnter={() => handleMouseEnterV2(testChild.id)} onMouseLeave={() => handleMouseLeaveV2(testChild.id)}>
                                                        {hoverContentV2 == testChild.id ?
                                                            <div onClick={() => functEdit(testChild, j, i)} className='editGrades' style={{ height: 44, cursor: 'pointer' }}>
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </div>
                                                            :
                                                            <div className='p-1'>
                                                                <p className='m-0'>{testChild.TestType.value}</p>
                                                                <p className='m-0' style={{ fontSize: 11 }}>{testChild.date && moment(testChild.date).format('DD/MM')}</p>
                                                            </div>}
                                                    </td>
                                                </OverlayTrigger>
                                            ))
                                            }{amountTest != null && verifierLength != null && completer(fr.id, true)}</>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>

                                    {students && students.map((st, i) => (
                                        <tr key={++keys} className='' style={{ border: 'white' }}>

                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div style={{ textAlign: 'center' }}>
                                                            {1 + i}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>

                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0' >
                                                <div className='d-none d-lg-block'>
                                                    <Row className='tableRows' style={{ minWidth: '290px' }}>
                                                        <Col className='align-center-vertically-v2'>
                                                            <div >
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                                    <div className='fitContentWidth p-1'>
                                                                        <div className='studentImg circle-container' style={{ borderColor: 'white', background: 'white' }}>
                                                                            {st.avatarFile ? <img className='' src={st.avatarFile} alt={st.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                                        </div>
                                                                    </div>
                                                                    <span className='ms-1' style={{ textAlign: 'left' }}>{st.surname && st.surname.toUpperCase()}, {st.name}</span>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className='d-block d-lg-none fs-12'>
                                                    <Row className='tableRows' style={{ minWidth: '110px' }}>
                                                        <Col className='align-center-vertically-v2'>

                                                            <div style={{ textAlign: 'left', width: 110 }}>
                                                                {st.surname ? st.surname.toUpperCase() : ''}, {st.name[0]}.
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </td>

                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        {studentsBreaches != null && getbreachesByStudentId(st.id).length > 0 &&
                                                            <div className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                                <Badge bg='warning' className='statusBadge fs-14 mt-1 mb-1' onClick={() => handleShowStudentBreachesModal(i)} style={{ height: 36, width: 50, backgroundColor: 'white', cursor: 'pointer', color: 'black' }}>
                                                                    {getbreachesByStudentId(st.id).length}
                                                                </Badge>
                                                            </div>}
                                                    </Col>
                                                </Row>
                                            </td>
                                            {gradesPeriods && gradesPeriods.map((fr, ind) => <>{
                                                <>
                                                    {gradesStudents != null ?
                                                        <>
                                                            {gradesStudents[st.id] && gradesStudents[st.id].Grades && gradesStudents[st.id].Grades.map((gs) => (
                                                                <>
                                                                    {gs.periodId == fr.id ?
                                                                        <td className='pb-0 pt-1 ps-0 pe-0' style={{ width: 70 }}>
                                                                            <Row className='tableRows'>
                                                                                <Col className='align-center-vertically-v2'>
                                                                                    <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                                                        <GradeValue g={gs} />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                        </td>
                                                                        :
                                                                        ''
                                                                    }
                                                                </>
                                                            ))}
                                                        </>
                                                        :
                                                        ''
                                                    }
                                                </>
                                            }
                                                {amountTest != null && verifierLength != null && completer(fr.id, false)}
                                            </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </Card>
            </div>

            {showNewGradesModal && (
                <Modal size={'lg'} show={showNewGradesModal} onHide={handleCloseNewGradesModal} centered backdrop="static" >
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>{showEdit ? 'Modificar evaluación' : 'Nueva evaluación'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} className='mb-2 ps-0 ' style={{ minWidth: 'min-content' }}>
                                <div className='align-center-vertically'>
                                    <FontAwesomeIcon height={30} icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                                    <Form.Control className='textInputGray fs-14 wi-300' id="textNewGradesTitle" placeholder="Título" />
                                </div>
                            </Col>

                            <Col md={6} className='mb-2 ps-0 ' style={{ minWidth: 'min-content' }}>
                                <div className='align-center-vertically'>
                                    <FontAwesomeIcon icon={faPenFancy} className='dropDownIcon' style={{ height: 16 }} />
                                    <Dropdown className='dropdownButton'>
                                        <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                            {selectedTestTypeOption}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="fs-14" title={selectedTestTypeOption}>
                                            {testTypes.map((opt, i) => (
                                                <Dropdown.Item key={i} onClick={() => handleSelectTestType(i)}>{opt.name}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>

                            <Col md={6} className='mb-2 ps-0' style={{ minWidth: 'min-content' }}>
                                <div className='align-center-vertically w-100'>
                                    <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />

                                    <DatePicker
                                        selected={selectedDateDay}
                                        onChange={handleChangeDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Fecha"
                                        className='dropdown-toggle'
                                        locale='es' maxDate={moment().toDate()} />
                                </div>
                            </Col>

                            <Col md={6} className='mb-2 ps-0 ' style={{ minWidth: 'min-content' }}>
                                <div className='align-center-vertically'>
                                    <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                    <Dropdown className='dropdownButton'>
                                        <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                            {selectedPeriodOption}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="fs-14" title={selectedPeriodOption}>
                                            {periodsNoGrouped.map((opt, i) => (
                                                <Dropdown.Item key={i} onClick={() => handleSelectPeriod(i)}>{opt.name}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <FontAwesomeIcon icon={faPen} className='dropDownIcon' style={{ height: 16 }} />Calificaciones <span className='fitContentWidth ms-3 text-muted'>Una vez creada la evaluación, se podrán modificar las calificaciones</span>
                            </Col>
                        </Row>
                        <div className='d-none d-lg-block'>
                            <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>

                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        #
                                    </div>
                                </Col>
                                <Col md={7} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Nota
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='d-block d-lg-none'>
                            <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={12} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Estudiantes
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row className='mt-2'>
                            {students.map((s, i) => (<>

                                <div className='d-none d-lg-block ps-0 pe-0'>
                                    <Row key={i} className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={1} xs={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {1 + i}
                                            </div>
                                        </Col>
                                        <Col md={7} xs={11} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                        {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>

                                            </div>
                                        </Col>

                                        <Col md={2} xs={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'right' }}>
                                                {showNewGradesModal && isApprovedBadge(i)}
                                            </div>
                                        </Col>

                                        <Col md={2} xs={8} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {selectedTestTypeIsNumerical != null &&
                                                    <span>
                                                        {selectedTestTypeIsNumerical == true ?
                                                            <Form.Control className='textInputGray fs-14 w-100' id={`textNumericalGrade-${i}`} placeholder="" onChange={(event) => handleSelectNumericalGrades(i, event, "textNumericalGrade")} />
                                                            :
                                                            <>{/* <Dropdown className='dropdownButton'>
                                                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 w-100">
                                                                    {selectedEvaluativeGrades[i] && selectedEvaluativeGrades[i].value}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="fs-14" title={selectedEvaluativeGrades[i] && selectedEvaluativeGrades[i].value}>
                                                                    {evaluativeGrades && evaluativeGrades.map((opt, j) => (
                                                                        <Dropdown.Item key={j} onClick={() => handleSelectEvaluativeGrades(i, opt)}>{opt.name}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown> */}
                                                                <Form.Select
                                                                    className='dropdownButton'
                                                                    value={selectedEvaluativeGrades[i]?.value || ''}
                                                                    onChange={(e) => handleSelectEvaluativeGrades(i, evaluativeGrades.find(opt => opt.value === e.target.value))}>
                                                                    {evaluativeGrades && evaluativeGrades.map((opt, j) => (
                                                                        <option key={j} value={opt.value}>{opt.name}</option>
                                                                    ))}
                                                                </Form.Select></>
                                                        }
                                                    </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='d-block d-lg-none ps-0 pe-0'>
                                    <Row key={i} className='mb-1 pt-1 pb-2 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={1} xs={1} className='align-center-vertically-v2 '>
                                            <div style={{ textAlign: 'center' }} >
                                                {1 + i}
                                            </div>
                                        </Col>
                                        <Col md={7} xs={11} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                        {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>

                                            </div>
                                        </Col>
                                        <Col md={2} xs={6} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }} className='mt-1'>
                                                {selectedTestTypeIsNumerical != null &&
                                                    <span>
                                                        {selectedTestTypeIsNumerical == true ?
                                                            <Form.Control className='textInputGray fs-14 w-100' id={`textNumericalGrade-${i}`} placeholder="" onChange={(event) => handleSelectNumericalGrades(i, event, "textNumericalGrade")} />
                                                            :
                                                            <Form.Select
                                                                className='dropdownButton'
                                                                value={selectedEvaluativeGrades[i]?.value || ''}
                                                                onChange={(e) => handleSelectEvaluativeGrades(i, evaluativeGrades.find(opt => opt.value === e.target.value))}>
                                                                {evaluativeGrades && evaluativeGrades.map((opt, j) => (
                                                                    <option key={j} value={opt.value}>{opt.name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        }
                                                    </span>
                                                }
                                            </div>
                                        </Col>
                                        <Col md={2} xs={3} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'left' }} className='mt-1'>
                                                {showNewGradesModal && isApprovedBadge(i)}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </>))}
                        </Row>

                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewGradesModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="primary" className='fs-14' onClick={showEdit ? putUpdatedGrades : postGrades} disabled={selectedTestTypeId == null || selectedDateDay == null || selectedNodalOption == null || selectedPeriodId == null || isPostReady != -1}>
                            {isPostReady != -1 ? <div className="justify-content-center" style={{ width: '85px' }}>
                                <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                            </div> : <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</>}
                        </Button>
                    </Modal.Footer>
                </Modal>)
            }


            {
                showEdit && <Modal size={'lg'} show={showEdit} onHide={handleCloseModalEdit} centered className='assitanceModal modalContainer' backdrop="static" style={{ zIndex: 1045 }}>
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Modificar evaluación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Object.keys(editTestData).length === 0 ?
                            <div className='justify-content-center' style={{ height: '100%', alignItems: 'center' }}>
                                <Spinner animation="border" role="status" variant="primary">
                                </Spinner>
                            </div> :
                            <>
                                <Row className='mb-1'>
                                    <Col md={6} className='mb-2' style={{ minWidth: 'min-content' }}>
                                        <div className='align-center-vertically'>
                                            <FontAwesomeIcon height={30} icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                                            <Form.Control className='textInputGray fs-14 wi-300' id="textNewGradesTitle" placeholder="Título" defaultValue={editTestData.name} />
                                        </div>
                                    </Col>

                                    <Col md={6} className='mb-2' style={{ minWidth: 'min-content' }}>
                                        <div className='align-center-vertically'>
                                            <FontAwesomeIcon icon={faPenFancy} className='dropDownIcon' style={{ height: 16 }} />
                                            <Dropdown className='dropdownButton'>
                                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                                    {selectedTestTypeOption}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="fs-14" title={selectedTestTypeOption}>
                                                    {testTypes.map((opt, i) => (
                                                        <Dropdown.Item key={i} onClick={() => handleSelectTestType(i)}>{opt.name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>

                                    <Col md={6} className='mb-2' style={{ minWidth: 'min-content' }}>
                                        <div className='align-center-vertically w-100'>
                                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />

                                            <DatePicker
                                                selected={selectedDateDay}
                                                onChange={handleChangeDate}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Fecha"
                                                className='dropdown-toggle'
                                                locale='es' maxDate={moment().toDate()} />
                                        </div>
                                    </Col>

                                    <Col md={6} className='mb-2' style={{ minWidth: 'min-content' }}>
                                        <div className='align-center-vertically'>
                                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                            <Dropdown className='dropdownButton'>
                                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                                    {selectedPeriodOption}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="fs-14" title={selectedPeriodOption}>
                                                    {periodsNoGrouped.map((opt, i) => (
                                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriod(i)}>{opt.name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>

                                <div>
                                    <Row className='pb-2 pt-2 pe-2 ps-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                        <Col md={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                #
                                            </div>
                                        </Col>
                                        <Col md={9} className='align-center-vertically-v2'>
                                            <div>
                                                Nombre
                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'end' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                Nota
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                                <Row className='mt-3'>
                                    {students.map((s, i) => (
                                        <Row className='mb-1 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {1 + i}
                                                </div>
                                            </Col>
                                            <Col md={7} className='align-center-vertically-v2'>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className='fitContentWidth'>
                                                        <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                            {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                        </div>
                                                    </div>
                                                    <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>

                                                </div>
                                            </Col>
                                            <Col md={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'right' }}>
                                                    {showEdit ? isApprovedBadge(i) : ''}
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                <div className='align-center-vertically-v2'>
                                                    {selectedTestTypeIsNumerical == true ?
                                                        <Form.Control className='textInputGray fs-14 w-100' id={`textEditNumericalGrade-${i}`} defaultValue={showEdit && gradesStudents[s.id] && gradesStudents[s.id].Grades[editTestIndex] && gradesStudents[s.id].Grades[editTestIndex].numerical && gradesStudents[s.id].Grades[editTestIndex].numerical == "ND" ? '' : gradesStudents[s.id].Grades[editTestIndex].numerical} onChange={(event) => handleSelectNumericalGrades(i, event, "textEditNumericalGrade")} />
                                                        :
                                                        <Form.Select
                                                            className='dropdownButton'
                                                            value={selectedEvaluativeGrades[i]?.value || ''}
                                                            onChange={(e) => handleSelectEvaluativeGrades(i, evaluativeGrades.find(opt => opt.value === e.target.value))}>
                                                            {evaluativeGrades && evaluativeGrades.map((opt, j) => (
                                                                <option key={j} value={opt.value}>{opt.name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Row>
                            </>}
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalEdit}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="outline-danger" className='fs-14' onClick={() => handleShowConfirmDeleteModal(editTestId, "Eliminar", `¿Deseas eliminar la evaluación del ${moment(editTestData.date).format('DD/MM/YYYY')}?`, deleteGrade)}><FontAwesomeIcon className='mr-10' icon={faTrash} />Eliminar</Button>
                        <Button variant="primary" className='fs-14' onClick={putUpdatedGrades}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </Modal.Footer>
                </Modal>
            }

            {
                showNewBreachesModal && <Modal size={'lg'} show={showNewBreachesModal} onHide={handleCloseNewBreachesModal} centered className='assitanceModal modalContainer' backdrop="static" >
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardList} style={{ minWidht: '20px' }} /><Modal.Title>Incumplimientos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className='ps-0'>
                                <div className='align-center-vertically w-100'>
                                    <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />

                                    <DatePicker
                                        selected={selectedDateDayBreaches}
                                        onChange={handleChangeDateBreaches}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Fecha"
                                        className='dropdown-toggle'
                                        locale='es' maxDate={moment().toDate()} />
                                </div>
                            </Col>
                        </Row>
                        <div className='d-none d-lg-block'>
                            <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>

                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        #
                                    </div>
                                </Col>
                                <Col md={7} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Incumplimiento
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='d-block d-lg-none'>
                            <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={12} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Estudiante
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row className='mt-2'>
                            {students.map((s, i) => (
                                <><div className='d-none d-lg-block'>
                                    <Row key={i} className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        {/* <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {1 + i}
                                        </div>
                                    </Col> */}
                                        <Col className='align-center-vertically pe-0'>
                                            <div style={{ textAlign: 'center', width: '25px' }}>
                                                {1 + i}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                        {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>

                                            </div>
                                        </Col>
                                        <Col className='align-center-vertically endRow' style={{ width: '375px' }}>
                                            {valueCheckedButtons[s.id] ?
                                                <div>
                                                    <Form.Control className=' fs-14 wi-300' id={`${s.id}-description`} placeholder="Motivo..." />
                                                </div>
                                                : ''
                                            }
                                            <div className='endRow align-center-vertically fitContentWidth ms-3' >
                                                <Form.Check id={`check-breach-${s.id}`} type="switch" onChange={(val) => handleToggleChange(`check-breach-${s.id}`, s.id, val)} defaultChecked={false} className='bigSwitch' />
                                            </div>
                                        </Col>
                                    </Row> </div>
                                    <div className='d-block d-lg-none ps-0 pe-0'>
                                        <Row key={i} className='mb-1 pt-1 pb-2 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={1} xs={1} className='align-center-vertically-v2 '>
                                                <div style={{ textAlign: 'center' }} >
                                                    {1 + i}
                                                </div>
                                            </Col>
                                            {/* <Col md={7} xs={11} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                        {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>

                                            </div>
                                        </Col>
                                         <Col xs={12}>
                                            <div className='justify-content-center mt-0'>
                                                <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-done-${s.id}`] ? 'greenBack breachesButton assistanceButton' : 'assistGrey breachesButton assistanceButton'} id={`check-done-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-done-${s.id}`, s.id)}>Cumplió</ToggleButton>
                                                <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-notdone-${s.id}`] ? 'redBack breachesButton assistanceButton' : 'assistGrey breachesButton assistanceButton'} id={`check-notdone-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-notdone-${s.id}`, s.id)}>No cumplió</ToggleButton>
                                            </div>
                                        </Col> */}
                                            <Col xs={11} className='align-center-vertically' style={{ justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className='fitContentWidth'>

                                                    </div>
                                                    <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>

                                                </div>
                                                <div className='endRow align-center-vertically fitContentWidth ms-3' >
                                                    <Form.Check id={`check-breach-${s.id}`} type="switch" onChange={(val) => handleToggleChange(`check-breach-${s.id}`, s.id, val)} defaultChecked={false} className='bigSwitch' />
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                {valueCheckedButtons[s.id] ?
                                                    <div className='mt-2'>
                                                        <Form.Control className=' fs-14' id={`${s.id}-description`} placeholder="Descripción" />
                                                    </div>
                                                    : ''
                                                }

                                            </Col>

                                        </Row>
                                    </div>
                                </>
                            ))}
                        </Row>


                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewBreachesModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="primary" className='fs-14' onClick={postBreaches} disabled={selectedDateDayBreaches == null}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </Modal.Footer>
                </Modal>
            }

            <Modal size={'xs'} show={showStudentBreachesModal} onHide={handleCloseStudentBreachesModal} centered className='assitanceModal modalContainer' backdrop="static" style={{ zIndex: 1049 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardList} style={{ minWidht: '20px' }} /><Modal.Title>Incumplimientos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className='ps-0 pe-0'>
                            <InfoField content={<div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='fitContentWidth'>
                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                        {students != null && selectedStudentIndex != null && students[selectedStudentIndex].avatarFile ? <img className='' src={students[selectedStudentIndex].avatarFile} alt={students[selectedStudentIndex].name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                    </div>
                                </div>
                                <p className='classesTime m-0'><p className='m-0'>{students != null && selectedStudentIndex != null && students[selectedStudentIndex].surname && students[selectedStudentIndex].surname.toUpperCase()}, {students != null && selectedStudentIndex != null && students[selectedStudentIndex].name}</p></p>

                            </div>} title='Estudiante' />
                        </Col>
                    </Row>
                    <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                        <Col md={3} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                Fecha
                            </div>
                        </Col>
                        <Col md={9} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                Descripción
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        {breachesByStudent != null && breachesByStudent.map((r, i) => (
                            <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                <Col md={3} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'left' }}>
                                        {moment(r.date).format("DD/MM/YYYY")}
                                    </div>
                                </Col>
                                <Col className='align-center-vertically-v2 ps-0 pe-0'>
                                    <div style={{ textAlign: 'left' }}>
                                        {r.description}
                                    </div>
                                </Col>
                                <Col className='align-center-vertically-v2 fitContentWidth'>
                                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        <Dropdown className='ms-2'>
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r.id, "Eliminar", `¿Deseas eliminar el incumplimiento del ${moment(r.date).format('DD/MM/YYYY')}?`, deleteBreach)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </Col>
                            </Row>
                        ))}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseStudentBreachesModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                    {/* <Button variant="primary" className='fs-14' disabled={selectedDateDayBreaches == null}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button> */}
                </Modal.Footer>
            </Modal>

            {
                showConfirmDeleteModal && <div>
                    <div style={{ opacity: 0.5, zIndex: 1050 }}></div>
                    <Modal show={showConfirmDeleteModal.state} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" className="custom-backdrop" style={{ zIndex: 1051 }}>
                        <Modal.Header closeButton>
                            <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>{showConfirmDeleteModal.modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='pt-4 pb-4'>
                            <span className=''>{showConfirmDeleteModal.modalContent}</span>
                        </Modal.Body>
                        <Modal.Footer className='p-2'>
                            <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                            <Button variant="danger" className='fs-14 me-2' onClick={showConfirmDeleteModal.funct}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div >
    )
}

export default Grades