import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faFile, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown, InputGroup } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST } from '../../../controllers/endpoints.controller';
import { useNavigate } from 'react-router-dom';
import InfoField from '../../general-components/InfoField';

function CurriculumYears() {
    const PAGE_TITLE = "Años / grados";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración - Planes de estudio";
    const PREVIOUS_URL = "/configuracion/planes-estudio"

    const [curriculumId, setCurriculumId] = useState(null);
    const [curriculumName, setCurriculumName] = useState('');
    const [curriculumResolution, setCurriculumResolution] = useState('');
    const [curriculumYear, setCurriculumYear] = useState('');
    const [curriculumIsCurrent, setCurriculumIsCurrent] = useState('');

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        setCurriculumId(getParams())
        getCurriculumById(getParams());
    }, []);

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    //Modal
    const [showNewYears, setShowNewYears] = useState(false);

    const handleShowNewYears = () => {
        setShowNewYears(true)
    };

    const handleCloseModalNewYears = () => {
        setSelectedYearsOption('Cantidad');
        setShowNewYears(false);
    }

    const optionsYears = [1, 2, 3, 4, 5, 6, 7];
    const [years, setYears] = useState([]);
    const [selectedYearsOption, setSelectedYearsOption] = useState('Cantidad');

    const handleSelectYears = (eventKey) => {
        setSelectedYearsOption(optionsYears[eventKey]);
    };

    const getCurriculumById = async function (id) {
        const req = await GET(`/curriculum/byId/${id != null ? id : curriculumId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setCurriculumName(res.name);
            setCurriculumResolution(res.resolution);
            setCurriculumYear(res.year);
            setCurriculumIsCurrent(res.isCurrent);
            setYears(res.Grades);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postGrades = async function () {
        let g = [];
        for(let i = 0; i < parseInt(selectedYearsOption); i++){
            g.push(i + 1 + "");
        }

        const body = {
            curriculumId: curriculumId,
            grades: g
        }

        const req = await POST(`/curriculum/grades`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewYears();
            getCurriculumById(null);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Crear años/grados'} func={handleShowNewYears} classExtra='fs-14' />
                    </Col>
                </Row>

                <Card className='cards mt-3'>
                    <InfoField title='Nombre' content={curriculumName} extraClass='mb-2'></InfoField>
                    <InfoField title='Resolución' content={curriculumResolution} extraClass='mb-2'></InfoField>
                    <InfoField title='Año de vigencia' content={curriculumYear} extraClass='mb-2'></InfoField>
                    <InfoField title='Vigente' content={curriculumIsCurrent == true ? "Si" : "No"} extraClass=''></InfoField>
                </Card>

                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    Año / Grado
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    Materias
                                </div>
                            </Col>
                            <Col md={8} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {years.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={2} className='align-center-vertically-v2 pt-1 pb-1'>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>{r.name}º</p>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className='m-0'>{r.Subjects ? r.Subjects.length : '-'}</p>
                                        </div>
                                    </Col>
                                    <Col md={8} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link to={`/configuracion/planes-estudio/años/materias/?id=${r.id}`}>
                                                <Button variant="outline-secondary">
                                                    <span><FontAwesomeIcon icon={faEye} className='me-2' />Materias</span>
                                                </Button>
                                            </Link>

                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='' show={showNewYears} onHide={handleCloseModalNewYears} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Crear años/grados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-200 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedYearsOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedYearsOption}>
                                    {optionsYears.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectYears(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewYears}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postGrades}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CurriculumYears