import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function ActionButton({ iconFa, title = '', url, func, classExtra = '', variant = 'primary', disabled = false }) {
  return (
    <div className='fitContentWidth p-0' style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {url == null ?
        <Button disabled={disabled} variant={variant} className={classExtra} onClick={func}><FontAwesomeIcon className={`${title == "" ? '' : 'mr-10'}`} icon={iconFa} />{title}</Button>
        :
        <Link to={url} style={{ textDecoration: 'none', }} >
          <Button disabled={disabled} variant={variant} className={classExtra} onClick={func}><FontAwesomeIcon className={`${title == "" ? '' : 'mr-10'}`} icon={iconFa} />{title}</Button>
        </Link>
      }
    </div>
  )
}

export default ActionButton