import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Card, Col, Dropdown, Row, Button, Modal, Badge, Form, Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClapperboard, faClipboardCheck, faCog, faEllipsisV, faEye, faFile, faFileAlt, faFileCircleExclamation, faGraduationCap, faPen, faPlus, faSearch, faTicket, faTimes, faTrash, faUser, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { useNavigate } from 'react-router-dom';
import { DEL, GET, POST } from '../../../controllers/endpoints.controller'
import ActionButton from '../../general-components/ActionButton'
import InfoField from '../../general-components/InfoField'
import Loading from '../../general-components/Loading'
import moment from 'moment'
import { formatNameV2 } from '../../../tools/tools'
import NotFound from '../../general-components/NotFound'

function PendingSubjects() {
    const PAGE_TITLE = "Materias adeudadas";
    const PAGE_ICON = faChalkboardTeacher;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = ""

    const [courseId, setCourseId] = useState('Curso');

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getClassrooms();
        getEvaluativeGrades();
    }, []);

    const [showModalNewOwedSubject, setShowModalNewOwedSubject] = useState(false);
    const handleShowModalNewOwedSubject = () => {
        setSelectedContacts([]);
        setSelectedSubjectOption("Materia")

        setShowModalNewOwedSubject(true);
    };
    const handleCloseModalNewOwedSubject = () => {
        setShowModalNewOwedSubject(false);
    }

    const [students, setStudents] = useState([{
        name: "Valentina", surname: "Fernandez", avatarFile: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
        owedSubjects: [
            { name: "Matemática", year: 2022, degree: "1", division: 'A' },
            { name: "Lengua", year: 2022, degree: "1", division: 'A' }
        ]
    },
    {
        name: "Magalí", surname: "Fernandez", avatarFile: "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg",
        owedSubjects: [
            { name: "Matemática", year: 2022, degree: "1", division: 'A' },
            { name: "Lengua", year: 2022, degree: "1", division: 'A' }
        ]
    }])

    //MODAL

    //Buscador
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const handleContactClick = (contact) => {
        if (!selectedContacts.find(selected => selected.id === contact.id)) {
            //setSelectedContacts([contact]);
            setSelectedContacts((prevState) => ([...prevState, contact]));
            if (window.getComputedStyle(document.getElementById('textSearchMobile')).display == "block") {
                document.getElementById('textSearchMobile').value = '';
            } else {
                document.getElementById('textSearchDesktop').value = '';
            }
            setSearchResults([]);
        }
    };
    const removeContact = (contactToRemove) => {
        const updatedContacts = selectedContacts.filter(contact => contact.id !== contactToRemove.id);
        setSelectedContacts(updatedContacts);
    };

    const handleOutsideClick = (event) => {
        if (window.getComputedStyle(document.getElementById('textSearchMobile')).display == "block") {
            if (document.getElementById('textSearchMobile').value != '') { }
            const clickedOutside = !event.target.closest('#textSearchMobile');
            if (clickedOutside) {
                document.getElementById('textSearchMobile').value = '';
                setSearchResults([])
            }
        } else {
            if (document.getElementById('textSearchDesktop').value != '') { }
            const clickedOutside = !event.target.closest('#textSearchDesktop');
            if (clickedOutside) {
                document.getElementById('textSearchDesktop').value = '';
                setSearchResults([])
            }
        }
    }

    const searchUsers = async function () {
        let chain = "";
        if (window.getComputedStyle(document.getElementById('textSearchMobile')).display == "block") {
            chain = document.getElementById('textSearchMobile').value;
        } else {
            chain = document.getElementById('textSearchDesktop').value;
        }

        if (chain.length >= 3) {
            const req = await GET(`/sso/users/search/ByRole/Alumno/${chain}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setSearchResults(res)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            setSearchResults([]);
        }
    }

    //Seleccionar grado
    const [classrooms, setClassrooms] = useState([]);
    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(-1);
    const handleSelectClassroomOption = (eventKey) => {
        setSelectedClassroomId(classrooms[eventKey].id);
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`)
        getClassesByClassroomId(classrooms[eventKey].id)
    };

    //Seleccionar materia
    const [selectedSubjectOption, setSelectedSubjectOption] = useState('Materia');
    const [selectedSubjectElement, setSelectedSubjectElement] = useState(null);

    const handleSelectSubjectOption = (eventKey) => {
        setSelectedSubjectOption(classes[eventKey].name);
        setSelectedSubjectElement(classes[eventKey])
    };


    const [classes, setClasses] = useState([]);


    const getClassesByClassroomId = async function (cid) {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classes/byTeacherId&ClassroomId/${cid}`, getUserToken());
            const res = await req.json();
            if (req.status === 200) {
                console.log(res)
                setClasses(res);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classes/byClassroomId/${cid}`, getUserToken());
            const res = await req.json();
            if (req.status === 200) {
                console.log(res)
                setClasses(res);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id);
                    getClassesByClassroomId(res[0].id)
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id);
                    getClassesByClassroomId(res[0].id)
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const handleInputYear = (e) => {
        /*         if(e.target.value > 1992 && e.target.value <= moment().format('YYYY')){
                    getClassrooms(e.target.value)
                } */
    }

    const postPendingSubject = async function () {
        let selectedContactsIds = [];
        for (let s of selectedContacts) {
            selectedContactsIds.push(s.id);
        }

        let body = {
            userIds: selectedContactsIds,
            year: document.getElementById("owedsubjectYearInput").value,
            subjectId: selectedSubjectElement.subjectId,
            subSubjectId: selectedSubjectElement.subSubjectId,
            degree: selectedClassroomOption.split("º")[0]
        };

        console.log(body)
        const req = await POST(`/academicRecord/pendingSubjects`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            searchUsersGeneral();
            handleCloseModalNewOwedSubject();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getStudentsWithPendingSubjects = async function () {
        const req = await GET(`/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [usersGeneralSearch, setUsersGeneralSearch] = useState([]);
    const [eventKeys, setEventKeys] = useState(['0', '1']);
    const [isLoading, setIsLoading] = useState(false);

    const searchUsersGeneral = async function () {
        const chain = document.getElementById('textSearchGeneral').value.length > 0 ? document.getElementById('textSearchGeneral').value : "null";
        const approved = selectedStatusOption.value == 0 ? 'all' : selectedStatusOption.value == 1 ? 'approved' : 'disapproved';

        setIsLoading(true);

        const req = await GET(`/academicRecord/pendingSubjects/bylevelFilterApproved&Student/${chain}/${approved}/${groupedStudents}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            if (groupedStudents) {
                setEventKeys([]);
                let ae = [];
                for (let i = 0; i < res.length; i++) {
                    ae.push(`${i}`);
                }
            }
            setUsersGeneralSearch(res);
            setIsLoading(false);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [groupedStudents, setGroupedStudents] = useState(true);
    const handleGroupStudents = (e) => {
        setGroupedStudents(e.target.checked);
    };


    let statesOptions = [{ value: 1, name: 'Aprobada' }, { value: 0, name: 'Desaprobada' }];
    const [selectedState, setselectedState] = useState(statesOptions[0]);
    const handleState = (eventKey) => {
        setselectedState(statesOptions[eventKey]);
    };
    const [showConfirmChangeStateModal, setShowConfirmChangeStateModal] = useState(false);
    const handleCloseConfirmChangeStateModal = () => setShowConfirmChangeStateModal(false);
    const [subjectToApprove, setSubjectToApprove] = useState(null);
    const [studentWithSubjectToApprove, setStudentWithSubjectToApprove] = useState(null)
    const handleShowConfirmChangeStateModal = (r, s) => {
        if (r.subject != null) {
            setSubjectToApprove({
                name: r.subject.name,
                degree: r.degree,
                division: r.division,
            })
        } else {
            setSubjectToApprove(r);
        }

        setSelectedNumericalGrade(null);
        setSelectedGradeTypeIsNumerical(true);
        setSelectedEvaluativeGrades({
            approved: true,
            equivalent: -1,
            id: null,
            name: "---",
            value: "---"
        })
        setStudentWithSubjectToApprove(s)
        setShowConfirmChangeStateModal(true);
    };

    const [status, setStatus] = useState([{ name: 'Todas', value: 0 }, { name: 'Aprobadas', value: 1 }, { name: 'Desaprobadas', value: 2 }]);
    const [selectedStatusOption, setSelectedStatusOption] = useState(status[0]);
    const handleSelectStatus = (eventKey) => {
        setSelectedStatusOption(eventKey);
    };

    const [selectedGradeTypeIsNumerical, setSelectedGradeTypeIsNumerical] = useState(true);
    const handleGradeType = (e) => {
        setSelectedGradeTypeIsNumerical(e.target.checked);
    }

    useEffect(() => {
        console.log("use")
        searchUsersGeneral();
    }, [selectedStatusOption, groupedStudents])


    const [evaluativeGrades, setEvaluativeGrades] = useState([]);
    const [selectedEvaluativeGrades, setSelectedEvaluativeGrades] = useState(null);
    const [selectedNumericalGrade, setSelectedNumericalGrade] = useState(null);

    const handleSelectNumericalGrades = (grade) => {
        const values = grade.target.value.split(" ");
        let value = values[0].replace(",", ".");
        if (value == "") {
            setSelectedNumericalGrade(null)
        } else {
            let numericalValue = parseFloat(value);
            if (numericalValue > 10) {
                value = numericalValue % 100 == 0 ? 10 : value.charAt(0);
                numericalValue = parseFloat(value);
            }
            document.getElementById(`textNumericalGrade`).value = value
            setSelectedNumericalGrade(numericalValue);
        }

    }
    const handleSelectEvaluativeGrades = (grade) => {
        setSelectedEvaluativeGrades(grade);
    };
    const getEvaluativeGrades = async function () {
        const req = await GET(`/grades/evaluativeGrades/showGrades`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let a = [{
                approved: true,
                equivalent: -1,
                id: null,
                name: "---",
                value: "---"
            }];
            let aa = a.concat(res)
            setEvaluativeGrades(aa);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const passingGrade = 6;
    const isApprovedBadge = (i) => {
        const aBadge = (<Badge bg='' style={{ background: 'green', height: '32px' }} className='statusBadge fs-14'>A</Badge>);
        const dBagde = (<Badge bg='' style={{ background: 'red', height: '32px' }} className='statusBadge fs-14'>D</Badge>);

        if (selectedGradeTypeIsNumerical == true) {
            if (selectedNumericalGrade != null) {
                if (selectedNumericalGrade >= passingGrade) {
                    return aBadge;
                } else if (selectedNumericalGrade == -1) {
                    return '';
                } else {
                    return dBagde;
                }
            } else {
                return '';
            }
        } else if (selectedGradeTypeIsNumerical == false) {
            if (selectedEvaluativeGrades != null) {
                if (selectedEvaluativeGrades.value != '---') {
                    if (selectedEvaluativeGrades.approved == true) {
                        return aBadge;
                    } else {
                        return dBagde;
                    }
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }
    }

    const [showExamStudentsModal, setShowExamStudentsModal] = useState(false);
    const [examTableToShowStudents, setExamTableToShowStudents] = useState(null)
    const handleShowExamStudentsModal = (r) => {
        setExamTableToShowStudents(r)
        setShowExamStudentsModal(true);
    };
    const handleCloseExamStudentsModal = () => {
        setShowExamStudentsModal(false);
    }



    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} > ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4 rg-8'>

                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedStatusOption.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedStatusOption.name}>
                                    {status.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectStatus(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically'>
                            <Form.Control className='textInputGray fs-14 wi-300' placeholder="Buscar alumno..." id="textSearchGeneral" onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    searchUsersGeneral()
                                }
                            }} /><ActionButton iconFa={faSearch} title='' classExtra='ms-2' func={searchUsersGeneral} />

                        </div>
                    </Col>
                    <Col>
                        <div className='align-center-vertically' style={{ height: '100%' }}>
                            <FontAwesomeIcon height={30} icon={faUserGroup} className='dropDownIcon' style={{ height: 16 }} />
                            <Form.Check type="switch" label="Agrupar por alumno" onChange={(val) => handleGroupStudents(val)} defaultChecked={true} />
                        </div>
                    </Col>
                    <div className='d-none d-lg-block col'>
                        <Col className=' endRow'>
                            <div className='  fitContentWidth'>
                                <ActionButton iconFa={faPlus} title={'Añadir'} func={handleShowModalNewOwedSubject} classExtra='fs-14' />
                            </div>

                        </Col>
                    </div>
                    <Col className='d-block d-lg-none' md={12}>
                        <Button variant='primary' className='fs-14 totalWidth' onClick={handleShowModalNewOwedSubject}><FontAwesomeIcon className='mr-10' icon={faPlus} />Añadir</Button>
                    </Col>

                </Row>
                <Card className='cards mobile-cards  mt-3'>
                    {isLoading ? <div className='justify-content-center'>
                        <Loading animation={'border'} classExtra='loading-100' />
                    </div> :
                        usersGeneralSearch != null && groupedStudents ?
                            usersGeneralSearch.length == 0 ? <div className='justify-content-center'>
                                <NotFound classExtra="loading-100 " iconFa={faFileCircleExclamation} text='Busque un alumno' />
                            </div> : <Accordion alwaysOpen>
                                {usersGeneralSearch != null && usersGeneralSearch.map((s, i) => (
                                    <Accordion.Item key={i} eventKey={`${i}`} className='mb-2' style={{ border: '1px solid rgb(240, 240, 240)', borderRadius: '8px' }}>
                                        <Accordion.Header className=''>
                                            <Row style={{ width: '100%' }}>
                                                <Col md={9} xs={10} className='ps-0'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='fitContentWidth'>
                                                            <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                                {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}

                                                            </div>
                                                        </div>
                                                        <p className='classesTime m-0'><p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name}</p></p>
                                                    </div>
                                                </Col>
                                                <Col md={3} xs={2} className='endRow pe-0'>
                                                    <div className='pe-3 d-none d-lg-block endRow me-2 align-center-vertically-v2 pe-3' >
                                                        {s.pendingSubjects && s.pendingSubjects.length} {s.pendingSubjects && s.pendingSubjects.length > 1 ? 'materias' : 'materia'}
                                                    </div>
                                                    <div className='d-block d-lg-none pe-0 endRow me-2 align-center-vertically-v2 pe-0' >
                                                        {s.pendingSubjects && s.pendingSubjects.length}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Accordion.Header>
                                        <Accordion.Body className='p-3 pb-2'>
                                            {s.pendingSubjects && s.pendingSubjects.map((r, j) => (
                                                <><div key={j} className='d-none d-lg-block'>
                                                    <Row className='mb-2 pb-1 pt-1 pe-2 ps-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                        <Col md={6} className='align-center-vertically-v2'>
                                                            <div style={{ textAlign: 'left' }}>
                                                                {r.subject.name} - {r.degree}º{r.division} ({r.year})
                                                            </div>
                                                        </Col>
                                                        <Col md={4} >
                                                            <div className='align-center-vertically' style={{ height: '100%' }}>
                                                                <Badge bg={r.isApproved ? 'success' : 'warning'} style={{ color: `${r.isApproved ? 'white' : 'black'}` }} className='fs-14 ms-2 fw-400'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-center-vertically-v2'>
                                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                                <Dropdown className='ms-2'>
                                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => handleShowConfirmChangeStateModal(r, s)} ><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />Aprobar/desaprobar</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                    <div key={j} className='d-block d-lg-none'>
                                                        <Row className='mb-2 pb-2 pt-1 pe-2 ps-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                            <Col md={6} xs={9} className='align-center-vertically-v2'>
                                                                <div style={{ textAlign: 'left' }}>
                                                                    {r.subject.name} - {r.degree}º{r.division} ({r.year})
                                                                </div>
                                                                <div className='align-center-vertically' style={{ height: '100%' }}>
                                                                    <Badge bg={r.isApproved ? 'success' : 'warning'} style={{ color: `${r.isApproved ? 'white' : 'black'}` }} className='fs-14 fw-400 mt-1'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                                </div>
                                                            </Col>

                                                            <Col md={2} xs={3} className='align-center-vertically-v2'>
                                                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <Dropdown className='ms-2'>
                                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => handleShowConfirmChangeStateModal(r, s)} ><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />Aprobar/desaprobar</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div></>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>

                                ))}</Accordion> :
                            usersGeneralSearch != null && usersGeneralSearch.length == 0 ? <div className='justify-content-center'>
                                <NotFound classExtra="loading-100 " iconFa={faFileCircleExclamation} text='Busque un alumno' />
                            </div> : <>
                                <Accordion alwaysOpen>
                                    {usersGeneralSearch != null && usersGeneralSearch.map((r, i) => (
                                        <Accordion.Item key={i} eventKey={`${i}`} className='mb-2' style={{ border: '1px solid rgb(240, 240, 240)', borderRadius: '8px' }}>
                                            <Accordion.Header className=''>
                                                <Row style={{ width: '100%' }}>
                                                    <Col md={9} xs={10} className='ps-0'>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <p className='classesTime m-0'><p className='m-0'>{r.subject && r.subject.name} - {r.degree}º{r.division} ({r.year})</p></p>
                                                        </div>
                                                    </Col>
                                                    <Col md={3} className='endRow pe-0' xs={2}>
                                                        <div className='pe-3 d-none d-lg-block endRow me-2 align-center-vertically-v2 pe-3' >
                                                            {r.users && r.users.length} {r.users && r.users.length > 1 ? 'alumnos' : 'alumno'}
                                                        </div>
                                                        <div className='d-block d-lg-none pe-0 endRow me-2 align-center-vertically-v2 pe-0' >
                                                            {r.users && r.users.length}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Accordion.Header>
                                            <Accordion.Body className='p-3 pb-2'>
                                                {r.users && r.users.map((s, j) => (
                                                    <><div key={j} className='d-none d-lg-block'>
                                                        <Row className='mb-2 pb-1 pt-1 pe-2 ps-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                            <Col md={6} className='align-center-vertically-v2'>
                                                                <div style={{ textAlign: 'left' }}>
                                                                    {formatNameV2(s.name, s.surname)}
                                                                </div>
                                                            </Col>
                                                            <Col md={4} >
                                                                <div className='align-center-vertically' style={{ height: '100%' }}>
                                                                    <Badge bg={r.isApproved ? 'success' : 'warning'} style={{ color: `${r.isApproved ? 'white' : 'black'}` }} className='fs-14 ms-2 fw-400'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                                </div>
                                                            </Col>
                                                            <Col md={2} className='align-center-vertically-v2'>
                                                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                                    <Dropdown className='ms-2'>
                                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item onClick={() => handleShowConfirmChangeStateModal(r, s)} ><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />Aprobar/desaprobar</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                        <div key={j} className='d-block d-lg-none'>
                                                            <Row className='mb-2 pb-2 pt-1 pe-2 ps-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                                <Col md={6} xs={9} className='align-center-vertically-v2'>
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        {formatNameV2(s.name, s.surname)}
                                                                    </div>
                                                                    <div className='align-center-vertically' style={{ height: '100%' }}>
                                                                        <Badge bg={r.isApproved ? 'success' : 'warning'} style={{ color: `${r.isApproved ? 'white' : 'black'}` }} className='fs-14 fw-400 mt-1'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                                    </div>
                                                                </Col>

                                                                <Col md={2} xs={3} className='align-center-vertically-v2'>
                                                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                                        <Dropdown className='ms-2'>
                                                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => handleShowConfirmChangeStateModal(r, s)} ><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />Aprobar/desaprobar</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div></>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    ))}</Accordion>
                            </>
                    }
                </Card>
            </div>

            <Modal size='lg' show={showModalNewOwedSubject} onClick={handleOutsideClick} onHide={handleCloseModalNewOwedSubject} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Añadir</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Row style={{ rowGap: '7px' }}>
                        <Col md={6}>
                            <div className='align-center-vertically '>
                                <FontAwesomeIcon icon={faUser} className='dropDownIcon' />
                                <div className='' style={{ width: "100%" }}>
                                    <div className='align-center-vertically'>
                                        <div className=''>
                                            <Form.Control className='d-none d-lg-block textInputGray fs-14 wi-300' placeholder="Buscar alumno..." id="textSearchDesktop" onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    searchUsers()
                                                }
                                            }} />
                                        </div>

                                        <Form.Control className='d-block d-lg-none textInputGray fs-14 wi-200' placeholder="Buscar alumno..." id="textSearchMobile" onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                searchUsers()
                                            }
                                        }} /><ActionButton iconFa={faSearch} title='' classExtra='ms-2' func={searchUsers} /></div>
                                    <div className='search-results' style={{ display: searchResults.length > 0 ? 'block' : 'none' }}>
                                        {searchResults && searchResults.map((contact, index) => (
                                            <div key={index} onClick={() => handleContactClick(contact)} style={{ cursor: 'pointer' }}>
                                                {contact.name} {contact.surname}
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col className='align-center-vertically ps-0' md={12}>
                            <div className='align-center-vertically' style={{ flexWrap: 'wrap', rowGap: '5px' }} >
                                {selectedContacts.map((contact, index) => (
                                    <><div key={index} className='ms-2 userSelectedModal' style={{ display: 'inline-block' }}>
                                        <span>{contact.name} {contact.surname}</span>
                                        <FontAwesomeIcon onClick={() => removeContact(contact)} icon={faTimes} className='remiderCross ms-2' />
                                    </div>
                                    </>
                                ))}
                            </div>
                        </Col>
                        <Col className='fitContentWidth'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' />
                                <Form.Control className='textInputGray fs-14 wi-100' type="number" placeholder="Año" id="owedsubjectYearInput" defaultValue={"2024"} onChange={(e) => handleInputYear(e)} />
                            </div>
                        </Col>
                        <Col className='fitContentWidth' md={3}>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faGraduationCap} className='dropDownIcon' />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100" disabled={selectedClassroomId == -1 ? true : false}>
                                        {selectedClassroomOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                        {classrooms && classrooms.length > 0 && classrooms.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectClassroomOption(i)}>{`${opt.degree}º ${opt.division}`}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col className=''>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' />
                                <Dropdown className='d-none d-lg-block dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={selectedClassroomId == -1 ? true : false}>
                                        {selectedSubjectOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedSubjectOption}>
                                        {classes && classes.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectSubjectOption(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className='d-block d-lg-none dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200" disabled={selectedClassroomId == -1 ? true : false}>
                                        {selectedSubjectOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedSubjectOption}>
                                        {classes && classes.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectSubjectOption(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewOwedSubject}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postPendingSubject} ><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmChangeStateModal} onHide={handleCloseConfirmChangeStateModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faClipboardCheck} style={{ minWidht: '20px' }} /><Modal.Title>Aprobar / desaprobar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    {studentWithSubjectToApprove != null && <InfoField extraClass='mb-2' title='Alumno' content={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className='classesTime m-0'><p className='m-0'>{studentWithSubjectToApprove.surname && studentWithSubjectToApprove.surname.toUpperCase()}, {studentWithSubjectToApprove.name}</p></p>
                        </div>
                    } />}
                    {subjectToApprove != null && <InfoField extraClass='mb-3' title='Materia' content={`${subjectToApprove.name} - ${subjectToApprove.degree}º${subjectToApprove.division}`} />
                    }
                    <Form.Check type="switch" label="Calificación numérica" onChange={(val) => handleGradeType(val)} defaultChecked={true} className='mb-2' />

                    <div className='align-center-vertically'>
                        <FontAwesomeIcon icon={faClipboardCheck} className='dropDownIcon' style={{ height: 16 }} />
                        {/* <Dropdown className='dropdownButton'>
                            <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                {selectedState.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="fs-14" title={selectedState.name}>
                                {statesOptions.map((opt, i) => (
                                    <Dropdown.Item key={i} onClick={() => handleState(i)}>{opt.name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown> */}
                        {selectedGradeTypeIsNumerical == true ?
                            <Form.Control className='textInputGray fs-14 wi-100 me-2' id={`textNumericalGrade`} placeholder="Nota" onChange={(event) => handleSelectNumericalGrades(event, "textNumericalGrade")} />
                            :
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100 me-2">
                                    {selectedEvaluativeGrades && selectedEvaluativeGrades.value}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedEvaluativeGrades && selectedEvaluativeGrades.value}>
                                    {evaluativeGrades && evaluativeGrades.map((opt, j) => (
                                        <Dropdown.Item key={j} onClick={() => handleSelectEvaluativeGrades(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        {isApprovedBadge()}
                    </div>

                </Modal.Body>
                <Modal.Footer className='p-2 mt-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmChangeStateModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2' >{true ? <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> : <div className="justify-content-center" style={{ width: '85px' }}>
                        <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                    </div>}</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default PendingSubjects