import React, { useEffect } from 'react'
import { Row } from "react-bootstrap";
import { getAllLocalStorageData } from '../../../controllers/user.controller';

export default function Debug() {

    const [data, setData] = React.useState(<div></div>);

    useEffect(() => {
        document.title = `Tasky`;
        setData(getAllLocalStorageData());
    }, []);

    return (
        <div>
            <Row>
                {data}
            </Row>
        </div>
    );
}