import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookReader, faChalkboardTeacher, faChartBar, faCheck, faClipboardUser, faEllipsisV, faEye, faFileCircleCheck, faFileExport, faHandHoldingHeart, faHandsHelping, faPen, faPeopleCarry, faPrint, faTimes, faTrash, faUniversalAccess, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, PUT } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import Loading from '../../general-components/Loading'
import InfoField from '../../general-components/InfoField'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

function Indicators() {
    const PAGE_TITLE = "Indicadores";
    const PAGE_ICON = faChartBar;

    const [isTableLoaded, setIsTableLoaded] = useState(false);

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getAllIndicators();
    }, []);

    const [indicators, setIndicators] = useState([]);

    const getAllIndicators = async function () {
        const req = await GET(`/indicators/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let ind = [];
            for (let i of res) {
                i.jsonProcessed = JSON.parse(i.json)
                ind.push(i)
            }
            setIndicators(ind)
            //getIndicatorsByMicroserviceAndSlug(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }



    function RECHART_StackedBarChart({ ind }) {
        return <>
            <ResponsiveContainer width="100%" height={400} className={'mt-4'}>
                <BarChart
                    width={500}
                    height={300}
                    data={ind.data}
                    margin={{
                        top: 0,
                        right: 10,
                        left: -30,
                        bottom: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={ind.config.xDataKey} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {ind.config.barsDataKey.map((bdk, j) => (
                        <Bar dataKey={bdk.dataKey} stackId="a" fill={bdk.fill} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    }

    function RECHART_SimpleBarChart({ ind }) {
        return <>
            <ResponsiveContainer width="100%" height={400} className={'mt-4'}>
                <BarChart
                    width={500}
                    height={300}
                    data={ind.data}
                    margin={{
                        top: 0,
                        right: 10,
                        left: -30,
                        bottom: 0,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={ind.config.xDataKey} />
                    {ind.config.yDomain ?
                        <YAxis domain={ind.config.yDomain} />
                        :
                        <YAxis />
                    }
                    <Tooltip />
                    <Legend />
                    {ind.config.barsDataKey.map((bdk, j) => (
                        <Bar dataKey={bdk.dataKey} fill={bdk.fill} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </>
    }


    function RECHART_SimplePieChart({ ind }) {
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8A2BE2', '#DC143C', '#32CD32', '#FFD700', '#4B0082', '#00CED1'];
        const RADIAN = Math.PI / 180;

        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {ind.data[index].value}
                </text>
            );
        };

        return <>
            <ResponsiveContainer width="100%" height={200} className={'mt-4'}>
                <PieChart width={200} height={200}>
                    <Pie
                        data={ind.data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {ind.data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <p>
                {
                    ind.data.map((r, i) => (
                        <span className='me-1' style={{ background: `${COLORS[i % COLORS.length]}`, padding: '3px 8px 3px 8px', color: 'white', borderRadius: 6 }}>{r.name}</span>
                    ))
                }
            </p>
        </>
    }


    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    {indicators.map((ind, i) => (
                        <Col md={ind.jsonProcessed.cols}>
                            <Card className='cards mt-2'>
                                {ind.jsonProcessed.type == "RECHART_StackedBarChart" ?
                                    <>
                                        <p>
                                            <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFileCircleCheck}></FontAwesomeIcon></span>
                                            <span className='ms-2'>Inscripciones - {ind.jsonProcessed.description}</span>
                                        </p>
                                        <RECHART_StackedBarChart ind={ind.jsonProcessed} />
                                    </>
                                    : <></>
                                }

                                {ind.jsonProcessed.type == "RECHART_SimpleBarChart" ?
                                    <>
                                        <p>
                                            <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFileCircleCheck}></FontAwesomeIcon></span>
                                            <span className='ms-2'>Calificaciones - {ind.jsonProcessed.description}</span>
                                        </p>
                                        <RECHART_SimpleBarChart ind={ind.jsonProcessed} />
                                    </>
                                    : <></>
                                }

                                {ind.jsonProcessed.type == "RECHART_PieChart" ?
                                    <>
                                        <p>
                                            <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFileCircleCheck}></FontAwesomeIcon></span>
                                            <span className='ms-2'>{ind.jsonProcessed.description}</span>
                                        </p>
                                        <RECHART_SimplePieChart ind={ind.jsonProcessed} />
                                    </>
                                    : <></>
                                }
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

export default Indicators