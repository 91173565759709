import React, { Fragment, useEffect, useState } from 'react'
import { Col, Dropdown, Row, Card, Modal, ToggleButton, Badge, Button, Spinner, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { faClipboardUser, faChalkboardTeacher, faPen, faCaretUp, faCaretDown, faCalendar, faCheck, faArrowLeft, faPlus, faTimes, faFile, faPenFancy, faUser, faFileCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { useLocation } from "react-router-dom";
import { GET, POST, PUT } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import { getMonthsActualYear } from '../../../tools/tools';
import NotFound from '../../general-components/NotFound';
import InfoField from '../../general-components/InfoField';
registerLocale('es', es)


function Observations() {
    const PAGE_TITLE = "Observaciones";
    const PAGE_ICON = faPen;
    const PREVIOUS_PAGE = "Calificaciones";
    const PREVIOUS_URL = "/reportes/calificaciones-primaria";

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;

        getPeriods(getParam("curso"));
        getReportCardComments(getParam("curso"))
        getClassrooms();
    }, []);

    const getParam = function (name) {
        const params = new URLSearchParams(window.location.search);
        if (params.has(name)) {
            return params.get(name);
        } else {
            return null;
        }
    }

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };


    const [isTableLoaded, setIsTableLoaded] = useState(false);

    const [classrooms, setClassrooms] = useState([]);
    const [comments, setComments] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [nodals, setNodals] = useState([]);
    const [students, setStudents] = useState([]);
    const [evaluativeGrades, setEvaluativeGrades] = useState([]);
    const [reportSubjects, setReportSubjects] = useState([]);

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    
    const location = useLocation();
    const handleSelectClassroom = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getReportCardComments(classrooms[eventKey].id)
        setIsTableLoaded(false)
        navigate({
            pathname: location.pathname,
            search: `?curso=${classrooms[eventKey].id}`,
        });
    };

    const [selectedReportCommentId, setSelectedReportCommentId] = useState(null);
    const [selectedReportCommentText, setSelectedReportCommentText] = useState("");

    const [selectedPeriodOption, setSelectedPeriodOption] = useState('Período');
    const [selectedPeriodId, setSelectedPeriodId] = useState(null);
    const [selectedPeriodNumerical, setSelectedPeriodNumerical] = useState(false);

    const handleSelectPeriod = (eventKey) => {
        setSelectedPeriodOption(periods[eventKey].name);
        setSelectedPeriodId(periods[eventKey].id);
        setSelectedPeriodNumerical(periods[eventKey].PeriodGradeTypes[0].isNumerical);
    };

    const [selectedStudentOption, setSelectedStudentOption] = useState('Alumno');
    const [selectedStudentId, setSelectedStudentId] = useState(null);

    const handleSelectStudent = (eventKey) => {
        setSelectedStudentOption(`${comments[eventKey].User.surname && comments[eventKey].User.surname.toUpperCase()}, ${comments[eventKey].User.name}`);
        setSelectedStudentId(comments[eventKey].User.id);

    };

    const [showObservationModal, setShowObservationModal] = useState(false);

    const handleShowObservationModal = () => {
        setShowObservationModal(true);
    };

    const handleCloseObservationModal = () => {
        setShowObservationModal(false);
    };


    const getPeriods = async function (cid) {
        const req = await GET(`/grades/periods/byClassroomId/noGrouped/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getReportCardComments = async function (cid) {
        setIsTableLoaded(false);

        const req = await GET(`/grades/reportComment/ByClassroomId&SchoolYear/${cid}/2024`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setComments(res);
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postObservation = async function () {
        const body = {
            reportCommentId: selectedReportCommentId,
            content: document.getElementById("textComment").value
        }

        const req = await PUT(`/grades/reportComment`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getReportCardComments(selectedClassroomId!=null ? selectedClassroomId : getParam("curso"));
            handleCloseObservationModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [hoverContent, setHoverContent] = useState({});

    const handleMouseEnter = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: true,
        }))
    };
    const handleMouseLeave = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    const functEdit = (reportCommentId, reportCommentText, periodId, studentName, index) => {
        handleShowObservationModal();
        setSelectedStudentOption(studentName);
        setSelectedPeriodId(periodId);

        for (let p of periods) {
            if (periodId == p.id) {
                setSelectedPeriodOption(p.name);
            }
        }

        setSelectedReportCommentId(reportCommentId);
        setSelectedReportCommentText(reportCommentText);
    }

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Primaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                const cl = getParam("curso");

                if (cl != null) {
                    for (let c of res) {
                        if(c.id == cl){
                            setSelectedClassroomOption(`${c.degree}º ${c.division}`)
                        }
                    }
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${2024}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                const cl = getParam("curso");

                if (cl != null) {
                    for (let c of res) {
                        if(c.id == cl){
                            setSelectedClassroomOption(`${c.degree}º ${c.division}`)
                        }
                    }
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }


    }


    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClassroom(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>

                <Card className='cards mt-3 h-100-2'>
                    {!isTableLoaded ? <div className='justify-content-center'>
                        <Loading animation={'border'} classExtra='loading-100' />
                    </div> :
                        <div className='slideTable fs-14 ' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white'}}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className=' headerContainer' style={{ height: 60 }}>
                                        <td className='sticky-column' style={{width:'300px'}}>Alumno</td>
                                        {periods.map((p, i) => (
                                            <td style={{width:'300px'}}><div className='fs-14'>{p.name}</div></td>
                                        ))}

                                    </tr>

                                </thead>
                                <tbody>
                                    {comments.map((c, i) => (
                                        <tr>
                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0' style={{ width: 300 }}>
                                                <Row className='tableRowsBackground pt-2 pb-2' style={{ height: 180, minWidth:'300px' }}>
                                                    <Col className='align-center-vertically-v2 ps-3 pe-1'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {c.User.surname && c.User.surname.toUpperCase()}, {c.User.name}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>

                                            {c.ReportComments.map((rc, i) => (
                                                <td className='pb-0 pt-1 ps-0 pe-0' style={{ height: 180 }}>
                                                    <Row className='tableRows' style={{ height: '100%', minWidth: 300 }}>
                                                        <Col className='align-center-vertically-v2 p-0'>
                                                            <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                                <OverlayTrigger placement="top" delay={{ show: 20, hide: 20 }} overlay={<Tooltip id="button-tooltip" className="custom-tooltip" >
                                                                    Modificar
                                                                </Tooltip>}>
                                                                    <td className='p-0' style={{width:'300px'}} onMouseEnter={() => handleMouseEnter(rc.id)} onMouseLeave={() => handleMouseLeave(rc.id)}>
                                                                        {hoverContent[rc.id] ?
                                                                            <div style={{ minWidth: 300, height: 180, cursor: 'pointer' }} onClick={() => functEdit(rc.id, rc.content, rc.periodId, `${c.User.surname && c.User.surname.toUpperCase()}, ${c.User.name}`, i)} className='editGrades fs-11' ><FontAwesomeIcon icon={faPen} /></div>
                                                                            :
                                                                            <div style={{ minWidth: 300, height: 175, textAlign: 'left' }} className='fs-11 p-2 commentContentReports'>{rc.content}</div>
                                                                        }</td>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </td>
                                            ))}
                                        </tr>
                                    ))}



                                </tbody>
                            </table>

                        </div>
                    }
                </Card>
            </div>

            {showObservationModal ? 
                <Modal size={'lg'} show={showObservationModal} onHide={handleCloseObservationModal} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Observación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <InfoField title='Período' extraClass='w-100' content={selectedPeriodOption} />
                            </div>
                        </Col>

                        <Col md={12} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                                <InfoField title='Alumno' extraClass='w-100' content={selectedStudentOption} />
                            </div>
                        </Col>


                    </Row>

                    <Row className='p-2 mt-3' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                        <Col md={12} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                Observación
                            </div>
                        </Col>

                    </Row>
                    <Row className='mt-2 mb-3 pt-3 pb-3 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                        <Col md={12} className='align-center-vertically-v2'>
                            <div style={{ textAlign: 'left' }}>
                                <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={9} id='textComment' defaultValue={selectedReportCommentText} />
                                </Form.Group>
                            </div>
                        </Col>

                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseObservationModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postObservation} ><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal> : ''}


        </div>
    )
}

export default Observations