import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Card, Col, Dropdown, Row, Button, Modal, Badge, Form, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCircle, faClapperboard, faClock, faCog, faEllipsisV, faEnvelope, faEye, faFileAlt, faGraduationCap, faPen, faPhone, faPhoneAlt, faPhoneSquare, faPlus, faQuestionCircle, faSearch, faTicket, faTimes, faTrash, faUser, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { useNavigate } from 'react-router-dom';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller'
import ActionButton from '../../general-components/ActionButton'
import InfoField from '../../general-components/InfoField'
import Loading from '../../general-components/Loading'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Moment from 'react-moment'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

function Help() {
    const PAGE_TITLE = "Ayuda";
    const PAGE_ICON = faQuestionCircle;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = ""


    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        moment.locale('es');
    }, []);

    const [helpContacts, setHelpContacts] = useState([
        {
            name: "WhatsApp",
            icon: faWhatsapp,
            available: true,
            slug: "WHATSAPP",
            action: "5491158402933"
        },
        {
            name: "Correo electrónico",
            icon: faEnvelope,
            available: false,
            slug: "EMAIL",
            action: "+5491122601450"
        },
        {
            name: "Videollamada",
            icon: faPhoneSquare,
            available: false,
            slug: "CALL",
            action: "5491122601450" //5491158402933
        }
    ])

    const goToExternalUrl = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
            <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>
                
                <Row className='toolbar m-0'>
                    <Col>

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} > ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Container>
                    <div className='pt-4 pb-3 fw-600 fs-18' style={{ textAlign: 'center' }}>¿Cómo preferís contactarnos?</div>
                    <Row style={{ rowGap: '10px' }}>
                        {helpContacts.map((r, i) => (
                            <Col md={4} key={i}>
                                <Card className={`cards ${r.available ? 'pointer helpCard' : ''}`} onClick={r.available && r.slug == 'WHATSAPP' ? () => goToExternalUrl(`https://wa.me/${r.action}`) : ''}>
                                    <div className='align-center-vertically mb-3' style={{ justifyContent: 'center' }}>
                                        <FontAwesomeIcon icon={r.icon} style={{ width: '50px', height: '50px' }} />

                                    </div>
                                    <p style={{ textAlign: 'center' }} className={`fs-16 mb-3 ${r.available ? ' blueHover' : ''}`}>
                                        {r.name}
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        {r.available ?
                                            <span style={{ color: '#198754' }}><FontAwesomeIcon icon={faCircle} className='me-2' />Disponible</span> :
                                            <span style={{ color: '#dc3545' }}><FontAwesomeIcon icon={faCircle} className='me-2' />No disponible</span>}
                                    </div>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                </Container>
            </div>
        </div>)
}

export default Help