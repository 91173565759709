import React, { Fragment, useEffect, useState } from 'react'
import { Col, Dropdown, Row, Card, Modal, ToggleButton, Badge, Button, Spinner, Form } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { faClipboardUser, faChalkboardTeacher, faPen, faCaretUp, faCaretDown, faCalendar, faCheck, faArrowLeft, faPlus, faTimes, faFile, faPenFancy, faUser, faFileCircleExclamation, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { useLocation } from "react-router-dom";
import { GET, POST, PUT } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import { getMonthsActualYear } from '../../../tools/tools';
import NotFound from '../../general-components/NotFound';
import { OverlayTrigger, Tooltip } from 'react-bootstrap/esm';
registerLocale('es', es)


function ReportCard() {
    const PAGE_TITLE = "Boletín (vista previa)";
    const PAGE_ICON = faPen;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        getClassrooms();
    }, []);

    const [isTableLoaded, setIsTableLoaded] = useState(false);

    const [classrooms, setClassrooms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [nodals, setNodals] = useState([]);
    const [students, setStudents] = useState([]);
    const [evaluativeGrades, setEvaluativeGrades] = useState([]);
    const [reportSubjects, setReportSubjects] = useState([]);

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);
    const [selectedClassroomImMainTeacher, setSelectedClassroomImMainTeacher] = useState(false);

    const handleSelectClassroom = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        setSelectedClassroomImMainTeacher(classrooms[eventKey].IAmMainTeacher);
        setSelectedPeriodGeneralOption('Período');
        setSelectedPeriodGeneralId(null);
        setSelectedPeriodNumerical(false);
        setClasses([]);
        setIsTableLoaded(false)
    };

    const [selectedPeriodOption, setSelectedPeriodOption] = useState('Período');
    const [selectedPeriodId, setSelectedPeriodId] = useState(null);
    const [selectedPeriodNumerical, setSelectedPeriodNumerical] = useState(false);

    const handleSelectPeriod = (eventKey) => {
        setSelectedPeriodOption(periods[eventKey].name);
        setSelectedPeriodId(periods[eventKey].id);
        setSelectedPeriodNumerical(periods[eventKey].PeriodGradeTypes[0].isNumerical);
    };

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        setSelectedPeriodOption(periods[eventKey].name);
        setSelectedPeriodId(periods[eventKey].id);
        setSelectedPeriodNumerical(periods[eventKey].PeriodGradeTypes[0].isNumerical);
        getEvaluativeReport(periods[eventKey].id, selectedClassroomId)
    };

    const [selectedStudentOption, setSelectedStudentOption] = useState('Alumno');
    const [selectedStudentId, setSelectedStudentId] = useState(null);

    const handleSelectStudent = (eventKey) => {
        setSelectedStudentOption(`${students[eventKey].surname && students[eventKey].surname.toUpperCase()}, ${students[eventKey].name}`);
        setSelectedStudentId(students[eventKey].id);
        showOldGrades(eventKey);
    };

    const [showQualifyPeriodModal, setShowQualifyPeriodModal] = useState(false);

    const handleShowQualifyPeriodModal = () => {
        setSelectedStudentId(null);
        setSelectedStudentOption("Alumno");
        getEvaluativeGrades();
        resetGrades();
        setShowQualifyPeriodModal(true);
    };

    const handleCloseQualifyPeriodModal = () => {
        setShowQualifyPeriodModal(false);
    };

    const [selectedEvaluativeGrades, setSelectedEvaluativeGrades] = useState([]);
    const [selectedNumericalGrades, setSelectedNumericalGrades] = useState([]);

    const handleSelectEvaluativeGrades = (i, grade) => {
        let auxEG = [...selectedEvaluativeGrades];
        auxEG[i] = grade;
        setSelectedEvaluativeGrades(auxEG);
    };

    const handleSelectNumericalGrades = (i, grade) => {
        let auxNG = [...selectedNumericalGrades];
        auxNG[i] = grade.target.value != "" ? (!isNaN(grade.target.value) ? parseFloat(grade.target.value) : -1) : -1;
        setSelectedNumericalGrades(auxNG);
    };

    const resetGrades = () => {
        let egs = [];
        let ngs = [];
        for (let i = 0; i <= nodals.length; i++) { //nodals.length en vez de students
            egs[i] = {
                id: 0,
                name: '---',
                value: '---',
                approved: null,
                equivalent: -1
            };
            ngs[i] = -1;
        }
        setSelectedEvaluativeGrades(egs);
        setSelectedNumericalGrades(ngs);
    }


    const getClassrooms = async function () {
        if (getUserRole() == "Docente Primaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                setSelectedClassroomId(res[0].id)
                setSelectedClassroomImMainTeacher(res[0].IAmMainTeacher);
                getPeriods(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${2024}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                setSelectedClassroomId(res[0].id)
                getPeriods(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }

    }

    const getPeriods = async function (cid) {
        const req = await GET(`/grades/periods/byClassroomId/noGrouped/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEvaluativeGrades = async function () {
        const req = await GET(`/grades/evaluativeGrades/showNodals`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setEvaluativeGrades(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getEvaluativeReport = async function (pid, cid) {
        setIsTableLoaded(false);
        const req = await GET(`/grades/fullEvaluativeReport/byPeriodId&ClassroomId/${pid}/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClasses(res)
            //if (res.students) { setStudents(res.students) }
            //if (res.nodals) { setNodals(res.nodals) }
            //if (res.reportSubjects) { setReportSubjects(res.reportSubjects) }
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const passingGrade = 6;

    const isApprovedBadge = (i) => {
        const aBadge = (<Badge bg='' style={{ background: 'green' }} className='statusBadge fs-14'>A</Badge>);
        const dBagde = (<Badge bg='' style={{ background: 'red' }} className='statusBadge fs-14'>D</Badge>);

        if (selectedNumericalGrades[i] != null) {
            if (selectedNumericalGrades[i] >= passingGrade) {
                return aBadge;
            } else if (selectedNumericalGrades[i] == -1) {

            } else {
                return dBagde;
            }
        }

        if (selectedEvaluativeGrades[i] != null) {
            if (selectedEvaluativeGrades[i].value != '---') {
                if (selectedEvaluativeGrades[i].approved == true) {
                    return aBadge;
                } else {
                    return dBagde;
                }
            } else {
                return '';
            }
        } else {
            return '';
        }
    }



    const GradeValue = ({ g }) => {
        if (g.numerical) {
            //Numerical grade
            if (g.numerical == "ND") {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'white' }}>--</Badge>)
            } else if (g.numerical >= passingGrade) {
                //Approved
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'green' }}>{g.numerical}</Badge>)
            } else {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'red' }}>{g.numerical}</Badge>)
            }
        } else if (g.EvaluativeGrade) {
            //Evaluative grade
            if (g.EvaluativeGrade.approved) {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'green' }}>{g.EvaluativeGrade.value}</Badge>)
            } else {
                return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'red' }}>{g.EvaluativeGrade.value}</Badge>)
            }
        } else {
            return (<Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: 'white' }}>-</Badge>);
        }
    }

    const [hoverContent, setHoverContent] = useState({}); //[testId] = true/false, si esta con hover o no.

    const handleMouseEnter = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: true,
        }))
    };
    const handleMouseLeave = (id) => {
        setHoverContent((prevState) => ({
            ...prevState,
            [id]: false,
        }));
    };

    const functEdit = (userId, index) => {
        /*         handleShowQualifyPeriodModal(); */
        setSelectedStudentOption(`${students[index].surname && students[index].surname.toUpperCase()}, ${students[index].name}`);
        setSelectedStudentId(userId);
        showOldGrades(index);
        getEvaluativeGrades();
        setShowQualifyPeriodModal(true);
    }

    const showOldGrades = (studentIndex) => {
        let egs = [];
        let ngs = [];

        for (let i = 0; i < nodals.length; i++) {
            if (nodals[i].averages[studentIndex].EvaluativeGrade != null) {
                egs[i] = {
                    id: nodals[i].averages[studentIndex].EvaluativeGrade.id,
                    name: nodals[i].averages[studentIndex].EvaluativeGrade.name,
                    value: nodals[i].averages[studentIndex].EvaluativeGrade.value,
                    approved: nodals[i].averages[studentIndex].EvaluativeGrade.approved,
                    equivalent: nodals[i].averages[studentIndex].EvaluativeGrade.equivalent,
                };
            } else {
                egs[i] = {
                    id: 0,
                    name: '---',
                    value: '---',
                    approved: null,
                    equivalent: -1
                };
            }
            if (nodals[i].averages[studentIndex].numerical != null) {
                ngs[i] = nodals[i].averages[studentIndex].numerical;
            } else {
                ngs[i] = -1;
            }

        }
        if (reportSubjects[studentIndex].numerical != null) {
            ngs[nodals.length] = reportSubjects[studentIndex].numerical;
        } else {
            ngs[nodals.length] = -1;
        }

        if (reportSubjects[studentIndex].EvaluativeGrade != null) {
            egs[nodals.length] = {
                id: reportSubjects[studentIndex].EvaluativeGrade.id,
                name: reportSubjects[studentIndex].EvaluativeGrade.name,
                value: reportSubjects[studentIndex].EvaluativeGrade.value,
                approved: reportSubjects[studentIndex].EvaluativeGrade.approved,
                equivalent: reportSubjects[studentIndex].EvaluativeGrade.equivalent,
            }
        } else {
            egs[nodals.length] = {
                id: 0,
                name: '---',
                value: '---',
                approved: null,
                equivalent: -1
            };
        }

        setSelectedEvaluativeGrades(egs);
        setSelectedNumericalGrades(ngs);
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClassroom(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-175">
                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col className='endRow'>
                    
                    </Col>
                </Row>

                {!isTableLoaded &&
                    <Card className='cards mt-3 h-75'>
                        {selectedPeriodGeneralId != null ?
                            <>
                                <div className='justify-content-center'>
                                    <Loading animation={'border'} classExtra='mt-100' />
                                </div>
                                <div className='mt-3 w-100 text-center'>
                                    <p className='text-muted'>Este proceso puede demorar hasta 30 segundos</p>
                                </div>
                            </>
                            :
                            <div className='justify-content-center'>
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un curso, una materia y un período' />
                            </div>
                        }
                    </Card>
                }

                {classes.map((cl, i) => (
                    <Card className='cards mt-3 h-85'>
                        <p className='fs-16'>{cl.name}</p>
                        <div className='slideTable fs-14 ' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className=' headerContainer' style={{ height: 60 }}>
                                        <td className='sticky-column'>Eje</td>
                                        {cl.students.map((s, i) => (
                                            <td><div style={{ width: 100 }} className='fs-11'>{s.surname && s.surname.toUpperCase()}, {s.name}</div></td>
                                        ))}
                                    </tr>

                                </thead>
                                <tbody>
                                    {cl.nodals.map((n, i) => (
                                        <tr>
                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRowsBackground pt-2 pb-2' style={{ width: 300, height: 95 }}>
                                                    <Col className='align-center-vertically-v2 ps-3 pe-1'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {n.name}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>

                                            {n.averages.map((na, i) => (
                                                <td className='pb-0 pt-1 ps-0 pe-0' style={{ width: 100, height: 95 }}>
                                                    <Row className='tableRows' style={{ height: '100%' }}>
                                                        <Col className='align-center-vertically-v2 '>
                                                            <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                                <GradeValue g={na} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                    {cl.isGradable == true &&
                                        <tr>
                                            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRowsBackground pt-2 pb-2' style={{ width: 300, height: 50 }}>
                                                    <Col className='align-center-vertically-v2 ps-3 pe-1'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            Calificación general
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>

                                            {cl.reportSubjects.map((rs, i) => (
                                                <td className='pb-0 pt-1 ps-0 pe-0' style={{ width: 100, height: 50 }}>
                                                    <Row className='tableRows' style={{ height: '100%' }}>
                                                        <Col className='align-center-vertically-v2 '>
                                                            <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                                <GradeValue g={rs} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </td>
                                            ))}
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    )
}

export default ReportCard