import React, { Fragment, useEffect, useState } from 'react'
import { Col, Dropdown, Row, Card, Modal, ToggleButton, Badge, Button, Spinner, Form } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { faClipboardUser, faChalkboardTeacher, faPen, faCaretUp, faCaretDown, faCalendar, faCheck, faArrowLeft, faPlus, faTimes, faClock, faFileArrowUp, faDownload, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { useLocation } from "react-router-dom";
import { GET, POST } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import { convert2Base64, getMonthsActualYear } from '../../../tools/tools';
import InfoField from '../../general-components/InfoField';
registerLocale('es', es)


function Assistance() {
    const PAGE_TITLE = "Asistencia - Vista curso";
    const PAGE_ICON = faClipboardUser;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    const months = getMonthsActualYear(false);
    const [myRole, setMyRole] = useState(getUserRole())

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        moment.locale('es');
        setupMonth(moment().daysInMonth());
        setSelectedDateOption(months[moment().month()].name);
        setSelectedDateNumber(months[moment().month()].num);
        getAssistanceValues();
        getClassrooms(getParams());
    }, []);

    const [classrooms, setClassrooms] = useState([]);
    const [assistanceValuesText, setAssistanceValuesText] = useState("");
    const [assistanceValues, setAssistanceValues] = useState([]);
    const [students, setStudents] = useState([]);
    const [assistance, setAssistance] = useState([]);
    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [isTableTakeAssistanceLoaded, setIsTableTakeAssistanceLoaded] = useState(false);

    useEffect(() => {
        if (students.length > 0) {
            setDefaultAssistanceValues();
        }
    }, [students]);

    const [numberDay, setNumberDay] = useState([]);

    const setupMonth = (qty) => {
        const days = [];

        for (let i = 1; i <= qty; i++) {
            days.push(i);
        }

        setNumberDay(days);
    }

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(-1);

    const handleSelect = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getAssistance(classrooms[eventKey].id, selectedDateNumber);
    };

    const [selectedDateOption, setSelectedDateOption] = useState('Fecha');
    const [selectedDateNumber, setSelectedDateNumber] = useState(null);

    const handleSelectDate = (i) => {
        setIsTableLoaded(false);
        setSelectedDateOption(months[i].name);
        setSelectedDateNumber(months[i].num);
        setupMonth(months[i].qtyDays);
        getAssistance(selectedClassroomId, months[i].num);
        setSelectedTime({});
        setFile({})
    };

    const dataCard = [{
        title: 'Asistencia del año',
        value: '89',
        subtitle: '',
        compare: '-'
    }, {
        title: 'Faltas justificadas',
        value: '58',
        subtitle: '',
        compare: '-'
    },
    {
        title: 'Asistencia del mes',
        value: '91',
        subtitle: '',
        compare: '+'
    }, {
        title: 'Asistencia del día',
        value: '98',
        subtitle: '',
        compare: '+'
    }]

    const stadisticCard = (title, value, subtitle, compare, i) =>
        <Col md={3} key={i} className='ps-0'>
            <Card className='staticCards cards'>
                <p className='m-0'>{title}</p>
                <div className='wrapCont flexEnd'>
                    <p className='m-0 fw-700 fs-38'>{value}%</p>
                    {compare == '+' ? <FontAwesomeIcon className='arrowStatics' style={{ color: '#73D64D' }} icon={faCaretUp} /> : <FontAwesomeIcon className='arrowStatics' style={{ color: '#EA4335' }} icon={faCaretDown} />}
                    <span className='fs-8' style={{ padding: '0px 5px 10px' }}>{subtitle}</span>
                </div>
            </Card>
        </Col>

    const [showTakeAssistance, setShowTakeAssistance] = useState(false);

    const handleShowTakeAssistance = () => {
        getClassroomStudents(selectedClassroomId, true, moment(selectedDateDay).format('YYYY-MM-DD'));
        setIsPostReady(-1);
        setShowTakeAssistance(true)
    };

    const handleCloseModalTakeAssistance = () => {
        setShowTakeAssistance(false);
    }

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                setClassrooms(res);
                let classroomIdUrl = getParams();
                if (res.length > 0) {
                    if (classroomIdUrl >= 0) {
                        let index = res.findIndex(item => item.id == classroomIdUrl);
                        index = index >= 0 ? index : 0;
                        setSelectedClassroomOption(`${res[index].degree}º ${res[index].division}`);
                        setSelectedClassroomId(res[index].id)
                        getAssistance(res[index].id, moment().month() + 1)
                    } else {
                        setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                        setSelectedClassroomId(res[0].id)
                        getAssistance(res[0].id, moment().month() + 1)
                    }
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                if (res.length > 0) {
                    let classroomIdUrl = getParams();
                    if (classroomIdUrl >= 0) {
                        let index = res.findIndex(item => item.id == classroomIdUrl);
                        index = index >= 0 ? index : 0;
                        setSelectedClassroomOption(`${res[index].degree}º ${res[index].division}`);
                        setSelectedClassroomId(res[index].id)
                        getAssistance(res[index].id, moment().month() + 1)
                    } else {
                        setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                        setSelectedClassroomId(res[0].id)
                        getAssistance(res[0].id, moment().month() + 1)
                    }

                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getClassroomStudents = async function (cId, avatars, date) {
        setIsTableTakeAssistanceLoaded(false);
        const req = await GET(`/assistance/courseByClassroomIdAndDate/${cId}/${date}/${avatars}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res);
            setIsTableTakeAssistanceLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAssistance = async function (csId, month) {
        setIsTableLoaded(false);

        const req = await GET(`/assistance/courseByClassroomIdAndMonth/${csId}/${month}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setAssistance(res)
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAssistanceValues = async function () {
        const req = await GET(`/assistance/values/byLevelId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let txt = "";
            for (let i = 0; i < res.length; i++) {
                if (i == (res.length - 1)) {
                    txt += `${res[i].shortName}: ${res[i].name}`
                } else {
                    txt += `${res[i].shortName}: ${res[i].name} | `
                }
            }
            txt += " | F: Feriado";
            setAssistanceValuesText(txt);
            setAssistanceValues(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const formatDateTime = (dateOrHour) => {
        let combined;

        if (moment(dateOrHour, moment.ISO_8601, true).isValid()) {
            combined = moment(dateOrHour);
            return combined.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        }
        else {
            const currentDate = moment().format('YYYY-MM-DD');
            combined = moment(`${currentDate}T${dateOrHour}`, 'YYYY-MM-DDTHH:mm:ss');
        }
        return combined.utcOffset('-03:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    };


    function formatHour(hour) {
        if (hour.length == 8) {
            // const combined = moment(`${moment().format('YYYY-MM-DD')}T${hour}`);
            // return combined.format('YYYY-MM-DDTHH:mm:ss.000-03:00');
            const combined = moment(`${moment().format('YYYY-MM-DD')}T${hour}`, 'YYYY-MM-DDTHH:mm:ss').toDate();
            combined.setHours(combined.getHours() - 3);
            return combined;
        }
        return hour;
    }



    const [isPostReady, setIsPostReady] = useState(-1);
    const postAssistance = async function () {
        let body = [];
        setIsPostReady(false);
        console.log("Post")
        for (let s of students) {
            const av = valueCheckedButtons[s.id];
            const avF = getChosenFaultsValue(av);

            let fileStudent = null;
            if (getChosenJustifiedValue(av) && file[s.id]) {
                if (!isNaN(file[s.id])) {
                    fileStudent = null
                } else {
                    console.log(file[s.id])
                    const f = await convert2Base64(file[s.id]);
                    const aux = file[s.id].name.split('.')
                    const fileExtension = `${aux[aux.length - 1]}`
                    const fileName = file[s.id].name;
                    fileStudent = {
                        b64: f,
                        name: fileName.replace(`.${fileExtension}`, ""),
                        extension: fileExtension
                    }
                }
            }

            let time = (avF > 0 && avF < 1) || av == "TC" || av == "TCJ" || av == "RC" || av == "RCJ" ? selectedTime[s.id]/*  moment(selectedTime[s.id]).format('HH:mm:ss') */ : null;

            body.push({
                classId: null,
                userId: s.id,
                valueId: getChosenAssistanceValue(s.id),
                date: moment(selectedDateDay).format('YYYY-MM-DD'),
                classroomId: selectedClassroomId,
                file: fileStudent,
                hour: time != null ? formatDateTime(time) : time
            });
        }
        console.log("Body assistance")
        console.log(body)
        const req = await POST(`/assistance`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getAssistance(selectedClassroomId, selectedDateNumber)
            setIsPostReady(true);
            handleCloseModalTakeAssistance();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getChosenAssistanceValue = (userId) => {
        let value = null;
        let i = 0;
        let cb = checkedButtons[userId];

        while (value == null && i < assistanceValues.length) {
            if (cb[`check-${assistanceValues[i].shortName}-${userId}`]) {
                value = assistanceValues[i].id;
            }
            i++;
        }

        return value;
    }

    const getChosenFaultsValue = (assistanceValue) => {
        let value = 0;
        let i = 0;

        while (i < assistanceValues.length) {
            if (assistanceValue == assistanceValues[i].shortName) {
                value = assistanceValues[i].faults;
                break;
            }
            i++;
        }

        return value;
    }

    const getChosenJustifiedValue = (assistanceValue) => {
        let value = false;
        let i = 0;

        while (i < assistanceValues.length) {
            if (assistanceValue == assistanceValues[i].shortName) {
                value = assistanceValues[i].isJustified;
                break;
            }
            i++;
        }

        return value;
    }

    const getChosenNameValue = (assistanceValue) => {
        let value = false;
        let i = 0;

        while (i < assistanceValues.length) {
            if (assistanceValue == assistanceValues[i].shortName) {
                value = assistanceValues[i].name;
                break;
            }
            i++;
        }

        return value;
    }


    const [checkedButtons, setCheckedButtons] = useState({});
    const [valueCheckedButtons, setvalueCheckedButtons] = useState({});

    const setDefaultAssistanceValues = () => {
        const initialStates = {};
        const initialValues = {};
        const initialFaults = {};
        const initialJustified = {};
        const initialTime = {};
        const initialFiles = {};

        for (let s of students) {
            initialStates[s.id] = {
                [`check-${s.Assistance.value == "ND" ? "P" : s.Assistance.value}-${s.id}`]: true
            };
            initialValues[s.id] = s.Assistance.value == "ND" ? "P" : s.Assistance.value;

            initialFaults[s.id] = s.Assistance.value == "ND" || s.Assistance.value == "P" ? 0 : getChosenFaultsValue(s.Assistance.value);
            initialJustified[s.id] = s.Assistance.value == "ND" || s.Assistance.value == "P" ? 0 : getChosenJustifiedValue(s.Assistance.value);
            initialTime[s.id] = s.Assistance.hour != null ? formatHour(s.Assistance.hour) : null;
            initialFiles[s.id] = s.Assistance.fileId;
            //assistance[s.id].dates[]
        }

        setSelectedTime(initialTime);
        setFile(initialFiles);
        setCheckedButtons(initialStates);
        setvalueCheckedButtons(initialValues);
        setJustifiedCheckedButtons(initialJustified);
        setFaultsCheckedButtons(initialFaults);
    };

    const handleToggleChange = (buttonId, ind) => {
        setCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: {
                //[buttonId]: !prevState[ind]?.[buttonId]
                [buttonId]: true
            }
        }));
        let parts = buttonId.split("-")
        let leter = parts[1]
        setvalueCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: leter,
        }));
        let isJustified = getChosenJustifiedValue(leter);
        let faults = getChosenFaultsValue(leter);
        setJustifiedCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: isJustified,
        }));
        setFaultsCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: faults,
        }));
    };

    const [selectedDateDay, setSelectedDateDay] = useState(new Date());

    const handleChangeDate = date => {
        setSelectedDateDay(date);
        getClassroomStudents(selectedClassroomId, true, moment(date).format('YYYY-MM-DD'));
    };

    const handleFile = async (event, id) => {
        setFile((prevState) => ({
            ...prevState,
            [id]: event.target.files[0],
        }));
        console.log(event.target.files[0])
    }
    const [file, setFile] = useState({});

    const [selectedTime, setSelectedTime] = useState();
    const handleChangeTime = (date, id) => {
        console.log(date)
        setSelectedTime((prevState) => ({
            ...prevState,
            [id]: date,
        }));
    };

    const deleteFile = (id) => {
        setFile((prevState) => ({
            ...prevState,
            [id]: null,
        }));
    }

    useEffect(() => {
        console.log("Files: ", file)
    }, [file])

    const [valueFaultsCheckedButtons, setFaultsCheckedButtons] = useState({});
    const [valueJustifiedCheckedButtons, setJustifiedCheckedButtons] = useState({});

    const studentRowAssist = (sa, i) => {

        let stdDays = []

        for (let d of sa.dates) {
            stdDays.push(
                <td className='pb-0 pt-1 ps-0 pe-0'>
                    <Row className='tableRows'>
                        <Col className='align-center-vertically-v2 ps-1 pe-1'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Badge onClick={() => { if (d.color !== "#6C757D" && d.shortName !== "ND") { handleShowAssistanceDetail(sa, d); } }} bg='' className='assistanceBadge fs-14' style={{ height: 36, width: 36, background: `${d.color}`, color: `${d.color != '#FFFFFF' ? 'white' : 'black'}`, cursor: d.color != "#6C757D" && d.shortName != "ND" ? 'pointer' : 'auto' }}>{d.shortName != 'ND' ? d.shortName : '-'}</Badge>
                            </div>
                        </Col>
                    </Row>
                </td>
            )
        }

        return (<tr key={i} className=''>
            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                    <Col className='align-center-vertically-v2'>
                        <div style={{ textAlign: 'center', width: 20 }}>
                            {i + 1}
                        </div>
                    </Col>
                </Row>
            </td>
            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2'>
                        <div style={{ textAlign: 'left', width: 250 }} className='d-none d-lg-block'>
                            {sa.surname ? sa.surname.toUpperCase() : ''}, {sa.name}
                        </div>
                        <div style={{ textAlign: 'left', width: 100 }} className='d-block d-lg-none fs-12'>
                            {sa.surname ? sa.surname.toUpperCase() : ''}, {sa.name[0]}.
                        </div>
                    </Col>
                </Row>
            </td>

            {stdDays.map((s) => (s))}

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats.assist.total}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.unjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.noComputable : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalJustified : ''}</Badge>
                            {/*                             <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{studentAssistance.stats.justifyFaults.yearTotal[i]}</Badge> */}
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalUnjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

        </tr>)
    }

    const [showAssistanceDetail, setShowAssistanceDetail] = useState(false);
    const [studentAssistanceDetailModal, setStudentAssistanceDetailModal] = useState(null)
    const handleShowAssistanceDetail = (sa, d) => {
        setStudentAssistanceDetailModal({
            student: sa,
            date: d,
            AssistanceName: getChosenNameValue(d.shortName)
        });

        setShowAssistanceDetail(true)
    };

    const handleCloseModalAssistanceDetail = () => {
        setShowAssistanceDetail(false);
    }

    const downloadFile = async function (fileId) {
        const req = await GET(`/files/byId/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Certificado ${studentAssistanceDetailModal.student.surname}.${res.extension}`;
            downloadLink.click();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className='' style={{ rowGap: '10px' }}>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedDateOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedDateOption}>
                                    {months.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectDate(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    {/*                     id: {selectedClassroomId} */}

                    <Col className='d-none d-lg-block'>
                        {myRole != "Docente Secundaria" && <div className='endRow'>
                            <ActionButton iconFa={faPen} title={'Tomar asistencia'} func={handleShowTakeAssistance} classExtra='fs-14' />
                        </div>}
                    </Col>
                    <Col xs={12} className='d-block d-lg-none'>
                        {myRole != "Docente Secundaria" && <Button variant='primary' className='fs-14 totalWidth' onClick={handleShowTakeAssistance}><FontAwesomeIcon className='mr-10' icon={faPen} />Tomar asistencia</Button>
                        }</Col>
                </Row>

                {/* <Row className='mt-3'>
                    {dataCard.map((ind, i) =>
                        stadisticCard(ind.title, ind.value, ind.subtitle, ind.compare, i)
                    )}
                </Row>*/}
                <Row>
                    <p className='fs-11 mb-0 mt-3'>{assistanceValuesText}</p>

                </Row>

                <Card className='mobile-cards cards mt-2 h-100-3'>
                    {!isTableLoaded ? <div className='justify-content-center'>
                        <Loading animation={'border'} classExtra='loading-100' />
                    </div> :

                        <div className='slideTable fs-14 ' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className=' headerContainer'>
                                        <td className='sticky-column' rowSpan="2">#</td>
                                        <td className='sticky-column' rowSpan="2">Estudiante</td>
                                        {
                                            numberDay.map((num, i) =>
                                                <td key={i} rowSpan="2">{num}</td>
                                            )
                                        }

                                        <td colSpan={4}>Este mes</td>
                                        <td colSpan={2}>Anteriores</td>
                                        <td rowSpan="2" className='fs-11'>Total</td>
                                        <td rowSpan="2" className='fs-11'>Total just.</td>
                                        <td rowSpan="2" className='fs-11'>Total injust.</td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        <td className='fs-11'>Asisten.</td>
                                        <td className='fs-11'>Just.</td>
                                        <td className='fs-11'>Injust.</td>
                                        <td className='fs-11'>N/C</td>
                                        <td className='fs-11'>Total</td>
                                        <td className='fs-11'>Just.</td>
                                    </tr>
                                </thead><tbody>
                                    {assistance.map((std, i) =>
                                        studentRowAssist(std, i)
                                    )}
                                </tbody>
                            </table>

                        </div>
                    }
                </Card>
            </div>

            {showTakeAssistance ? <Modal size={'xl'} show={showTakeAssistance} onHide={handleCloseModalTakeAssistance} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Tomar asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'white' }}>
                    <Row>
                        <Col md={6} className='mb-2' >
                            <div className='align-center-vertically w-100'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    selected={selectedDateDay}
                                    onChange={handleChangeDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Fecha"
                                    className='dropdown-toggle'
                                    locale='es' maxDate={moment().toDate()} />
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <p className='fs-11 mb-0 mt-2'>{assistanceValuesText}</p>
                    </Row>
                    {isTableTakeAssistanceLoaded ?
                        <Row className='mt-2'>
                            {students.map((s, i) => (
                                <Row className='mb-1 pb-1 pt-1' style={{ background: '#F0F0F0', borderRadius: 8, alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <Col className='fitContentWidth pe-0 ps-0'>
                                        <div className='studentImg circle-container ' style={{ width: '50px', height: '50px', borderColor: 'white', background: 'white' }}>
                                            {s.avatarFile ? <img src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                        </div>
                                    </Col>
                                    <Col className='d-none d-lg-block pe-0' >
                                        <div className='align-center-vertically' style={{ justifyContent: 'space-between' }}>


                                            <p className='fs-14' style={{ margin: '0px' }}>{s.surname ? s.surname.toUpperCase() : ''}, {s.name}</p>
                                            <div className='align-center-vertically'>
                                                {(valueFaultsCheckedButtons[s.id] > 0 && valueFaultsCheckedButtons[s.id] < 1) || valueCheckedButtons[s.id] == "TC" || valueCheckedButtons[s.id] == "TCJ" || valueCheckedButtons[s.id] == "RC" || valueCheckedButtons[s.id] == "RCJ" ? <div className='d-none d-lg-flex fitContentWidth endRow pe-0 ps-0'>
                                                    <div className='align-center-vertically w-100'>
                                                        <FontAwesomeIcon icon={faClock} className='dropDownIcon me-1' style={{ height: 16 }} />
                                                        <DatePicker
                                                            selected={selectedTime ? selectedTime[s.id] : ''}
                                                            onChange={(d) => handleChangeTime(d, s.id)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={10}
                                                            placeholderText="Hora"
                                                            className='wi-70'
                                                            dateFormat="HH:mm"
                                                            style={{ borderRadius: '0.25rem' }}
                                                            locale='es' />
                                                    </div>
                                                </div> : ''}
                                                {valueJustifiedCheckedButtons[s.id] == true && <div className='d-none d-lg-flex fitContentWidth endRow pe-0 ps-1'>
                                                    <div className='align-center-vertically '>
                                                        <FontAwesomeIcon icon={faFileArrowUp} className='dropDownIcon me-1' style={{ height: 16 }} />
                                                        {/* <Form.Control size="sm" className='textInputGray' onChange={(event) => handleFile(event)} type="file" /> */}
                                                        {file[s.id] ? <div className='userSelectedModal fitContentWidth' style={{ display: 'inline-block', overflow: 'hidden' }}>
                                                            Certificado
                                                            <FontAwesomeIcon onClick={() => deleteFile(s.id)} icon={faTimes} className='remiderCross ms-2' style={{ cursor: "pointer" }} />
                                                        </div> :
                                                            <><Button variant='secondary' onClick={() => document.querySelector(`#input-field-${s.id}`).click()}>Subir archivo</Button>
                                                                <input type="file" class="form-control-file" id={`input-field-${s.id}`} hidden onChange={(e) => handleFile(e, s.id)} /></>
                                                        }
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </Col>

                                    {assistanceValues.length <= 9 ?
                                        <>
                                            <Col className='bigButtonsContainer endRow pe-0 d-none d-lg-flex'>
                                                {assistanceValues.map((av, j) => (
                                                    <div>
                                                        {av.shortName == 'P' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'greenBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>P</ToggleButton> : ''}
                                                        {av.shortName != 'P' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>{av.shortName}</ToggleButton> : ''}
                                                    </div>
                                                ))}
                                            </Col>
                                        </>
                                        :
                                        <>

                                            <Col className='bigButtonsContainer endRow pe-0 d-none d-lg-flex fitContentWidth'>
                                                {assistanceValues.map((av, j) => (
                                                    <div>
                                                        {av.shortName == 'P' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'greenBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>P</ToggleButton> : ''}
                                                        {av.shortName == 'A' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>A</ToggleButton> : ''}
                                                        {av.shortName == 'T' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>T</ToggleButton> : ''}
                                                        {av.shortName == 'R' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>R</ToggleButton> : ''}
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col className='fitContentWidth ps-0 d-none d-lg-block pe-0' style={{ minWidht: '312px !important' }}>
                                                {assistanceValues.map((av, j) => (
                                                    <span>
                                                        {(av.shortName != "P" && av.shortName != "A" && av.shortName != "R" && av.shortName != "T") ?
                                                            <React.Fragment key={i + j}>
                                                                <ToggleButton
                                                                    size='sm'
                                                                    className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack assistanceButton smallButton' : 'assistGrey assistanceButton smallButton'}
                                                                    id={`check-${av.shortName}-${s.id}`}
                                                                    type="checkbox"
                                                                    variant="light"
                                                                    onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>
                                                                    {av.shortName}
                                                                </ToggleButton>
                                                                {j == (Math.ceil(assistanceValues.length - 3) / 2) + 3 /* 5 + 3 */ ? <br /> : ''}
                                                            </React.Fragment>
                                                            : ''}
                                                    </span>
                                                ))}
                                            </Col>
                                        </>
                                    }


                                    <Col className='d-block d-lg-none'>
                                        <p className='fs-12' style={{ margin: '0px' }}>{s.surname ? s.surname.toUpperCase() : ''} <br /> {s.name}</p>
                                    </Col>
                                    <Col className='d-block d-lg-none pe-0 ps-0 fitContentWidth' >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div className='fitContentWidth pe-0 ps-0'>
                                                <Dropdown className='dropdownButton'>
                                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-12 p-0" style={{ width: '70px', overflow: 'hidden' }}>
                                                        <div style={{ width: '100%', height: '100%', backgroundColor: 'red' }} className={valueCheckedButtons[s.id] == 'P' ? 'greenBack p-2' : 'redBack p-2'}>
                                                            {valueCheckedButtons[s.id] ? valueCheckedButtons[s.id] : '--'}
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="fs-14" title={valueCheckedButtons[s.id] ? valueCheckedButtons[s.id] : '--'}>
                                                        {assistanceValues.map((av, j) => (
                                                            <Dropdown.Item key={j} onClick={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>{av.shortName}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Row>
                        :
                        <Row className='mt-5 mb-5'>
                            <div className="justify-content-center">
                                <Loading animation={'border'} classExtra='loading-100' />
                            </div>
                        </Row>

                    }
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalTakeAssistance} disabled={isPostReady != -1}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postAssistance} disabled={!isTableTakeAssistanceLoaded || isPostReady != -1}>{isPostReady == -1 || isPostReady == true ? <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> : <div className="justify-content-center" style={{ width: '85px' }}>
                        <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                    </div>}</Button>
                </Modal.Footer>
            </Modal > : ''}

            <Modal show={showAssistanceDetail} onHide={handleCloseModalAssistanceDetail} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Detalle de asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'white' }}>
                    {studentAssistanceDetailModal != null && <><InfoField title='Estudiante' extraClass='mb-2' content={`${studentAssistanceDetailModal.student.surname.toUpperCase()}, ${studentAssistanceDetailModal.student.name}`} />
                        <InfoField title='Fecha' extraClass='mb-2' content={`${studentAssistanceDetailModal.date.day}/${selectedDateNumber < 10 ? `0${selectedDateNumber}` : selectedDateNumber} `} />
                        <InfoField title='Asistencia' extraClass='mb-2' content={studentAssistanceDetailModal.AssistanceName} />
                        {studentAssistanceDetailModal.date.hour != null && <InfoField title='Hora' content={moment(formatHour(studentAssistanceDetailModal.date.hour)).format("HH:mm")} extraClass='mb-2' />}
                        {studentAssistanceDetailModal.date.file != null && <InfoField title='Certificado' content={<><Button variant="secondary" onClick={() => downloadFile(studentAssistanceDetailModal.date.file.id)} className='fs-14'><FontAwesomeIcon className='mr-10' icon={faFileArrowDown} />Descargar</Button></>} />}
                    </>}
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalAssistanceDetail}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Assistance