import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faCheck, faCheckSquare, faClipboardUser, faEllipsisV, faEnvelope, faEye, faFileExport, faGraduationCap, faHeading, faIdCard, faImage, faPen, faPlus, faPrint, faSortNumericUp, faTimes, faTrash, faUser, faUserFriends, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { faLetterboxd } from '@fortawesome/free-brands-svg-icons'
import { GET } from '../../../controllers/endpoints.controller'
import Loading from '../../general-components/Loading'
import moment from 'moment/moment'

function Teachers() {
    const PAGE_TITLE = "Profesores";
    const PAGE_ICON = faUsers;
    const [myRole, setMyRole] = useState("")


    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        moment.locale('es');
        setMyRole(getUserRole());
        getUsers();
    }, []);

    //DropDown Course
    let optionsCourse = ['1°A', '1°B', '2°A', '2°B', '3°A', '3°B', '4°A', '4°B', '5°A', '5°B'];
    const [selectedCourseOption, setSelectedCourseOption] = useState('Curso');

    const handleSelect = (eventKey) => {
        setSelectedCourseOption(optionsCourse[eventKey]);
    };

    //Dropdown level
    const [optionsLevel, setOptionsLevel] = useState([
        { name: 'Jardin', id: 3 },
        { name: 'Primaria', id: 1 },
        { name: 'Secundaria', id: 2 }
    ]);

    const [selectedLevelOption, setSelectedLevelOption] = useState('');

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey);
    };

    //Modal
    const [showNewUser, setShowNewUser] = useState(false);

    const handleShowNewUser = () => {
        setSelectedEnableOption('')
        setSelectedRoleOption('');
        setShowNewUser(true)
    };

    const handleCloseModalNewUser = () => {
        setShowNewUser(false);
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [deleteUserName, setDeleteUserName] = useState("");

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, name) => {
        setDeleteUserId(id);
        setDeleteUserName(name);
        setShowConfirmDeleteModal(true);
    };

    const roles = [
        { name: 'Preceptor', id: 1 },
        { name: 'Profesor', id: 2 },
        { name: 'Alumno', id: 3 },
    ];

    const [selectedRoleOption, setSelectedRoleOption] = useState('');
    const handleSelectRole = (eventKey) => {
        setSelectedRoleOption(eventKey);
    };

    const enableOptions = [{ name: 'SI', value: true }, { name: 'NO', value: false }]
    const [selectedEnableOption, setSelectedEnableOption] = useState('');
    const handleSelectEnable = (eventKey) => {
        setSelectedEnableOption(eventKey);
    };

    const [users, setUsers] = useState([]);
    const [isTableLoaded, setIsTableLoaded] = useState(false);

    const getUsers = async function () {
        const req = await GET(`/classes/getTeachersScheduleByLevelId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setUsers(res);
            setIsTableLoaded(true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const rolesCanSeeTeacherDetail = ["Preceptor Secundaria", "Secretaría Secundaria", "Rectorado Secundaria", "Apoderado Legal - Secundaria", ]

    return (
        <div className="app teachersPage">
            <Navbar />
            <div className="content">
            <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>
                
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <div className=' pb-4'>
                    {!isTableLoaded ? <div className='justify-content-center'>
                        <Loading animation={'border'} />
                    </div>
                        :
                        <>
                            <div>
                                <Row>
                                    {users.map((u, i) => (
                                        <Col md={4} className='mb-2' key={i}>
                                            <Card className=' mobile-cards cards mt-2' style={{ height: "100%" }}>
                                                <Link className='link-no-deco' to={rolesCanSeeTeacherDetail.find(r=>r==myRole) ? `/profesor/?id=${u.User.id}` : null}><div style={{ textAlign: 'center' }}>
                                                    <img className="avatar-70" src={u.User.avatarFile} />
                                                    <p className='fitContentWidth roundedText-v2 borderDiv fs-14 mt-2' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                        {u.User.surname ? u.User.surname.toUpperCase() : ''}, {u.User.name}
                                                    </p>
                                                </div></Link>
                                                <div>
                                                    {u.ClassTimes &&
                                                        <>
                                                            {u.ClassTimes.map((ct, j) => (
                                                                <Row className='pt-2 pb-2 mt-1' style={{ background: '#F0F0F0', borderRadius: 8 }} key={j}>
                                                                    <Col>
                                                                        {moment().day(ct.day).format('dddd').charAt(0).toUpperCase() + moment().day(ct.day).format('dddd').slice(1).toLowerCase()}
                                                                    </Col>
                                                                    <Col style={{ textAlign: 'right' }}>
                                                                        {`${(ct.start + "").slice(0, -2).padStart(2, '0')}:${(ct.start + "").slice(-2)}`} a {`${(ct.end + "").slice(0, -2).padStart(2, '0')}:${(ct.end + "").slice(-2)}`}
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </>
                                                    }
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </>}
                </div>
            </div>

            <Modal size='lg' show={showNewUser} onHide={handleCloseModalNewUser} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faUser} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <Row>
                        <Col md={6}>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" id="textNewUserName" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Apellido" id="textNewUserLastName" />
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faEnvelope} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Email" id="textNewUserEmail" />
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faImage} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Foto del usuario" id="textNewUserAvatar" type='file' />
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedRoleOption !== '' ? roles[selectedRoleOption].name : 'Rol'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedRoleOption !== '' ? roles[selectedRoleOption].name : 'Rol'}>
                                        {roles.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectRole(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faIdCard} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="DNI" id="textNewUserDni" />
                            </div>
                        </Col>
                        <Col md={6} className='mt-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCheckSquare} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedEnableOption !== '' ? enableOptions[selectedEnableOption].name : 'Habilitado'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedEnableOption !== '' ? enableOptions[selectedEnableOption].name : 'Habilitado'}>
                                        {enableOptions.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectEnable(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        {/*                         <Col md={12} className='mt-1 mb-2'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="" id="checkNewUserShowReport" />
                            </div>
                        </Col> */}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewUser}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' ><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faUser} style={{ minWidht: '20px' }} /><Modal.Title>Deshabilitar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas deshabilitar el usuario "{deleteUserName}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2'><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Teachers