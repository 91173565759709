import React, { Fragment, useEffect, useState } from 'react'
import { Col, Dropdown, Row, Card, Modal, ToggleButton, Badge, Button, Spinner } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { faClipboardUser, faChalkboardTeacher, faPen, faCaretUp, faCaretDown, faCalendar, faCheck, faArrowLeft, faPlus, faTimes, faFileCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { useLocation } from "react-router-dom";
import { GET, POST } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import { getMonthsActualYear } from '../../../tools/tools';
import NotFound from '../../general-components/NotFound';
registerLocale('es', es)


function AssistanceClass() {
    const PAGE_TITLE = "Asistencia - Vista materia";
    const PAGE_ICON = faClipboardUser;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    const months = getMonthsActualYear(false);

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;

        setupMonth(moment().daysInMonth());
        setSelectedDateOption(months[moment().month()].name);
        setSelectedDateNumber(months[moment().month()].num);
        getAssistanceValues();
        getClassrooms(getParams());
    }, []);

    const [classrooms, setClassrooms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [assistanceValuesText, setAssistanceValuesText] = useState("");
    const [assistanceValues, setAssistanceValues] = useState([]);
    const [students, setStudents] = useState([]);
    const [assistance, setAssistance] = useState([]);
    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [isTableTakeAssistanceLoaded, setIsTableTakeAssistanceLoaded] = useState(false);

    useEffect(() => {
        if (students.length > 0) {
            setDefaultAssistanceValues();
        }
    }, [students]);

    const [numberDay, setNumberDay] = useState([]);

    const setupMonth = (qty) => {
        const days = [];

        for (let i = 1; i <= qty; i++) {
            days.push(i);
        }

        setNumberDay(days);
    }

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(-1);

    const handleSelect = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getClassesByClassroomId(classrooms[eventKey].id);
        setSelectedClassOption("Materia");
        setSelectedClassId(null);
        setIsTableLoaded(false);
    };

    const [selectedClassOption, setSelectedClassOption] = useState('Materia');
    const [selectedClassId, setSelectedClassId] = useState(null);

    const handleSelectClass = (eventKey) => {
        setSelectedClassOption(classes[eventKey].name);
        setSelectedClassId(classes[eventKey].id);
        getAssistance(selectedClassroomId, classes[eventKey].id, selectedDateNumber);
    };

    const [selectedDateOption, setSelectedDateOption] = useState('Fecha');
    const [selectedDateNumber, setSelectedDateNumber] = useState(null);

    const handleSelectDate = (i) => {
        setIsTableLoaded(false);
        setSelectedDateOption(months[i].name);
        setSelectedDateNumber(months[i].num);
        setupMonth(months[i].qtyDays);
        getAssistance(selectedClassroomId, selectedClassId, months[i].num);
    };

    const dataCard = [{
        title: 'Asistencia del año',
        value: '89',
        subtitle: '',
        compare: '-'
    }, {
        title: 'Faltas justificadas',
        value: '58',
        subtitle: '',
        compare: '-'
    },
    {
        title: 'Asistencia del mes',
        value: '91',
        subtitle: '',
        compare: '+'
    }, {
        title: 'Asistencia del día',
        value: '98',
        subtitle: '',
        compare: '+'
    }]

    const stadisticCard = (title, value, subtitle, compare, i) =>
        <Col md={3} key={i} className='ps-0'>
            <Card className='staticCards cards'>
                <p className='m-0'>{title}</p>
                <div className='wrapCont flexEnd'>
                    <p className='m-0 fw-700 fs-38'>{value}%</p>
                    {compare == '+' ? <FontAwesomeIcon className='arrowStatics' style={{ color: '#73D64D' }} icon={faCaretUp} /> : <FontAwesomeIcon className='arrowStatics' style={{ color: '#EA4335' }} icon={faCaretDown} />}
                    <span className='fs-8' style={{ padding: '0px 5px 10px' }}>{subtitle}</span>
                </div>
            </Card>
        </Col>

    const [showTakeAssistance, setShowTakeAssistance] = useState(false);

    const handleShowTakeAssistance = () => {
        getClassroomStudents(selectedClassroomId, selectedClassId, true, moment(selectedDateDay).format('YYYY-MM-DD'));
        setIsPostReady(-1);
        setShowTakeAssistance(true)
    };

    const handleCloseModalTakeAssistance = () => {
        setShowTakeAssistance(false);
    }

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                    getClassesByClassroomId(res[0].id)
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getClassesByClassroomId = async function (cid) {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classes/byTeacherId&ClassroomId/${cid}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClasses(res);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classes/byClassroomId/${cid}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClasses(res);
                //getAssistance(res[0].id, moment().month() + 1)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getClassroomStudents = async function (classroomId, classId, avatars, date) {
        setIsTableTakeAssistanceLoaded(false);
        console.log(`/assistance/courseByClassIdAndDate/${classId}/${date}/${avatars}`)
        const req = await GET(`/assistance/courseByClassIdAndDate/${classId}/${date}/${avatars}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res);
            setIsTableTakeAssistanceLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAssistance = async function (classroomId, classId, month) {
        setIsTableLoaded(false);

        const req = await GET(`/assistance/classByClassroomIdClassIdAndMonth/${classroomId}/${classId}/${month}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log("Assistance: ")
            console.log(res)
            setAssistance(res)
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAssistanceValues = async function () {
        const req = await GET(`/assistance/values/byLevelId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log("Asisstance values")
            console.log(res)
            let txt = "";
            for (let i = 0; i < res.length; i++) {
                if (i == (res.length - 1)) {
                    txt += `${res[i].shortName}: ${res[i].name}`
                } else {
                    txt += `${res[i].shortName}: ${res[i].name} | `
                }
            }
            txt += " | F: Feriado";
            setAssistanceValuesText(txt);
            setAssistanceValues(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isPostReady, setIsPostReady] = useState(-1);
    const postAssistance = async function () {
        let body = [];
        setIsPostReady(false);

        for (let s of students) {
            body.push({
                classId: selectedClassId,
                userId: s.id,
                valueId: getChosenAssistanceValue(s.id),
                date: moment(selectedDateDay).format('YYYY-MM-DD'),
                classroomId: selectedClassroomId,
            })
        }
        console.log(body)
        const req = await POST(`/assistance`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            setIsPostReady(true);
            handleCloseModalTakeAssistance();
            getAssistance(selectedClassroomId, selectedClassId, selectedDateNumber);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getChosenAssistanceValue = (userId) => {
        let value = null;
        let i = 0;
        let cb = checkedButtons[userId];

        while (value == null && i < assistanceValues.length) {
            if (cb[`check-${assistanceValues[i].shortName}-${userId}`]) {
                value = assistanceValues[i].id;
            }

            i++;
        }

        return value;
    }

    const [checkedButtons, setCheckedButtons] = useState({});
    const [valueCheckedButtons, setvalueCheckedButtons] = useState({});

    const setDefaultAssistanceValues = () => {
        const initialStates = {};
        const initialValues = {};

        for (let s of students) {
            initialStates[s.id] = {
                [`check-${s.Assistance.value == "ND" ? "P" : s.Assistance.value}-${s.id}`]: true
            };
            initialValues[s.id] = s.Assistance.value == "ND" ? "P" : s.Assistance.value;
        }

        setCheckedButtons(initialStates);
        setvalueCheckedButtons(initialValues);
    };

    const handleToggleChange = (buttonId, ind) => {
        setCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: {
                //[buttonId]: !prevState[ind]?.[buttonId],
                [buttonId]: true
            }
        }));
        let parts = buttonId.split("-")
        let leter = parts[1]
        setvalueCheckedButtons((prevState) => ({
            ...prevState,
            [ind]: leter,
        }));
    };

    const [selectedDateDay, setSelectedDateDay] = useState(new Date());

    const handleChangeDate = date => {
        setSelectedDateDay(date);
        getClassroomStudents(selectedClassroomId, selectedClassId, true, moment(date).format('YYYY-MM-DD'));
    };

    const studentRowAssist = (sa, i) => {

        let stdDays = []

        for (let d of sa.dates) {
            stdDays.push(
                <td className='pb-0 pt-1 ps-0 pe-0'>
                    <Row className='tableRows'>
                        <Col className='align-center-vertically-v2 ps-1 pe-1'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Badge bg='' className='assistanceBadge fs-14' style={{ height: 36, width: 36, background: `${d.color}`, color: `${d.color != '#FFFFFF' ? 'white' : 'black'}` }}>{d.shortName != 'ND' ? d.shortName : '-'}</Badge>
                            </div>
                        </Col>
                    </Row>
                </td>
            )
        }

        return (<tr key={i} className=''>
            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                    <Col className='align-center-vertically-v2'>
                        <div style={{ textAlign: 'center', width: 20 }}>
                            {i + 1}
                        </div>
                    </Col>
                </Row>
            </td>
            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2'>
                        <div style={{ textAlign: 'left', width: 250 }} className='d-none d-lg-block'>
                            {sa.surname ? sa.surname.toUpperCase() : ''}, {sa.name}
                        </div>
                        <div style={{ textAlign: 'left', width: 100 }} className='d-block d-lg-none fs-12'>
                            {sa.surname ? sa.surname.toUpperCase() : ''}, {sa.name[0]}.
                        </div>
                    </Col>
                </Row>
            </td>

            {stdDays.map((s) => (s))}

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats.assist.total}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.unjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.noComputable : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalJustified : ''}</Badge>
                            {/*                             <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{studentAssistance.stats.justifyFaults.yearTotal[i]}</Badge> */}
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalUnjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

        </tr>)
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
            <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>
                
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className=''>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='d-none d-lg-block dropdownButton'>
                                <Dropdown.Toggle className=" dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className='d-block d-lg-none dropdownButton'>
                                <Dropdown.Toggle className="d-block d-lg-none dropdownFilter txt-left fs-14 wi-250">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <div className='d-block d-lg-none mb-2'></div>

                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250">
                                    {selectedClassOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassOption}>
                                    {classes.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClass(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <div className='d-block d-lg-none mb-2'></div>

                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton d-none d-lg-block'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedDateOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedDateOption}>
                                    {months.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectDate(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className='dropdownButton d-block d-lg-none'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250">
                                    {selectedDateOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedDateOption}>
                                    {months.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectDate(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>

                    <div className='d-block d-lg-none mb-3'></div>

                    {/*                     id: {selectedClassroomId} */}
                    <Col className='d-none d-lg-block'>
                       <div className='endRow'>
                            <Button variant='primary' disabled={selectedClassroomId == null || selectedClassId == null} className='fs-14' onClick={handleShowTakeAssistance}><FontAwesomeIcon className='mr-10' icon={faPen} />Tomar asistencia</Button>
                        </div> 
                    </Col>
                    <Col className='d-block d-lg-none'>
                    <Button variant='primary' disabled={selectedClassroomId == null || selectedClassId == null} className='fs-14 totalWidth' onClick={handleShowTakeAssistance}><FontAwesomeIcon className='mr-10' icon={faPen} />Tomar asistencia</Button>
</Col>
                </Row>

                {/* <Row className='mt-3'>
                    {dataCard.map((ind, i) =>
                        stadisticCard(ind.title, ind.value, ind.subtitle, ind.compare, i)
                    )}
                </Row>*/}
                <Row>
                    <p className='fs-11 mb-0 mt-3'>{assistanceValuesText}</p>

                </Row>

                <Card className='mobile-cards cards mt-2 h-100-3'>
                    {!isTableLoaded ?
                        <div className='justify-content-center'>
                            {selectedClassroomId != null && selectedClassId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un curso, una materia y un mes' />
                            }
                        </div> :
                        <div className='slideTable fs-14 ' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className=' headerContainer'>
                                        <td className='sticky-column' rowSpan="2">#</td>
                                        <td className='sticky-column' rowSpan="2">Estudiante</td>
                                        {
                                            numberDay.map((num, i) =>
                                                <td key={i} rowSpan="2">{num}</td>
                                            )
                                        }

                                        <td colSpan={4}>Este mes</td>
                                        <td colSpan={2}>Anteriores</td>
                                        <td rowSpan="2" className='fs-11'>Total</td>
                                        <td rowSpan="2" className='fs-11'>Total just.</td>
                                        <td rowSpan="2" className='fs-11'>Total injust.</td>
                                    </tr>
                                    <tr className='headerContainer'>
                                        <td className='fs-11'>Asisten.</td>
                                        <td className='fs-11'>Just.</td>
                                        <td className='fs-11'>Injust.</td>
                                        <td className='fs-11'>N/C</td>
                                        <td className='fs-11'>Total</td>
                                        <td className='fs-11'>Just.</td>
                                    </tr>
                                </thead><tbody>
                                    {assistance.map((std, i) =>
                                        studentRowAssist(std, i)
                                    )}
                                </tbody>
                            </table>

                        </div>
                    }
                </Card>
            </div>

            {showTakeAssistance ? <Modal size={'xl'} show={showTakeAssistance} onHide={handleCloseModalTakeAssistance} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Tomar asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'white' }}>
                    <Row>
                        <Col md={6} className='mb-2' >
                            <div className='align-center-vertically w-100'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    selected={selectedDateDay}
                                    onChange={handleChangeDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Fecha"
                                    className='dropdown-toggle'
                                    locale='es' maxDate={moment().toDate()} />
                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <p className='fs-11 mb-0 mt-2'>{assistanceValuesText}</p>
                    </Row>
                    {isTableTakeAssistanceLoaded ?
                        <Row className='mt-2'>
                            {students.map((s, i) => (
                                <Row className='mb-1 p-1' style={{ background: '#F0F0F0', borderRadius: 8, alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <Col className='fitContentWidth pe-0 ps-0'>
                                        <div className='studentImg circle-container ' style={{ width: '50px', height: '50px', borderColor: 'white', background: 'white' }}>
                                            {s.avatarFile ? <img src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                        </div>
                                    </Col>
                                    <Col className='d-none d-lg-block' md={4}>
                                        <p className='fs-14' style={{ margin: '0px' }}>{s.surname ? s.surname.toUpperCase() : ''}, {s.name}</p>
                                    </Col>

                                    {assistanceValues.length <= 8 ?
                                        <>
                                            <Col className='bigButtonsContainer endRow pe-0 d-none d-lg-flex'>
                                                {assistanceValues.map((av, j) => (
                                                    <div>
                                                        {av.shortName == 'P' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'greenBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>P</ToggleButton> : ''}
                                                        {av.shortName != 'P' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>{av.shortName}</ToggleButton> : ''}
                                                    </div>
                                                ))}
                                            </Col>
                                        </>
                                        :
                                        <>

                                            <Col className='bigButtonsContainer endRow pe-0 d-none d-lg-flex'>
                                                {assistanceValues.map((av, j) => (
                                                    <div>
                                                        {av.shortName == 'P' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'greenBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>P</ToggleButton> : ''}
                                                        {av.shortName == 'A' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>A</ToggleButton> : ''}
                                                        {av.shortName == 'T' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>T</ToggleButton> : ''}
                                                        {av.shortName == 'R' ? <ToggleButton size='lg' className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack bigAssitButton assistanceButton' : 'assistGrey bigAssitButton assistanceButton'} id={`check-${av.shortName}-${s.id}`} type="checkbox" variant="light" onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>R</ToggleButton> : ''}
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col className='fitContentWidth ps-0 d-none d-lg-block'>
                                                {assistanceValues.map((av, j) => (
                                                    <span>
                                                        {(av.shortName != "P" && av.shortName != "A" && av.shortName != "R" && av.shortName != "T") ?
                                                            <React.Fragment key={i}>
                                                                <ToggleButton
                                                                    size='sm'
                                                                    className={checkedButtons[s.id]?.[`check-${av.shortName}-${s.id}`] ? 'redBack assistanceButton smallButton' : 'assistGrey assistanceButton smallButton'}
                                                                    id={`check-${av.shortName}-${s.id}`}
                                                                    type="checkbox"
                                                                    variant="light"
                                                                    onChange={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>
                                                                    {av.shortName}
                                                                </ToggleButton>
                                                                {j === 5 + 3 ? <br /> : ''}
                                                            </React.Fragment>
                                                            : ''}
                                                    </span>
                                                ))}
                                            </Col>
                                        </>
                                    }



                                    <Col className='d-block d-lg-none'>
                                        <p className='fs-12' style={{ margin: '0px' }}>{s.surname ? s.surname.toUpperCase() : ''} <br /> {s.name}</p>
                                    </Col>

                                    <Col className='d-block d-lg-none pe-0 ps-0 fitContentWidth' >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div className='fitContentWidth pe-0 ps-0'>
                                                <Dropdown className='dropdownButton'>
                                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-12 p-0" style={{ width: '70px', overflow: 'hidden' }}>
                                                        <div style={{ width: '100%', height: '100%', backgroundColor: 'red' }} className={valueCheckedButtons[s.id] == 'P' ? 'greenBack p-2' : 'redBack p-2'}>
                                                            {valueCheckedButtons[s.id] ? valueCheckedButtons[s.id] : '--'}
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="fs-14" title={valueCheckedButtons[s.id] ? valueCheckedButtons[s.id] : '--'}>
                                                        {assistanceValues.map((av, j) => (
                                                            <Dropdown.Item key={j} onClick={() => handleToggleChange(`check-${av.shortName}-${s.id}`, s.id)}>{av.shortName}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            ))}

                        </Row>
                        :
                        <Row className='mt-5 mb-5'>
                            <div className="justify-content-center">
                                <Loading animation={'border'} classExtra='loading-100' />
                            </div>
                        </Row>

                    }
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalTakeAssistance} disabled={isPostReady!=-1}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postAssistance} disabled={!isTableTakeAssistanceLoaded || isPostReady!=-1}>{isPostReady==-1 || isPostReady==true? <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> : <div className="justify-content-center" style={{width:'85px'}}>
                        <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                    </div>}</Button>
                </Modal.Footer>
            </Modal> : ''}
        </div>
    )
}

export default AssistanceClass