import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function Terms() {
    return (
        <div className=' content ms-0 ps-5 pe-5 pt-1'>
            <div style={{ width: '100%' }}>
                <div className='mt-4'>
                    <img src="/logo_color.svg" width={80} alt="Logo"></img>
                </div>
            </div>
            <div style={{ textAlign: 'center' }} className='fw-700 fs-24 mt-4 mb-4'>Términos y condiciones</div>
            <Container>
                <Row>
                    <Col md={12}>
                        <Card className='cards'>
                        <p>
        1. Introducción.
    </p>
    <p>
        1.1. Descripción general de Tasky.
    </p>
    <p>
        Tasky, una solución tecnológica privada y de código no accesible al público, desarrollado por Secdevs para el
        intercambio de información en el ámbito educativo. Permite a padres, docentes, directivos y staff de la
        institución educativa interactuar en el seguimiento de los alumnos a través de la web. Asimismo, facilita y
        agiliza la comunicación de la comunidad educativa mediante la difusión de noticias, eventos, calendarios,
        notificaciones, fechas de exámenes, boletines y planes de estudio, entre otras funciones.
    </p>
    <p>
        Tasky opera tanto en plataformas web como móviles, sincronizando continuamente su base de datos de manera
        automática, manteniendo los datos de padres, alumnos y staff de la institución educativa, así como diversa
        información académica (notas, asistencias, tareas para los alumnos, programas de estudio, etc.).
    </p>
    <p>
        Los padres, estudiantes, docentes, directivos y personal pueden acceder a Tasky desde cualquier dispositivo a
        través de la web y mediante aplicaciones para Android y iOS.
    </p>
    <p>
        1.2. Secciones y funciones de Tasky.
    </p>
    <p>
        La plataforma Tasky contiene una serie de secciones y funciones diseñadas para permitir distintos tipos de
        interacción entre padres, alumnos, docentes y staff de la institución educativa, cada una de las cuales responde
        a un tipo específico de necesidad vinculada al desarrollo de las actividades educativas y administrativas de la
        institución educativa. La habilitación de estas secciones y funciones para su acceso y utilización por los
        distintos usuarios es una facultad ejercida de forma discrecional por cada institución educativa en función de
        su política académica y necesidades administrativas y curriculares. La gestión de las mismas es responsabilidad
        de la institución académica, a través de las personas designadas a tal efecto. Secdevs no es responsable por el
        contenido, veracidad o exactitud de las publicaciones o comunicaciones efectuadas a través de las distintas
        secciones o funciones, ni ejerce su control, verificación o moderación, limitándose a poner a disposición de la
        institución educativa estas herramientas en los términos pactados en el respectivo contrato de provisión del
        servicio.
    </p>
    <p>
        Entre los módulos actualmente disponibles en Tasky cabe mencionar a las siguientes:
    </p>
    <p>
    <ul>
        <li>Autenticación</li>
        <li>Comunicaciones</li>
        <li>Calificaciones</li>
        <li>Asistencia</li>
        <li>Planificación</li>
        <li>Notificaciones</li>
        <li>Alumnos</li>
        <li>Historial académico</li>
        <li>Planes de estudio</li>
        <li>Cursos</li>
        <li>Calendario</li>
        <li>Seguridad</li>
        <li>Reportes</li>
        <li>Sanciones</li>
        <li>Documentación oficial</li>
        <li>Aula virtual</li>
        <li>Encuestas</li>
        <li>Pedagogía</li>
        <li>Personal docente</li>
        <li>Administración</li>
        <li>Google</li>
        <li>SAP</li>
        <li>Reservas</li>
        <li>Biblioteca</li>
        <li>Archivos</li>
        <li>Inscripciones</li>
        <li>Padres</li>
        <li>Secretaría</li>
    </ul>
    </p>
    <p>
        Asimismo, Tasky permite a la institución educativa remitir a los usuarios notificaciones de tipo vinculante,
        cuya aceptación tiene plenos efectos legales en los términos del artículo 284 del Código Civil y Comercial de la
        Nación.
    </p>
    <p>
        Secdevs puede modificar o suprimir estas funciones o módulos, o incorporar otras nuevas, sin necesidad de previo
        aviso y sin que ello genere derecho a reclamo alguno a favor del usuario.
    </p>
    <p>
        Finalmente, cabe destacar que Tasky es una plataforma exclusivamente pensada para su utilización en el ámbito
        educativo, con fines didácticos, administrativos y de comunicación entre los miembros de la comunidad educativa.
        Tasky no es un sitio de intercambio de archivos, un tracker o una red 2p2 (peer to peer), quedando obsolutamente
        prohibida su utilización a tales fines.
    </p>
    <p>
        2. Definiciones.
    </p>
    <p>
        A continuación se definen algunos de los términos utilizados en forma recurrente en los presentes Términos y
        Condiciones Generales:
    </p>
    <p>
        • Secdevs: “Secdevs” es la denominación comercial con que opera CERFOGLIO SANTIAGO EDUARDO, persona física de la
        República Argentina que ha desarrollado la plataforma Tasky. Es titular de las marcas Secdevs, Tasky, de los
        dominios de internet “tasky.com.ar”, “tasky.ar” y “tasky.digital” y de la propiedad intelectual vinculada a los
        programas informáticos identificados con los mismos (código fuente, estructura y organización, entre otros
        elementos que conforman el software), así como de sus distintas funciones, modificaciones y actualizaciones en
        los términos de las leyes Nros. 22.362 y 11.723 y normativa complementaria.
    </p>
    <p>
        • Institución educativa: Es la institución o red educativa de nivel inicial, primario y/o secundario que
        contrata con Secdevs la provisión de la plataforma Tasky, siendo la responsable de la carga y administración de
        los datos personales y académicos en los referidos sistemas, la habilitación y gestión de usuarios, y la
        administración de notificaciones y demás contenidos inherentes a la comunicación con la comunidad educativa.
    </p>
    <p>
        • Usuario alumno: Es el usuario destinado a su utilización por parte de los alumnos de la institución educativa.
        Sólo puede visualizar la información referida a su persona y el contenido académico publicado por la institución
        educativa. No está autorizado a agregar, modificar o borrar sus datos personales, médicos o académicos.
    </p>
    <p>
        • Usuario padre: Es el usuario destinado a su utilización por parte de los padres, tutores o guardadores de los
        alumnos. Sólo puede visualizar la información personal, médica y académica de los alumnos que tuviera vinculados
        a su usuario, así como su propia información personal y las notificaciones y contenido académico publicados por
        la institución educativa. Puede agregar, modificar o borrar sus propios datos personales y los de los usuarios
        alumnos que tuviera vinculados a su usuario. No puede acceder a información de ningún otro usuario que no tenga
        vinculado a su usuario.
    </p>
    <p>
        • Usuario profesor: Es el usuario destinado a su utilización por parte de los docentes de la institución
        educativa. Sólo tiene acceso a la información académica vinculada a su función en la institución educativa.
        Puede cargar contenido didáctico en la Sección Clases. No puede visualizar, agregar, modificar o borrar la
        información personal o médica de los alumnos, como así tampoco la información personal del usuario padre.
    </p>
    <p>
        • Usuario secretario: Es el usuario destinado a su utilización por parte de los secretarios académicos de la
        institución educativa. Tiene acceso completo a la información personal, médica y académica de los usuarios
        alumnos, padres y profesores, pudiendo agregarla, modificarla y borrarla, de acuerdo las competencias que le
        hubieran sido asignadas por la institución educativa. Administra el módulo de Comunicaciones y Calificaciones.
        Posee la facultad de dar el alta, modificar o
    </p>
    <p>
        dar de baja los usuarios padre, alumno y profesor.
    </p>
    <p>
        • Usuario directivo: Es el usuario destinado a su utilización por parte de las autoridades de la institución
        educativa. Puede visualizar toda la información publicada por la institución educativa, así como la información
        de los usuarios alumno, padre, profesor y secretario, pero no puede agregar, modificar o borrar la información,
        ni proceder al alta, modificación o baja de usuarios.
    </p>
    <p>
        • Administrador: Es la persona designada por la institución educativa para la administración y soporte técnico
        primario de la plataforma Tasky. Tiene acceso a toda la información incorporada al sistema por la institución
        educativa, a los posteos y contenido publicados en las distintas secciones del sistema, pudiendo agregar,
        modificar y borrar datos.
    </p>
    <p>
        Asimismo, puede proceder al alta, modificación y baja de usuarios.
    </p>
    <p>
        • Credenciales de acceso: Comprende al nombre de usuario y contraseña, así como a cualquier otro método de
        autentificación habilitado por Secdevs en el futuro, requeridos para acceder a la plataforma Tasky. A su vez, se
        puede iniciar sesión con la cuenta de Google institucional, aunque Tasky no se hace cargo del alta, baja o
        modificación de tales cuentas.
    </p>
    <p>
        • Archivos: Comprende a todo archivo electrónico generado o incorporado a Tasky por un usuario para su
        almacenamiento o para ser compartido con otros usuarios. Puede contener imágenes, videos, ilustraciones,
        diseños, texto, sonidos, música, programas ejecutables, formularios, datos o cualquier otra información que sea
        técnicamente admitida por el sistema.
    </p>
    <p>
        3. Alcance de la licencia de uso.
    </p>
    <p>
        3.1. La aceptación de los Términos y Condiciones Generales del servicio habilita al usuario a utilizar la
        plataforma Tasky a los fines de visualizar, modificar y borrar los datos obrantes en el sistema e interactuar
        con el resto de la comunidad educativa, todo ello de acuerdo a las autorizaciones correspondientes a su perfil
        de usuario, según lo definido en el punto 2.
    </p>
    <p>
        3.2. El uso de la plataforma es estrictamente personal y se encuentra restringido a la interacción entre los
        miembros de la comunidad educativa por cuestiones estrictamente educativas, a la gestión académica de la
        institución educativa y a la comunicación de ésta última con padres y alumnos. Se encuentra expresamente
        prohibido todo uso comercial o que tuviera por finalidad la obtención de un provecho pecuniario para el propio
        usuario o para un tercero.
    </p>
    <p>
        3.3. Las credenciales de acceso son únicas para cada usuario, no pudiendo ser compartidas ni transferidas a
        otras personas bajo ninguna modalidad. El usuario debe utilizar su propio nombre de usuario y contraseña, o
        cuenta de Google Workspace, de conformidad con las recomendaciones de seguridad establecidas por Secdevs, y
        deberá adoptar las precauciones necesarias para evitar que otras personas tomen conocimiento de sus credenciales
        de acceso. La utilización de las credenciales de acceso correspondientes a un determinado usuario harán a su
        titular legalmente responsable por todo uso no autorizado de Tasky, así como por cualquier daño causado a
        Secdevs, a la institución educativa o a terceros producido a partir del ingreso al sistema con dichas
        credenciales de acceso, no pudiendo excusarse en su uso no autorizado o no consentido por parte de terceros.
    </p>
    <p>
        3.4. Las cuentas de usuario son únicas por cada persona autorizada a la utilización de Tasky. La institución
        educativa no podrá generar múltiples usuarios para una misma persona. En caso que Secdevs tome conocimiento de
        que una persona posee múltiples usuarios en Tasky, podrá proceder a la baja de dichos usuarios sin necesidad de
        aviso o intimación previa.
    </p>
    <p>
        4. Prohibiciones.
    </p>
    <p>
        Se consideran usos prohibidos de Tasky, cuya violación resulta susceptible de generar responsabilidad civil,
        administrativa o penal, según corresponda, al usuario que incurriera en ellos, a los siguientes:
    </p>
    <p>
        4.1. En materia marcaria y de propiedad intelectual.
    </p>
    <p>
        4.1.1. Se encuentra prohibido el uso, reproducción o transferencia a título oneroso o gratuito, totales o
        parciales, de las marcas “Secdevs”, “Tasky” y de los dominios de internet “tasky.com.ar”, “tasky.ar” y
        “tasky.digital”, así como de las imágenes, dibujos, monogramas, combinaciones de colores, diseños y todo otro
        signo que los identifique. Tales usos prohibidos, además, pueden constituir un delito de acuerdo a lo previsto
        en el artículo 31 de la ley Nº 22.362.
    </p>
    <p>
        4.1.2. Se encuentra prohibida la copia, distribución, publicación, descompilación, modificación, o traducción
        del software de titularidad de Secdevs bajo cualquier modalidad, en particular la utilización de técnicas de
        ingeniería inversa o cualquier otra forma de acceso al código fuente de Tasky u otros programas informáticos de
        propiedad de Secdevs para su modificación o la creación de variantes o softwares derivados, ya sea con fines
        comerciales o no. Asimismo, se encuentra prohibido vender, licenciar, publicar, transferir, alquilar,
        distribuir, dar en garantía o disponer de cualquier forma el software de Tasky o cualquier otro programa
        informático de titularidad de Secdevs, ya sea en la Argentina o en el exterior del país, por medios físicos o
        electrónicos, sea a título oneroso o gratuito. Tales usos prohibidos, además, pueden constituir un delito de
        acuerdo a lo previsto en los artículos 71 y 72 de la ley Nº 11.723.
    </p>
    <p>
        4.1.3. Se encuentra prohibido el uso no autorizado de marcas o propiedad intelectual de terceros en Tasky. Se
        entenderá por tal, entre otros supuestos, a la publicación o difusión en cualquier sección de Tasky de
        fotografías, imágenes, sonidos, música, textos, diseños, animaciones o cualquier otra obra objeto de propiedad
        intelectual en violación a los derechos de su autor o titular, así como al uso de marcas ajenas con fines
        comerciales o en perjuicio de los derechos de su titular. Se encuentran excluidos de esta prohibición el uso de
        marcas o propiedad intelectual de terceros con fines exclusivamente didácticos o educativos en las condiciones
        establecidas por la legislación vigente.
    </p>
    <p>
        4.2. En materia de protección de la intimidad y otros derechos personalísimos.
    </p>
    <p>
        4.2.1. Se encuentra prohibido todo uso de Tasky que implique una lesión a la intimidad, la honra, la imagen, la
        identidad o la dignidad de las personas. Se entenderá por tales a la creación de cuentas de usuario falsas
        utilizando el nombre de otras personas; la difusión no autorizada de la imagen o la voz de una persona; la
        publicación, difusión o cesión no autorizada de datos personales; la publicación de mensajes que inciten a la
        violencia, al odio o a la discriminación por razones religiosas, raciales, étnicas, de nacionalidad,
        ideológicas, vinculadas a la opinión política o gremial, de género, sexo u orientación sexual, posición
        económica, condición social, caracteres físicos o cualquier otro motivo; la publicación o difusión de mensajes
        con contenido agraviante u ofensivo; la publicación o difusión de material con contenido obseno; el envío de
        mensajes, publicaciones u otros comportamientos con un propósito de acoso, bullying o grooming, entre otros
        supuestos. Asimismo, Secdevs presume que la institución educativa cuenta con las autorizaciones necesarias para
        utilizar la imagen, voz y nombre de alumnos, padres, docentes y otras personas en la difusión de actividades
        vinculadas al quehacer de la comunidad educativa, siendo de exclusiva responsabilidad de la institución
        educativa recabar el respectivo consentimiento de sus titulares en caso de resultar ello legalmente necesario.
        Por otro lado, se podrá revisar el contenido subido a la plataforma con inteligencia artificial con el fin de
        que no se vulneren los derechos personales de cada usuario, siendo este un método no 100% efectivo.
    </p>
    <p>
        4.2.2. Se encuentra prohibida la publicación o difusión de contenido que pudiera implicar un menoscabo de la
        imagen o reputación comercial de Secdevs, sus directivos, empleados o personal contratado.
    </p>
    <p>
        4.2.3. Se encuentra prohibida la publicación o difusión de contenido en violación al secreto profesional,
        estadístico, fiscal, bancario o en contravención a cualquier otra norma legal o contractual por la cual el
        usuario debiera guardar el secreto de determinada información.
    </p>
    <p>
        4.3. En materia de contenido o comportamientos dañosos.
    </p>
    <p>
        4.3.1. Se encuentra prohibida la publicación, difusión o distribución en Tasky de todo contenido o material
        susceptible de generar un daño personal o patrimonial a otros usuarios, a la institución educativa o a terceros
        lo que incluye, pero no se limita, a la distribución o puesta en circulación de virus, ransomware, adware,
        troyanos, spyware o cualquier otra clase de malware o programa informático malicioso o dañino. Asimismo, el
        usuario deberá abstenerse de publicar, distribuir o hacer circular comunicaciones de tipo masivo y no solicitado
        o spam de cualquier tipo.
    </p>
    <p>
        4.3.2. Se encuentra prohibida la utilización de Tasky con fines comerciales o con el propósito de obtener un
        provecho pecuniario para el usuario o para un tercero lo que incluye, pero no se limita, a la promoción o
        publicidad de cualquier tipo de bienes y/o servicios ofrecidos por el usuario o por terceros. Esta prohibición
        no alcanza la publicidad o difusión de cualquier tipo que pudiera realizar la institución educativa de sus
        propios servicios o actividades.
    </p>
    <p>
        4.3.3. Se encuentra prohibida la utilización de Tasky para cualquier finalidad fraudulenta, extorsiva o que
        implique una violación del orden legal vigente lo que incluye, pero no se limita, al envío de mensajes con el
        propósito de obtener en forma no autorizada datos personales o información patrimonial, laboral, relativa a la
        salud, a las creencias religiosas, opiniones políticas u otra de cualquier tipo que pudiera considerarse
        sensible o de carácter reservado, sea de un usuario de terceros; la publicación o difusión de mensajes con
        contenido amenazante; el ofrecimiento de drogas, armas, explosivos u otros bienes o servicios de carácter
        ilícito; la promoción o apología del terrorismo y, en general, cualquier otro acto con contenido ilícito,
        constituya o no delito de conformidad con las leyes de la República Argentina.
    </p>
    <p>
        4.4. Responsabilidad por usos prohibidos.
    </p>
    <p>
        En relación a los puntos anteriores de la presente sección se deja constancia que, toda vez que la gestión del
        contenido publicado en Tasky y de las comunicaciones generadas en dicho sistema corresponde a la institución
        educativa que contrata el servicio, Secdevs no ejerce un control o monitoreo del contenido de las publicaciones
        o comunicaciones de la institución educativa o de los usuarios, por lo que no asume responsabilidad alguna por
        los daños producidos a través de los mismos, la cual recae en el usuario que hubiera incurrido en un uso
        prohibido de la plataforma. Sin perjuicio de ello, Secdevs se reserva la facultad de adoptar las medidas
        necesarias para hacer cesar inmediatamente las conductas descriptas en esta sección e iniciar las acciones
        legales que correspondieran contra el usuario que hubiera incurrido en ellas, cuando hubiera tomado conocimiento
        de las mismas a través de la denuncia de un usuario o de un tercero.
    </p>
    <p>
        5. Enlaces y archivos adjuntos.
    </p>
    <p>
        La utilización de las distintas funciones y secciones de Tasky puede implicar que los usuarios incorporen a la
        plataforma enlaces a otros sitios o a contenido perteneciente a terceras personas o la carga de archivos
        adjuntos de distinto tipo. A continuación se desarrollarán algunas pautas y recomendaciones para el correcto uso
        de este tipo de elementos.
    </p>
    <p>
        5.1. Enlaces: Un enlace o link es un elemento en un documento, mensaje o página web que hace referencia a otro
        recurso, por ejemplo un archivo o página web. Los enlaces son un mero vínculo que direcciona al usuario desde un
        contenido hacia otro, pero no constituye una reproducción del contenido enlazado.
    </p>
    <p>
        Los usuarios pueden agregar enlaces a otros archivos, páginas web o posiciones determinadas en una página web en
        las publicaciones o archivos que incorporen a las distintas secciones de Tasky, pero en la medida en que los
        elementos enlazados sean ajenos a Tasky, Secdevs no se hace legalmente responsable por el contenido de dichos
        enlaces, toda vez que no es titular del mismo ni ejerce el control o moderación de las publicaciones y/o
        contenido agregado por los usuarios.
    </p>
    <p>
        Si bien la incorporación de un enlace no implica en principio una vulneración a los derechos de autor o de
        imagen del titular de la obra o contenido enlazados, en la medida en que constituye una mera referencia a la
        dirección URL donde dichos recursos se encuentran alojados y no una copia o reproducción de tales contenidos, a
        los efectos de evitar consecuencias legales sugerimos que el usuario, con carácter previo a la publicación del
        enlace, verifique que el contenido enlazado haya sido lícitamente publicado por su autor o titular o con la
        autorización de éste. Del mismo modo, los usuarios deberán verificar el carácter lícito de todo el contenido o
        recursos enlazados en los archivos o publicaciones que incorporen a Tasky, debiéndose abstener de la
        incorporación de los respectivos enlaces en caso de duda respecto de la licitud del contenido o recursos
        enlazados.
    </p>
    <p>
        5.2. Archivos adjuntos: Tasky permite que los usuarios almacenen o adjunten a sus publicaciones o mensajes
        distintos tipos de archivos con contenido producido por el propio usuario o por terceros. Secdevs no controla ni
        monitorea el contenido de estos archivos, por lo que el usuario que los incorpore a la plataforma asume la
        responsabilidad por dicho contenido y garantiza que el mismo es de su autoría o cuenta con la autorización de su
        autor o titular para difusión a terceros, cuando dicha autorización resulte legalmente exigible. La
        incorporación de archivos a Tasky implica por parte del usuario que la lleva a cabo la autorización para que los
        usuarios a los que está dirigido su contenido o se encuentran habilitados a acceder a la sección donde se
        encuentran publicados o almacenados, puedan proceder a su visualización o reproducción. Secdevs garantiza que
        estos archivos no estarán disponibles para su visualización, reproducción o descarga por parte de personas
        ajenas a la comunidad educativa de la institución educativa que contrata sus servicios, o por parte de usuarios
        no autorizados a acceder a los mismos.
    </p>
    <p>
        6. Uso de datos personales en Tasky.
    </p>
    <p>
        El funcionamiento de la plataforma Tasky implica la recopilación de ciertos datos personales de los usuarios
        tales como nombre, domicilios, edad, antecedentes médicos, información vinculada al desempeño académico, etc.
        Esta información es recabada por la institución educativa en el marco de su actividad administrativa y académica
        o, en ciertos casos, suministrada por los propios usuarios al completar su perfil en Tasky. Secdevs almacena
        estos datos en servidores propios o externos y brinda a la institución educativa las herramientas informáticas
        para su tratamiento automatizado, encontrándose inscripta como responsable de las respectivas bases de datos
        ante la Dirección Nacional de Protección de Datos Personales. Sin embargo, Secdevs no carga, recolecta, analiza,
        verifica, modifica ni elimina en modo alguno los datos personales de los usuarios, siendo tales tareas de
        responsabilidad exclusiva de la institución educativa. Los datos almacenados en Tasky no son públicos y sólo
        pueden ser visualizados o modificados por los usuarios que cuenten con determinados permisos establecidos según
        la naturaleza de los datos de que se trate, tal como fuera definido en el punto 2. No obstante ello, toda vez
        que, entre otras funciones, Tasky permite a los usuarios determinadas interacciones siguiendo un esquema de red
        social, al hacer entrega de sus datos el usuario consiente que algunos de ellos, tales como nombre, género,
        edad, ciudad o barrio de residencia, así como la fotografía del usuario, puedan ser visualizados por los
        restantes usuarios que forman parte de una misma comunidad educativa. Tasky no permite que esos datos sean
        visualizados por usuarios pertenecientes a otras comunidades educativas ni por terceros, ni tampoco que se hagan
        públicos datos que puedan ser considerados de carácter sensible, los cuales son únicamente accesibles por el
        usuario titular de los mismos o por las autoridades o personal de la institución educativa en la medida en que
        resulten necesarios para el cumplimiento de sus funciones.
    </p>
    <p>
        6.1. Consentimiento para la recolección y almacenamiento de datos personales.
    </p>
    <p>
        Al ingresar a Tasky, el usuario consiente que la institución educativa recabe ciertos datos personales
        necesarios para su gestión académica y administrativa. El usuario, asimismo, consiente que Secdevs almacene esos
        datos y proceda a su tratamiento automatizado exclusivamente para el cumplimiento de las funciones determinadas
        por la institución educativa. En caso de que el usuario no desee que datos de carácter no sensible tales como
        nombre, género, edad, ciudad o barrio de residencia, fotografía o nacionalidad sean visualizados por otros
        usuarios en el marco de las funciones de Tasky que permiten la interacción de usuarios bajo un esquema de red
        social, deberá solicitar a la institución educativa que no sean publicados en la plataforma y que sean
        reservados sólo para las funciones académicas y administrativas de la institución educativa.
    </p>
    <p>
        6.2. Consentimiento para la transferencia internacional de datos personales. El almacenamiento de los datos
        personales cargados en Tasky puede efectuarse en servidores pertenecientes a Secdevs o en servidores
        pertenecientes a otros proveedores subcontratados por Secdevs. En este último caso, la información podría ser
        almacenada en servidores ubicados en otros países, por lo que al ingresar a Tasky el usuario expresamente
        consiente la transferencia internacional de los datos suministrados a la institución educativa y cargados en la
        plataforma, en los términos del artículo 12 del decreto Nº 1558/2001 reglamentario de la ley Nº 25.326. En todos
        los casos, Secdevs garantiza que los datos serán almacenados en jurisdicciones que poseen un adecuado nivel de
        protección legal de su privacidad e integridad.
    </p>
    <p>
        6.3. Medidas de seguridad de la información. La protección de los datos personales de los usuarios es una
        prioridad para Secdevs. En la recolección, tratamiento y almacenamiento de datos personales Secdevs instrumenta
        todas las medidas de seguridad, técnicas y organizativas, necesarias para garantizar su seguridad y
        confidencialidad, evitando su adulteración, pérdida, consulta, transmisión o tratamiento no autorizados, así
        como para detectar y solucionar desviaciones de información de cualquier tipo. Estas medidas de seguridad son
        objeto de evaluación y perfeccionamiento permanente, de acuerdo a la evolución de los conocimientos y
        tecnologías disponibles, y se adecuan a los estándares establecidos por la ley Nº 25.326, su decreto
        reglamentario y la normativa complementaria emitida por la Agencia de Acceso a la Información Pública. Sin
        embargo, dado que no es posible garantizar la absoluta infalibilidad de las medidas de seguridad adoptadas,
        Secdevs advierte que los usuarios deberán tener en consideración tal circunstancia y que no se responsabiliza
        por violaciones a la confidencialidad y/o integridad de los datos personales y de las comunicaciones de los
        usuarios que no hayan podido razonablemente ser evitadas con los medios técnicos y humanos actualmente
        disponibles en el mercado. En todos los casos Secdevs se compromete a adoptar todas las medidas a su alcance
        para restablecer inmediatamente niveles adecuados de seguridad en sus sistemas en caso de detectar que está
        siendo víctima de un ciberataque o de una filtración de datos, y a poner en conocimiento de los usuarios y de
        las autoridades dicha situación a los efectos de la identificación y sanción de los sujetos responsables.
    </p>
    <p>
        6.4. Habeas data. Los usuarios y toda persona en general tienen derecho a obtener información sobre el tipo de
        datos personales de su titularidad existentes en bases de datos públicas o privadas y la finalidad con la que
        fueron recabados, así como a exigir su rectificación, actualización o supresión cuando los datos fueran
        inexactos, hubieran sido obtenidos sin su consentimiento o se estuvieran utilizando con una finalidad distinta a
        aquella para la cual prestaron su consentimiento. Sin embargo, toda vez que es la institución educativa que
        contrata el servicio la responsable de recabar, cargar, modificar y suprimir los datos personales de los
        usuarios, sin que Secdevs supervise o controle dichos procesos, toda solicitud de información, rectificación o
        supresión de datos personales deberá dirigirse directamente a la institución educativa, que deberá dar respuesta
        a la misma en los plazos establecidos en los artículos 14 y 16 de la ley 25.326.
    </p>
    <p>
        6.5. Eliminación de datos. Secdevs es un mero depositario de los datos personales, publicaciones, archivos y
        todo otro contenido incorporado a Tasky por la institución educativa y los usuarios que forman parte de su
        comunidad educativa. Por tal motivo, una vez concluida la relación contractual entre Secdevs y la institución
        educativa que contrata sus servicios, se procederá a la inmediata eliminación de los servidores de la empresa, o
        de los servidores externos que hubiera subcontratado, de toda la información de cualquier tipo cargada en Tasky
        por la institución educativa y los usuarios pertenecientes a su comunidad educativa.
    </p>
    <p>
        6.6. Uso de cookies en Tasky. Se denomina cookie a un archivo enviado por un sitio web y almacenado en el equipo
        del usuario con el objeto de recopilar cierta información respecto de la actividad del navegador. Tasky utiliza
        cookies con el objeto de identificar las páginas que son visitadas y su frecuencia, a los fines de efectuar
        análisis estadísticos y mejorar la experiencia del usuario. La información recabada a través de cookies es de
        carácter anónimo y no permite acceder a datos personales del usuario ni otorga acceso remoto a su equipo. Las
        cookies pueden ser eliminadas en cualquier momento por el propio usuario a través de su navegador web. Asimismo,
        si bien la mayoría de los navegadores web admiten por defecto el uso de cookies, es posible configurarlos para
        que rechacen las cookies salvo autorización expresa del usuario. En caso de configurar su navegador web para que
        rechace el uso de cookies, es probable que algunas secciones o funciones de Tasky no funcionen adecuadamente,
        sin que ello genere derecho alguno a favor del usuario para efectuar reclamos a Secdevs.
    </p>
    <p>
        7. Facultades reservadas.
    </p>
    <p>
        7.1. Incumplimiento de los Términos y Condiciones Generales del servicio. Secdevs no interviene en la gestión de
        datos personales de los usuarios en Tasky, ni en la publicación, moderación o control de los contenidos
        incorporados por los usuarios en las distintas secciones de la plataforma. Sin embargo, Secdevs se reserva
        ciertas facultades necesarias para evitar la producción de daños a la propia empresa, a la institución
        educativa, a los usuarios o a terceros, o la comisión de actos ilícitos mediante la utilización de Tasky. En
        particular, cuando Secdevs tomara conocimiento por intermedio de la institución educativa, o mediante la
        denuncia de un usuario o de un tercero, que un usuario infringe los presentes Términos y Condiciones Generales o
        que ha llevado a cabo cualquier acto que, aun no encontrándose expresamente previsto, causa o puede causar una
        lesión a los derechos de Secdevs o de terceros o constituir una infracción a la normativa legal vigente, podrá
        proceder al bloqueo del acceso al servicio por parte de dicho usuario o a la cancelación de su cuenta,
        restringir su acceso a determinadas funciones o secciones de la plataforma, eliminar o editar el contenido en
        infracción, como así también formular las denuncias o dar inicio a las acciones legales que estimare
        pertinentes. Estas acciones podrán ser adoptadas discrecionalmente por Secdevs, sin necesidad de notificación o
        intimación previa al usuario infractor.
    </p>
    <p>
        7.2. Modificaciones a Tasky. En su carácter de titular de los derechos de propiedad intelectual y marcaria
        inherentes a Tasky, Secdevs se reserva el derecho de introducir todos los cambios y modificaciones al software o
        al diseño de la plataforma que estime convenientes para la mejor prestación del servicio, pudiendo en
        consecuencia agregar, modificar, sustituir o eliminar cualquiera de sus secciones o servicios. Sin perjuicio de
        las condiciones pactadas con la institución educativa en el respectivo contrato de prestación de servicios,
        estas modificaciones no darán lugar a reclamo alguno por parte de los usuarios, ni deberán serles notificadas
        previamente, no obstante que Secdevs pueda informarlas oportunamente mediante la publicación de mensajes en
        Tasky o por medio de correos electrónicos dirigidos a la dirección informada por cada usuario.
    </p>
    <p>
        7.3. Modificaciones a los Términos y Condiciones Generales. Secdevs podrá en cualquier momento modificar en todo
        o en parte los presentes Términos y Condiciones Generales del servicio y/o la Política de Privacidad de Datos,
        sin que ello de lugar a reclamo alguno por parte de los usuarios, ni origine derecho a compensación o
        indemnización de ningún tipo a su favor. Las modificaciones a los Términos y Condiciones Generales del servicio
        y/o a la Política de Privacidad de Datos serán notificadas al usuario mediante la publicación de un mensaje al
        iniciar su sesión en Tasky, ocasión en la que el usuario deberá prestar su conformidad a los cambios
        introducidos. Asimismo, Secdevs podrá informar de las modificaciones a los Términos y Condiciones Generales del
        servicio y/o a la Política de Privacidad de Datos mediante el envío de un correo de cortesía a la dirección de
        correo electrónico informada por el usuario. En caso de disconformidad con las modificaciones a los Términos y
        Condiciones Generales del servicio y/o a la Política de Privacidad de datos, el usuario deberá abandonar la
        plataforma Tasky inmediatamente. La utilización bajo cualquier modalidad de Tasky, o la mera permanencia en el
        sitio, serán entendidos como una manifestación de voluntad positiva respecto de la plena aceptación de todas y
        cada una de las modificaciones introducidas a los Términos y Condiciones Generales del servicio y/o a la
        Política de Privacidad de Datos.
    </p>
    <p>
        8. Otros compromisos del usuario.
    </p>
    <p>
        8.1. El usuario es responsable de la conexión a internet necesaria para el acceso y uso de Tasky. Secdevs no se
        hace responsable por cortes del servicio, lentitud u otras deficiencias derivadas de problemas en la conexión a
        internet.
    </p>
    <p>
        8.2. El usuario se compromete a informar a la institución educativa de cualquier infracción a los presentes
        Términos y Condiciones Generales del servicio de la que tomara conocimiento, así como de cualquier problema de
        funcionamiento de Tasky, acceso no autorizado al sistema u otras circunstancias que pudieran poner en riesgo la
        seguridad de la plataforma o resultar potencialmente dañosas para otros usuarios, para la institución educativa,
        para Secdevs o para terceros. Asimismo, el usuario podrá denunciar estas circunstancias a Secdevs mediante un
        correo electrónico dirigido a denuncias@secdevs.com.ar.
    </p>
    <p>
        8.3. El usuario brindará a Secdevs toda la colaboración necesaria para la investigación de las violaciones a los
        Términos y Condiciones Generales del servicio, problemas técnicos, intrusiones al sistema y, en general, de
        todos aquellos acontecimientos que él hubiera denunciado.
    </p>
    <p>
        8.4. El usuario se compromete a mantener patrimonialmente indemne a Secdevs, sus directivos y personal, respecto
        de cualquier reclamo o demanda formulado por otros usuarios, por la institución educativa o por terceros,
        derivado de daños provocados por su actividad en Tasky, aun cuando esta no contraviniera expresamente los
        presentes Términos y Condiciones Generales del servicio. De conformidad con lo establecido en el punto 3.3, será
        atribuible al usuario toda actividad realizada mediante el uso de sus credenciales de acceso personales. La
        responsabilidad del usuario de mantener patrimonialmente indemne a Secdevs, sus directivos y personal comprende,
        pero no se limita, a reclamos o demandas vinculadas a la violación a derechos de propiedad intelectual o
        marcaria; la afectación al honor, la privacidad o la imagen de otros usuarios o de terceros; la revelación de
        secretos; la intrusión en comunicaciones ajenas; los daños a la propiedad derivada del uso no autorizado de
        Tasky, la difusión de software malicioso o la realización de conductas fraudulentas; y en general, de toda
        conducta, sea o no intencional, realizada con motivo u ocasión del uso de la plataforma Tasky que sea
        susceptible de generar un daño a otros usuarios, a la institución educativa, a Secdevs o a terceros.
    </p>
    <p>
        9. Limitación de responsabilidad.
    </p>
    <p>
        Secdevs no asume responsabilidad alguna por los daños que el usuario pudiera sufrir como consecuencia del uso
        inadecuado de Tasky. En caso de dudas sobre el adecuado uso del servicio, el usuario deberá efectuar la
        pertinente consulta a la institución educativa a través de los medios habilitados al efecto.
    </p>
    <p>
        Secdevs no asume responsabilidad alguna frente al usuario por daños derivados de caso fortuito, fuerza mayor,
        disposición de las autoridades o hechos de terceros que no pudieron ser razonablemente previstos o evitados
        adoptando las medidas de seguridad disponibles en el mercado acordes a los riesgos y características propias de
        la actividad que desarrolla Secdevs.
    </p>
    <p>
        La exclusión de responsabilidad por hechos de terceros alcanza también a daños provocados por virus
        informáticos, ataques automatizados, bots o cualquier otro supuesto de daños causados sin intervención humana
        que no pueda haber sido razonablemente previsto o evitado con las medidas de seguridad disponibles en el mercado
        acordes a los riesgos y características propias de la actividad que desarrolla Secdevs.
    </p>
    <p>
        Secdevs no asume responsabilidad alguna frente al usuario por el contenido de las publicaciones realizadas por
        la institución educativa u otros usuarios en Tasky, ni por el contenido de mensajes, archivos adjuntos, enlaces
        u otras actividades atribuibles a éstos que resultan ajenas al control, moderación o supervisión de Secdevs.
    </p>
    <p>
        10. Denuncias.
    </p>
    <p>
        Se podrán cursar denuncias relativas al incumplimiento de los Términos y Condiciones Generales del Servicio o a
        cualquier hecho o circunstancia vinculada al uso de Tasky que pudiera representar una violación a la normativa
        legal vigente o un riesgo para los usuarios, la institución educativa, Secdevs o terceros mediante un correo
        electrónico dirigido a denuncias@secdevs.com.ar, indicando en el asunto el motivo de la denuncia.
    </p>
    <p>
        11. Independencia de las disposiciones de los Términos y Condiciones Generales.
    </p>
    <p>
        Si alguna de las disposiciones de los presentes Términos y Condiciones Generales fuera declarada legalmente
        inválida, ilícita o inexigible en alguna jurisdicción, ello no afectará la validez o vigencia de sus restantes
        disposiciones en esa jurisdicción, ni la validez y vigencia de la totalidad de las disposiciones de estos
        Términos y Condiciones Generales en otras jurisdicciones.
    </p>
    <p>
        12. Ejercicio de derechos por parte de Secdevs.
    </p>
    <p>
        La falta de ejercicio por parte de Secdevs de alguno de los derechos o facultades previstos a su favor en los
        presentes Términos y Condiciones no podrá ser entendida en ningún caso como una renuncia tácita a ejercer esos
        derechos o facultades en el futuro.
    </p>
    <p>
        13. Notificaciones y comunicaciones.
    </p>
    <p>
        Se considerarán válidas y eficaces las comunicaciones cursadas por Secdevs a los usuarios a la dirección de
        correo electrónico oportunamente informada en sus respectivos perfiles, así como aquellas practicadas mediante
        avisos o mensajes incorporados en Tasky o que se envíen al módulo Comunicaciones y tengan por finalidad informar
        cuestiones vinculadas a la prestación del servicio o su uso por parte del usuario.
    </p>
    <p>
        14. Ley aplicable y competencia.
    </p>
    <p>
        Toda controversia entre los usuarios y Secdevs vinculada al funcionamiento de Tasky o a la prestación de
        servicios conexos se resolverá atendiendo en primer lugar a los presentes Términos y Condiciones y,
        supletoriamente, por lo dispuesto en el Código Civil y Comercial de la Nación y demás leyes y normativa legal
        vigente en el territorio de la República Argentina.
    </p>
    <p>
        El usuario acepta en todos los casos someter la resolución de la controversia, reclamo o disputa a la
        jurisdicción de la Justicia Nacional en lo Civil con asiento en la Ciudad Autónoma de Buenos Aires, renunciando
        a cualquier otro fuero o jurisdicción que pudiera resultar competente.
    </p>
    <p>
        A todos los efectos legales, Secdevs constituye su domicilio en la calle Paraná 439, piso 2, oficina 10, código
        postal 1017, de la Ciudad Autónoma de Buenos Aires, República Argentina.
    </p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Terms