import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faCross, faEllipsisV, faFolderOpen, faGraduationCap, faPen, faPenClip, faPenFancy, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST } from '../../../controllers/endpoints.controller'
import Moment from 'react-moment'

function Holidays() {
    const PAGE_TITLE = "Feriados";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getHolidays();
        getSchoolYears();
    }, []);

    const [selectedDateDay, setSelectedDateDay] = useState(new Date());

    const handleChangeDate = date => {
        setSelectedDateDay(date);
    };

    //Modal
    const [showModalNewFrame, setShowModalNewFrame] = useState(false);

    const handleShowModalNewFrame = () => {
        setShowModalNewFrame(true)
        getLevels();
    };

    const handleCloseModalNewFrame = () => {
        setShowModalNewFrame(false);
    }

    const [showLocalHolidaysModal, setShowLocalHolidaysModal] = useState(false);

    const handleShowLocalHolidaysModal = () => {
        setShowLocalHolidaysModal(true)
        getLevels();
    };

    const handleCloseLocalHolidaysModal = () => {
        setShowLocalHolidaysModal(false);
    }

    const [selectedSchoolYearOption, setSelectedSchoolYearOption] = useState('Ciclo lectivo');
    const [selectedSchoolYearId, setSelectedSchoolYearId] = useState(null);

    const handleSelectSchoolYear = (eventKey) => {
        setSelectedSchoolYearOption(schoolYears[eventKey].year);
        setSelectedSchoolYearId(schoolYears[eventKey].id);
    };


    //DropDown Nivel
    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [selectedLevelOptionId, setSelectedLevelOptionId] = useState(null);
    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(levels[eventKey].name);
        setSelectedLevelOptionId(levels[eventKey].id);
    };

    const [levels, setLevels] = useState([]);
    const [schoolYears, setSchoolYears] = useState([]);
    const [holidays, setHolidays] = useState([]);

    const getHolidays = async function () {
        const req = await GET(`/assistance/holidays/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setHolidays(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getSchoolYears = async function () {
        const req = await GET(`/classes/schoolYears`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchoolYears(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postHoliday = async function () {
        const body = {
            levelId: selectedLevelOptionId,
            name: document.getElementById('textNewHolidayName').value,
            date: selectedDateDay
        }

        const req = await POST(`/assistance/holidays`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewFrame();
            getHolidays();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postLocalHolidays = async function () {
        const body = {
            levelId: selectedLevelOptionId,
            year: selectedSchoolYearOption
        }

        const req = await POST(`/assistance/holidays/argentina`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseLocalHolidaysModal();
            getHolidays();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Feriados locales'} func={handleShowLocalHolidaysModal} classExtra='fs-14' />
                        <ActionButton iconFa={faPlus} title={'Nuevo período'} func={handleShowModalNewFrame} classExtra='fs-14 ms-2' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Fecha
                                </div>
                            </Col>
                            <Col md={9} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'left' }}>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {holidays.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-2 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            <Moment date={r.date} format='DD/MM/YYYY' />
                                        </div>
                                    </Col>
                                    <Col md={9} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'left' }}>
                                            {r.name}
                                        </div>
                                    </Col>

                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='lg' show={showModalNewFrame} onHide={handleCloseModalNewFrame} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faCalendar} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo feriado</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Row>
                        <Col md={6} className='p-0 mb-2'>
                            <div className='align-center-vertically w-100'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    selected={selectedDateDay}
                                    onChange={handleChangeDate}
                                    dateFormat="dd/MM/YYYY"
                                    placeholderText="Fecha"
                                    className='dropdown-toggle'
                                    locale='es' />
                            </div>
                        </Col>
                        <Col md={6} className='p-0 align-center-vertically mb-2'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col md={6} className='p-0'>
                            <div className=' align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" id="textNewHolidayName" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewFrame}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="primary" className='fs-14' onClick={postHoliday}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={showLocalHolidaysModal} onHide={handleCloseLocalHolidaysModal} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faCalendar} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo feriado</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Row>
                        <Col md={12} className='p-0 align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedSchoolYearOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedSchoolYearOption}>
                                    {schoolYears.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectSchoolYear(i)}>{opt.year}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={12} className='mt-2 p-0 align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseLocalHolidaysModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="primary" className='fs-14' onClick={postLocalHolidays}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Holidays