import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faFile, faHeading, faPen, faPlus, faSortNumericAsc, faSortNumericDesc, faSortNumericUp, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST } from '../../../controllers/endpoints.controller';

function GradesConfig() {
    const PAGE_TITLE = "Calificaciones";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getEvaluativeGrades();
    }, []);


    const [grades, setGrades] = useState([]);

    //Modal
    const [showNewGrade, setShowNewGrade] = useState(false);

    const handleShowNewGrade = () => {
        setShowNewGrade(true)
        getLevels();
    };

    const handleCloseModalNewGrade = () => {
        setShowNewGrade(false);
    }

    const [levels, setLevels] = useState([]);
    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [newGradeLevelId, setNewGradeLevelId] = useState(null);

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey.name);
        setNewGradeLevelId(eventKey.id);
    };

    const getEvaluativeGrades = async function () {
        const req = await GET(`/grades/evaluativeGrades`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let result = [];
            let shows = [];
            let visibility = "";

            for (let eg of res) {
                shows = [];
                visibility = "";

                if (eg.showNodal == true) { shows.push("Nodales") }
                if (eg.showGrade == true) { shows.push("Evaluaciones") }
                if (eg.showReport == true) { shows.push("Boletín") }

                for (let i = 0; i < shows.length; i++) {
                    visibility += `${shows[i]}${i == shows.length - 1 ? "" : " | "}`;
                }

                eg.visibility = visibility;

                result.push(eg);
            }

            setGrades(result);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postGrade = async function () {
        const body = {
            name: document.getElementById('textNewGradeName').value,
            value: document.getElementById('textNewGradeValue').value,
            approved: document.getElementById('checkNewGradeApprobed').checked,
            equivalent: document.getElementById('textNewGradeEquivalent').value != "" ? parseFloat(document.getElementById('textNewGradeEquivalent').value) : 0,
            showNodal: document.getElementById('checkNewGradeShowNodals').checked,
            showGrade: document.getElementById('checkNewGradeShowGrade').checked,
            showReport: document.getElementById('checkNewGradeShowReport').checked,
            levelId: newGradeLevelId
        }

        const req = await POST(`/grades/evaluativeGrade`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewGrade();
            getEvaluativeGrades();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nueva calificación'} func={handleShowNewGrade} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Abreviatura
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Equivalencia
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Visibilidad
                                </div>
                            </Col>
                            <Col md={4} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {grades.map((r, i) => (
                        <div>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            <p className='m-0'>{r.name}</p>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            {r.value}
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            {r.equivalent}
                                        </div>
                                    </Col>

                                    <Col md={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            {r.visibility}
                                        </div>
                                    </Col>

                                    <Col md={4} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='lg' show={showNewGrade} onHide={handleCloseModalNewGrade} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Nueva calificación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}  className='mb-2'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" id="textNewGradeName" />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faHeading} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Abreviatura" id="textNewGradeValue" />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faSortNumericUp} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Equivalencia numérica" id="textNewGradeEquivalent" />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2  align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={12} className='mb-1'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="Aprobado" id="checkNewGradeApprobed" />
                            </div>
                        </Col>
                        <Col md={12} className='mb-1'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="Mostrar en Nodales" id="checkNewGradeShowNodals" />
                            </div>
                        </Col>
                        <Col md={12} className='mb-1'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="Mostrar en Evaluaciones" id="checkNewGradeShowGrade" />
                            </div>
                        </Col>
                        <Col md={12} className='mb-1'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Check type="switch" label="Mostrar en Boletín" id="checkNewGradeShowReport" />
                            </div>
                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewGrade}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postGrade}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default GradesConfig