import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChalkboardTeacher, faCheck, faFile, faPen, faTimes, faUser, faClipboardUser, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../general-components/ActionButton';
import { validateToken } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';

export default function EvaluativeReport() {
    const PAGE_TITLE = "Informe Valorativo";
    const PAGE_ICON = faFile;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
    }, []);

    const [isGradeNumber, setIsGradeNumber] = useState(false);

    const [isTableLoaded, setIsTableLoaded] = useState(true); //Cambbiar a false y cambiar state cuando se haga get de los datos

    //DropDown Course
    let optionsCourse = ['1°A', '1°B', '1°A', '1°C', '1°A', '5°A', '1°A', '1°A', '1°A', '1°A'];
    const [selectedCourseOption, setSelectedCourseOption] = useState('Curso');

    const handleSelect = (eventKey) => {
        setSelectedCourseOption(optionsCourse[eventKey]);
    };


    //DropDown Materia
    let optionsSubject = ['Matemática', 'Lengua', 'Sociales', 'Biología', 'Filosofía', 'Inglés'];
    const [selectedSubjectOption, setSelectedSubjectOption] = useState('Materia');

    const handleSelectSubject = (eventKey) => {
        setSelectedSubjectOption(optionsSubject[eventKey]);
    };

    //Dropdown periodo
    let optionsFrame = ['1°bimestre', '2°bimestre', '3°bimestre', '4°bimestre'];
    const [selectedFrameOption, setSelectedFrameOption] = useState('Periodo');

    const handleSelectFrame = (eventKey) => {
        setSelectedFrameOption(optionsFrame[eventKey]);
    };

    //Funcion botones
    const frameGrade = () => {
        console.log("Calificar periodo")
    }

    //Table
    const valorativeReportTable = [{
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    }, {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    }, {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    }, {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    }, {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    }, {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    }, {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },
    {
        name: 'Juan Perez',
        rules: { text: 'A', color: '#ffffff' },
        tasks: { text: 'A', color: '#ffffff' },
        nodals: [{ text: 'A', color: '#ffffff' }, { text: 'A', color: '#ffffff' }, { text: 'EP', color: '#FF9CA5' }],
        grade: { num: 7, color: '#ABD5FF' }
    },]

    const studentsInfo = [{
        name: 'Juan Perez',
        img: 'https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg',
        userId: '1'
    },
    {
        name: 'Juan Perez',
        img: 'https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg',
        userId: '2'
    },
    {
        name: 'Juan Perez',
        img: 'https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg',
        userId: '3'
    }];

    const nodales = ['Nodal 1', 'Nodal 2', 'Nodal 3',]

    //Modal calificar nodal
    const [showQualifyNodal, setShowQualifyNodal] = useState(false);
    const handleShowQualifyNodal = () => {
        /* Chekear si es la primera vez */
        initNumericalGradesModal();
        setDropdownSelectedItems({});
        setShowQualifyNodal(true)
    };
    const handleCloseModalQualifyNodal = () => {
        setShowQualifyNodal(false);
    }

    const [selectedNodalOption, setSelectedNodalOption] = useState('Nodal');
    const handleSelectNodal = (eventKey) => {
        setSelectedNodalOption(nodales[eventKey]);
    };

    const [QualifyNodal, setQualifyNodal] = useState([
        {
            text: 'Tp 1',
            date: '22/3',
            id: '14',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'A'
                },
                {
                    userId: 2,
                    value: 'S'
                },
                {
                    userId: 3,
                    value: 'S'
                }

            ]
        },
        {
            text: 'Tp 2',
            date: '22/3',
            id: '15',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'A'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
        {
            text: 'Sugerido',
            id: '17',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'R'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
        {
            text: 'Calificación',
            id: '18',
            calificable: true, numerical: null, options: [
                { abbreviation: 'EP', title: 'En proceso', id: '123' },
                { abbreviation: 'S', title: 'Suficiente', id: '124' },
                { abbreviation: 'A', title: 'Avanzado', id: '125' }],
            average: [
                {
                    userId: 1,
                    value: 'A'
                },
                {
                    userId: 2,
                    value: 'EP'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
    ])


    //Modal Calificar periodo
    const [showQualifyFrame, setShowQualifyFrame] = useState(false);
    const handleShowQualifyFrame = () => {
        /* Chekear si es la primera vez */
        initNumericalGradesModal();
        setSelectedFrameOptionModal(selectedFrameOption)
        setDropdownSelectedItems({})
        setShowQualifyFrame(true)
    };
    const handleCloseModalQualifyFrame = () => {
        setShowQualifyFrame(false);
    }

    const [optionsLetterGrades, setOptionsLetterGrades] = useState(['EP', 'S', 'A']);
    const [selectedLetterGrade, setSelectedLetterGrade] = useState('S');
    const handleSelectLetterGrade = (eventKey) => {
        setSelectedLetterGrade(optionsLetterGrades[eventKey]);
    };

    const [selectedFrameOptionModal, setSelectedFrameOptionModal] = useState(selectedFrameOption);

    const handleSelectFrameModal = (eventKey) => {
        setSelectedFrameOptionModal(optionsFrame[eventKey]);
    };

    const [QualifyFrame, setQualifyFrame] = useState([
        {
            text: 'Respeta las normas de convivencia',
            id: '12',
            calificable: true, numerical: null, options: [{ abbreviation: 'EP', title: 'En proceso', id: '123' },
            { abbreviation: 'S', title: 'Suficiente', id: '124' },
            { abbreviation: 'A', title: 'Avanzado', id: '125' }],
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'S',
                    abreviatura: ""
                },
                {
                    userId: 3,
                    value: 'S',
                    abreviatura: ""
                }
            ]
        },
        {
            text: 'Entrega tareas en tiempo y forma',
            id: '13',
            calificable: true, numerical: null, options: [{ abbreviation: 'EP', title: 'En proceso', id: '123' },
            { abbreviation: 'S', title: 'Suficiente', id: '124' },
            { abbreviation: 'A', title: 'Avanzado', id: '125' }],
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'S'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
        {
            text: 'Nodal 1',
            id: '14',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'A'
                },
                {
                    userId: 2,
                    value: 'S'
                },
                {
                    userId: 3,
                    value: 'S'
                }

            ]
        },
        {
            text: 'Nodal 2',
            id: '15',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'A'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
        {
            text: 'Nodal 3',
            id: '16',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'A'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
        {
            text: 'Sugerido',
            id: '17',
            calificable: null,
            average: [
                {
                    userId: 1,
                    value: 'R'
                },
                {
                    userId: 2,
                    value: 'R'
                },
                {
                    userId: 3,
                    value: 'S'
                }
            ]
        },
        {
            text: 'Calificación',
            id: '18',
            calificable: true, numerical: true,
            average: [
                {
                    userId: 1,
                    value: '8'
                },
                {
                    userId: 2,
                    value: '1'
                },
                {
                    userId: 3,
                    value: '0'
                }
            ]
        },
    ])

    const [dropdownSelectedItems, setDropdownSelectedItems] = useState({})
    const handleDropdownSelectedItems = (idType, idPerson, value) => {
        console.log(`${idType}-${idPerson}` + ' ' + value)
        setDropdownSelectedItems(prevState => ({
            ...prevState,
            [`${idType}-${idPerson}`]: value
        }));
    }

    const saveQualifyFrameModal = () => {
        for (let i = 0; i < QualifyFrame.length; i++) {
            if (QualifyFrame[i].calificable) {
                if (QualifyFrame[i].options) {
                    //Es un dropdown
                    //Guardo la info seleccionada
                } else {
                    //Es form.input
                }
            }
        }
    }

    const [numericalGradesModal, setNumericalGradesModal] = useState([]);
    const initNumericalGradesModal = () => {
        let listNew = [];
        studentsInfo.map((r) => {
            listNew.push({
                id: r.userId,
                grade: '',
            })
        })
        setNumericalGradesModal(listNew);
    }

    const handleInputGradeNew = (event, i) => {
        const prev = [...numericalGradesModal];
        prev[i].grade = event.target.value;
        setNumericalGradesModal(prev);
    }

    const passingGrade = 6;
    const isApproved = (grade) => {
        if (grade >= passingGrade) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4 mb-3 align-items-center marginSep'>
                    <Col className='fitContentWidth align-items-center'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedCourseOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedCourseOption}>
                                    {optionsCourse.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth align-items-center'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedSubjectOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedSubjectOption}>
                                    {optionsSubject.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectSubject(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth align-items-center'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedFrameOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedFrameOption}>
                                    {optionsFrame.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectFrame(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPen} title={'Calificar periodo'} func={handleShowQualifyFrame} />
                    </Col>
                    <Col className='endRow fitContentWidth'>
                        <ActionButton iconFa={faPen} title={'Calificar nodal'} func={handleShowQualifyNodal} />
                    </Col>
                </Row>
                <Card className='cards'>
                    {!isTableLoaded ? <div className='justify-content-center'><Loading animation={'border'}/></div> : <>
                    <div  className='h-100-2'>
                        <div className='slideTable' style={{ borderRadius: '10px' }}>
                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                    <tr className='headerContainer'>
                                        <td className='sticky-column'>Alumnos</td>
                                        <td className='smallColTable'>Respeta las normas de convivencia</td>
                                        <td className='smallColTable'>Entrega tareas en tiempo y forma</td>
                                        {nodales.map((nod, i) => <td key={i}>{nod}</td>)}
                                        <td>Calificación</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {valorativeReportTable.map((student, i) =>
                                        <tr key={i} className='' style={{ border: 'white' }}>
                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className='fitContentWidth p-1'>
                                                                <div className='studentImg circle-container' style={{ borderColor: 'green' }}>
                                                                    <img className='ms-2' src={'https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg'} />
                                                                </div>
                                                            </div>
                                                            <span className='ms-1'>{student.name}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td className='pb-0 pt-1 ps-0 pe-0' >
                                                <Row className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: student.rules.color, color: 'black' }}>{student.rules.text}</Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td className='pb-0 pt-1 ps-0 pe-0' >
                                                <Row className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: student.tasks.color, color: 'black' }}>{student.tasks.text}</Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>
                                            {student.nodals.map((nodal, i) => <td key={i} className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: nodal.color, color: 'black' }}>{nodal.text}</Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>)}
                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row style={{ borderRadius: ' 0 8px 8px 0 ' }} className='tableRows'>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div className='m-0 p-0' style={{ textAlign: 'center' }}>
                                                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 60, backgroundColor: student.grade.color, color: 'black' }}>{student.grade.num}</Badge>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </>}
                </Card>
            </div>

            {/* Modal calificar nodal */}
            <Modal size={'xl'} show={showQualifyNodal} onHide={handleCloseModalQualifyNodal} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Calificar nodal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faFolderOpen} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedNodalOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedNodalOption}>
                                        {nodales.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectNodal(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <div className='slideTable' style={{ borderRadius: '10px' }}>
                        <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                            <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                <tr className=' headerContainer'>
                                    <td className='sticky-column' rowSpan="2">#</td>
                                    <td>Alumno</td>
                                    {QualifyNodal.map((r) =>
                                        <td style={{ maxWidth: '150px' }}>{r.text}</td>
                                    )}
                                </tr>
                            </thead><tbody>
                                {studentsInfo.map((st, i) => (
                                    <tr className='' style={{ border: 'white' }}>
                                        <td className='pb-0 pt-1 ps-0 pe-0'>
                                            <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                                                <Col className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {1 + i}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>

                                        <td className='pb-0 pt-1 ps-0 pe-0'>
                                            <Row className='tableRows'>
                                                <Col className='align-center-vertically-v2'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='fitContentWidth p-1'>
                                                            <div className='studentImg circle-container' style={{ borderColor: 'green' }}>
                                                                <img className='ms-2' src={st.img} />
                                                            </div>
                                                        </div>
                                                        <span className='ms-1'>{st.name}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>

                                        {QualifyNodal.map((r, j) =>
                                            r.calificable ? <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className={j + 1 == QualifyNodal.length ? 'tableRows roundedRowEnd ' : 'tableRows'}>
                                                    <Col className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                        {r.numerical ? (
                                                            <div>
                                                                {numericalGradesModal[i] && isApproved(numericalGradesModal[i].grade) ? (
                                                                    <Badge bg='' style={{ background: 'green' }} className='statusBadge fs-14'>
                                                                        Aprobado
                                                                    </Badge>
                                                                ) : (
                                                                    numericalGradesModal[i] &&  numericalGradesModal[i].grade !== '' && (
                                                                        <Badge bg='' style={{ background: 'red' }} className='statusBadge fs-14'>
                                                                            Desaprobado
                                                                        </Badge>
                                                                    )
                                                                )}
                                                                <div className='fitContentWidth'>
                                                                    <Form.Control className='textInputGray fs-14' style={{ width: '75px' }} id={`${r.id}-${i}`} placeholder={r.average[i].value} onChange={(event) => handleInputGradeNew(event, i)} />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <Dropdown className='dropdownButton'>
                                                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14" style={{ width: '75px' }}>
                                                                    {dropdownSelectedItems[`${r.id}-${i}`] ? dropdownSelectedItems[`${r.id}-${i}`].abbreviation : r.average[i].value ? r.average[i].value : r.options[0].abbreviation}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="fs-14" title={dropdownSelectedItems[`${r.id}-${i}`] ? dropdownSelectedItems[`${r.id}-${i}`].abbreviation : r.average[i].value ? r.average[i].value : r.options[0]}>
                                                                    {r.options.map((opt, k) => (
                                                                        <Dropdown.Item key={k} onClick={() => handleDropdownSelectedItems(r.id, i, opt)}>{opt.abbreviation}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        )}
                                                    </Col>
                                                </Row></td> :
                                                <td className='pb-0 pt-1 ps-0 pe-0'>
                                                    <Row className={j + 1 == QualifyNodal.length ? 'tableRows roundedRowEnd ' : 'tableRows'}>
                                                        <Col className='align-center-vertically-v2'>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {r.average[i].value}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </td>
                                        )}

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalQualifyNodal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal calificar periodo: */}
            <Modal size={'xl'} show={showQualifyFrame} onHide={handleCloseModalQualifyFrame} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faClipboardUser} style={{ minWidht: '20px' }} /><Modal.Title>Calificar período</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mb-3'>
                        <Col>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedFrameOptionModal}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedFrameOptionModal}>
                                        {optionsFrame.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectFrameModal(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <div className='slideTable' style={{ borderRadius: '10px' }}>
                        <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                            <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                <tr className=' headerContainer'>
                                    <td className='sticky-column' rowSpan="2">#</td>
                                    <td>Alumno</td>
                                    {QualifyFrame.map((r) =>
                                        <td style={{ maxWidth: '150px' }}>{r.text}</td>
                                    )}
                                </tr>
                            </thead><tbody>
                                {studentsInfo.map((st, i) => (
                                    <tr className='' style={{ border: 'white' }}>
                                        <td className='pb-0 pt-1 ps-0 pe-0'>
                                            <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                                                <Col className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        {1 + i}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>

                                        <td className='pb-0 pt-1 ps-0 pe-0'>
                                            <Row className='tableRows'>
                                                <Col className='align-center-vertically-v2'>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='fitContentWidth p-1'>
                                                            <div className='studentImg circle-container' style={{ borderColor: 'green' }}>
                                                                <img className='ms-2' src={st.img} />
                                                            </div>
                                                        </div>
                                                        <span className='ms-1'>{st.name}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td>

                                        {QualifyFrame.map((r, j) =>
                                            r.calificable ? <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className={j + 1 == QualifyFrame.length ? 'tableRows roundedRowEnd ' : 'tableRows'}>
                                                    <Col className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                        {r.numerical ?
                                                            <>{numericalGradesModal[i] && isApproved(numericalGradesModal[i].grade) ? (
                                                                <Badge bg='' style={{ background: 'green' }} className='statusBadge fs-14'>
                                                                    Aprobado
                                                                </Badge>
                                                            ) : (
                                                                numericalGradesModal[i] && numericalGradesModal[i].grade !== '' && (
                                                                    <Badge bg='' style={{ background: 'red' }} className='statusBadge fs-14'>
                                                                        Desaprobado
                                                                    </Badge>
                                                                )
                                                            )}
                                                                <div className='fitContentWidth'>
                                                                    <Form.Control className='textInputGray fs-14 ms-2' style={{ width: '75px' }} id={`${r.id}-${i}`} placeholder={r.average[i].value} onChange={(event) => handleInputGradeNew(event, i)} />
                                                                </div></>
                                                            :
                                                            <Dropdown className='dropdownButton'>
                                                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14" style={{ width: '75px' }}>
                                                                    {dropdownSelectedItems[`${r.id}-${i}`] ? dropdownSelectedItems[`${r.id}-${i}`].abbreviation : r.average[i].value ? r.average[i].value : r.options[0]}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="fs-14" title={dropdownSelectedItems[`${r.id}-${i}`] ? dropdownSelectedItems[`${r.id}-${i}`].abbreviation : r.average[i].value ? r.average[i].value : r.options[0]}>
                                                                    {r.options.map((opt, k) => (
                                                                        <Dropdown.Item key={k} onClick={() => handleDropdownSelectedItems(r.id, i, opt)}>{opt.abbreviation}</Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }
                                                    </Col>
                                                </Row></td> :
                                                <td className='pb-0 pt-1 ps-0 pe-0'>
                                                    <Row className={j + 1 == QualifyFrame.length ? 'tableRows roundedRowEnd ' : 'tableRows'}>
                                                        <Col className='align-center-vertically-v2'>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {r.average[i].value}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </td>
                                        )}

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalQualifyFrame}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
