import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faDesktopAlt, faEllipsisV, faEye, faFile, faGlobe, faHamburger, faHeading, faMobile, faMobileAlt, faMobilePhone, faPen, faPlus, faPlusMinus, faSchool, faSortNumericAsc, faSortNumericDesc, faSortNumericUp, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST, PUT } from '../../../controllers/endpoints.controller';
import { getIcon } from '../../../tools/tools';
import Loading from '../../general-components/Loading';
import InfoField from '../../general-components/InfoField';

function NavBarConfig() {
    const PAGE_TITLE = "NavBar";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getRoles();
        getRoutes()
    }, []);

    const [roles, setRoles] = useState(null)

    const getRoles = async function () {
        const req = await GET(`/sso/roles`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setRoles(res);
            //setSelectedSchoolName(res[0].School.name)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const [routes, setRoutes] = useState(null)

    const getRoutes = async function () {
        setIsLoading(true)

        const req = await GET(`/sso/navbar/all`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setRoutes(res);
            setIsLoading(false)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedSchoolIndex, setSelectedSchoolIndex] = useState(0);
    const [selectedSchoolName, setSelectedSchoolName] = useState("Colegio");
    const handleSelectSchool = (i) => {
        setSelectedSchoolIndex(i)
        //setSelectedSchoolName(roles[i].School.name)
    }

    const [editedNavbar, setEditedNavBar] = useState()

    const [isEditing, setIsEditing] = useState(true)
    const handleEdit = () => {
        setIsEditing(true)
    }

    const putNavBar = async () => {
        setIsEditing(false)
    }

    const handleEditNavbar = async (role, slag) => {
        let body = {
            itemId: role.itemId,
            roleId: role.roleId,
            show: slag
        }
        console.log(body)
        const req = await PUT(`/sso/navbar/visibility`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {

            console.log(res)
            getRoutes()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showItemOrderModal, setShowItemOrderModal] = useState(false)
    const [itemToEdit, setItemToEdit] = useState(null)
    const handleShowItemOrderModal = (item) => {
        setItemToEdit(item)
        setShowItemOrderModal(true)
    }
    const handleCloseItemOrderModal = () => {
        setShowItemOrderModal(false)
    }
    const handleEditNavbarOrder = async (item, slag) => {
        let body = {
            itemId: itemToEdit.id,
            order: Number(document.getElementById('navbarOrder').value)
        }
        console.log(body)
        const req = await PUT(`/sso/navbar/order`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            getRoutes()
            handleCloseItemOrderModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showNewItemModal, setShowNewItemModal] = useState(false)
    const handleShowNewItemModal = () => {
        setShowNewItemModal(true)
    }
    const handleCloseNewItemModal = () => {
        setShowNewItemModal(false)
    }

    const [subItems, setSubItems] = useState([]);

    const handleAddSubItem = () => {
        setSubItems([...subItems, { text: '', link: '', icon: faGlobe }]);
    };

    const handleSubItemChange = (index, field, value) => {
        const updatedSubItems = subItems.map((subItem, idx) =>
            idx === index ? { ...subItem, [field]: value } : subItem
        );
        setSubItems(updatedSubItems);
    };

    const handleRemoveSubItem = (index) => {
        const updatedSubItems = subItems.filter((_, idx) => idx !== index);
        setSubItems(updatedSubItems);
    };

    const postNavbar = async function () {

        let body = {
            text: document.getElementById("navbarText").value,
            icon: document.getElementById("navbarIcon").value,
            link: document.getElementById("navbarLink").value,
            order: document.getElementById("navbarOrderPost").value,
            navbarSubItems: subItems
        }
        console.log(body)

        const req = await POST(`/sso/navbar/items`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getRoutes()
            handleCloseNewItemModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col className='endRow'>
                        <ActionButton title='Nuevo item' variant='primary' iconFa={faPlus} func={handleShowNewItemModal} />
                    </Col>

                    {/* <Col className='endRow'>
                        {!isEditing ? <ActionButton title='Editar' variant='primary' iconFa={faPen} func={handleEdit} />
                            : <ActionButton title='Guardar cambios' variant='primary' iconFa={faCheck} func={putNavBar} />}
                    </Col> */}
                </Row>

                <Card className='cards mt-3'>
                    <div className='slideTable h-100-3'>
                        <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                            <thead className='sticky-row headerBlue' style={{ color: 'white' }}>
                                <tr className=' headerContainer'>
                                    <td className='sticky-column' rowSpan="2"></td>
                                    {
                                        roles && roles.map((r, i) =>
                                            <td key={i} rowSpan="2">{r.name} ({r.levelId})</td>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {routes && routes.map((r, i) => (
                                    <tr key={i} className=''>
                                        <td className='sticky-column pb-0 pt-1 ps-0 pe-0' style={{ position: 'relative' }}>
                                            <Row style={{ borderRadius: '8px 0 0 8px', minHeight: '95px', backgroundColor: '#F0F0F0' }} className=' pt-1 pb-1'>
                                                <Col className='align-center-vertically-v2 ps-1 pe-1' >
                                                    <div style={{ textAlign: 'left', width: 160 }} className='mt-1 flex'>
                                                        <span className='navbar-icon flex align-center-vertically'><FontAwesomeIcon className='navbar-icon-content' icon={getIcon(r.icon)} /></span>
                                                        <div className='ms-2' style={{ width: 120, lineHeight: '14px' }}>
                                                            {r.text}
                                                            <div className='fs-11' style={{ color: 'blue', marginTop: '2px', lineHeight: '11px' }}>{r.link}</div>
                                                        </div>
                                                    </div>
                                                    {r.NavbarSubItems && r.NavbarSubItems.map((su, j) => (
                                                        <div className='ps-1' style={{ textAlign: 'left', width: 150, paddingLeft: '', lineHeight: '10px', marginTop: '2px' }}>
                                                            <span className='fs-11'>{su.text} <span style={{ color: 'blue', fontSize: '10px' }}>{su.link}</span></span>
                                                        </div>
                                                    ))}

                                                </Col>
                                            </Row>
                                            <div className='flex' style={{ position: 'absolute', top: 7, right: 7, fontSize: 9, color: 'grey' }}>
                                                {`(${r.order})`}
                                                <FontAwesomeIcon icon={faPen} className='pointer blueHover ms-1' style={{  fontSize: 9, color: 'grey' }} onClick={() => handleShowItemOrderModal(r)} />

                                            </div>
                                        </td>
                                        {r.Roles.map((k, i) => (
                                            <td className='pb-0 pt-1 ps-0 pe-0'>
                                                <Row className=' pt-1 pb-1' style={{ height: '95px', backgroundColor: '#F0F0F0' }}>
                                                    <Col className='align-center-vertically-v2'>
                                                        <div style={{ textAlign: 'center', justifyContent: 'center' }} className='align-center-vertically'>
                                                            <FontAwesomeIcon icon={faMobileAlt} style={{ width: '16px' }} /><Form.Check type="switch" defaultChecked={k.showMobile} checked={k.showMobile} disabled={isLoading} onChange={() => handleEditNavbar(k, "MOBILE")} />
                                                        </div>
                                                        <div style={{ textAlign: 'center', justifyContent: 'center' }} className='align-center-vertically'>
                                                            <FontAwesomeIcon icon={faDesktopAlt} style={{ width: '16px' }} /><Form.Check type="switch" defaultChecked={k.showDesktop} checked={k.showDesktop} disabled={isLoading} onChange={() => handleEditNavbar(k, "DESKTOP")} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </td>
                                        ))}
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>

            {showNewItemModal && <Modal size='lg' show={showNewItemModal} onHide={handleCloseNewItemModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo navbar item</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Row className='mb-2'>
                        <Col md={4} className='mb-2'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Título" id="navbarText" />
                            </div>
                        </Col>
                        <Col md={4} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faGlobe} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Link" id="navbarLink" />
                            </div>
                        </Col>
                        <Col md={2} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faHamburger} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 ' placeholder="Icono" id="navbarIcon" />
                            </div>
                        </Col>
                        <Col md={2} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faPlusMinus} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 ' placeholder="Orden" id="navbarOrderPost" />
                            </div>
                        </Col>
                    </Row>
                    {subItems.map((subItem, index) => (
                        <Row key={index} className=" mb-2">
                            <Col md={4} className='ps-0'>
                                <div className='align-center-vertically'>
                                    <div className='me-2'>
                                        <Button variant='danger' onClick={() => handleRemoveSubItem(index)} ><FontAwesomeIcon icon={faTimes} /></Button>
                                    </div>
                                    <Form.Control
                                        className="textInputGray fs-14"
                                        placeholder="Título del subitem"
                                        value={subItem.title}
                                        onChange={(e) => handleSubItemChange(index, 'text', e.target.value)}
                                    /></div>
                            </Col>
                            <Col md={4}>
                                <div className='align-center-vertically'>
                                    <FontAwesomeIcon icon={faGlobe} className='dropDownIcon' style={{ height: 16 }} />

                                    <Form.Control
                                        className="textInputGray fs-14 wi-300"
                                        placeholder="Link del subitem"
                                        value={subItem.link}
                                        onChange={(e) => handleSubItemChange(index, 'link', e.target.value)}
                                    /></div>
                            </Col>
                        </Row>
                    ))}
                    <ActionButton title='Agregar subItems' iconFa={faPlus} func={handleAddSubItem} />
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseNewItemModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postNavbar}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>}

            {showItemOrderModal && <Modal size='' show={showItemOrderModal} onHide={handleCloseItemOrderModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Editar orden</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    {itemToEdit != null ? <>
                        <InfoField title='Navbar' content={itemToEdit.text} />
                        <Row className='mt-3'>
                            <Col md={4} className=''>
                                <div className='align-center-vertically'>
                                    <FontAwesomeIcon icon={faPlusMinus} className='dropDownIcon' style={{ height: 16 }} />
                                    <Form.Control className='textInputGray fs-14 wi-300' placeholder={itemToEdit.order ? itemToEdit.order : "Orden"} id="navbarOrder" />
                                </div>
                            </Col>
                        </Row></> : ''}
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseItemOrderModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={handleEditNavbarOrder}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}

export default NavBarConfig