import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { Button, Card, Col, Modal, Row, Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCircle, faCog, faEllipsisV, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST } from '../../../controllers/endpoints.controller';
import Moment from 'react-moment';

function SchoolYear() {
    const PAGE_TITLE = "Ciclo lectivo";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getLevels();
        getSchoolYears();
    }, []);


    const menus = [{ text: "2022", link: "/2022", icon: faCircle },
    { text: "2023", link: "/2023", icon: faCircle },
    ];

    const [schoolYears, setSchoolYears] = useState([]);

    const [showCreateModal, setShowCreateModal] = useState(false);

    const handleCloseCreateModal = () => setShowCreateModal(false);
    const handleShowCreateModal = () => {
        setSchoolYearDateStart(null)
        setSchoolYearDateEnd(null)
        setShowCreateModal(true)
    };

    //Pick date
    const [schoolYearDateStart, setSchoolYearDateStart] = useState(null);

    const handleSchoolYearDateStart = date => {
        setSchoolYearDateStart(date);
    };

    const [schoolYearDateEnd, setSchoolYearDateEnd] = useState(null);

    const handleSchoolYearDateEnd = date => {
        setSchoolYearDateEnd(date);
    };

    const [schoolYearYear, setSchoolYearYear] = useState(0);
    const handleSchoolYearYear = (e) => {
        setSchoolYearYear(e.target.value)
    }

    const [levels, setLevels] = useState([]);

    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [schoolYearLevelId, setSchoolYearLevelId] = useState(null);

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey.name);
        setSchoolYearLevelId(eventKey.id);
    };

    const getSchoolYears = async function () {
        const req = await GET(`/classes/schoolYears`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchoolYears(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postSchollYear = async function () {
        const body = {
            year: schoolYearYear,
            from: schoolYearDateStart,
            to: schoolYearDateEnd,
            levelId: schoolYearLevelId
        }

        const req = await POST(`/classes/schoolYear`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getSchoolYears();
            handleCloseCreateModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo ciclo lectivo'} func={handleShowCreateModal} />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={4} className='align-center-vertically-v2 ms-0'>
                                <div>
                                    Año
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    Desde
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    Hasta
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    Nivel
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2 ms-0'>

                            </Col>
                        </Row>
                    </div>

                    {schoolYears.map((year, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={4} className='align-center-vertically-v2 pt-2 pb-2 '>
                                        <div style={{ textAlign: 'left' }}>
                                            <p className='m-0'>{year.year}</p>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            <Moment date={year.from} format='DD/MM/YYYY' />
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            <Moment date={year.to} format='DD/MM/YYYY' />
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            {year.Level ? year.Level.name : ""}
                                        </div>
                                    </Col>

                                    <Col md={1} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal show={showCreateModal} onHide={handleCloseCreateModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faCalendar} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo ciclo lectivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <div className='ms-3'>
                            <Form.Control type='number' className='textInputGray fs-14 wi-300' id="" style={{ marginLeft: '22px' }} placeholder="Año" onChange={handleSchoolYearYear} />
                        </div>
                    </Col>
                    <Col md={12} className='mt-2 mb-2'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon style={{ height: 16 }} icon={faCalendar} className='dropDownIcon' />
                            <DatePicker
                                selected={schoolYearDateStart}
                                onChange={handleSchoolYearDateStart}
                                dateFormat="dd/MM/yyyy"
                                placeholderText='Fecha de inicio'
                                className='ms-2 input-text wi-147'
                                locale='es' />

                            <DatePicker
                                selected={schoolYearDateEnd}
                                onChange={handleSchoolYearDateEnd}
                                dateFormat="dd/MM/yyyy"
                                placeholderText='Fecha de finalización'
                                className='ms-2 input-text wi-146'
                                locale='es' />
                        </div>
                    </Col>
                    <Col md={12} className='align-center-vertically'>
                        <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                        <Dropdown className='dropdownButton wi-300 p-0 ms-2'>
                            <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                {selectedLevelOption}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                {levels.map((opt, i) => (
                                    <Dropdown.Item key={i} onClick={() => handleSelectLevel(opt)}>{opt.name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseCreateModal}><FontAwesomeIcon className='iconButton' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postSchollYear}><FontAwesomeIcon className='iconButton' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SchoolYear